import type { ReactNode } from "react";
import ListItem from "@components/elements/List/ListItem";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@components/fondation/Typography/Typography";

export function MarkedItem({
    children,
}: {
    children?: ReactNode;
}): JSX.Element {
    return (
        <ListItem disableGutters>
            <CheckCircleOutlineIcon color="success" />
            <Typography variant="body2" color="grey.700" ml={2}>
                {children}
            </Typography>
        </ListItem>
    );
}

export default MarkedItem;
