import { useEffect, useState } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";
import { useLocation, useNavigate } from "react-router-dom";

import Typography from "@components/fondation/Typography/Typography";
import Box from "@components/fondation/Box/Box";
import ModalHeader from "@components/elements/HeaderBar/ModalHeader";
import { FormData as EmailFormData } from "@components/modules/forms/auth/EmailForm";
import SessionStore from "@store/auth/session.store";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import VerificationCodeForm, {
    FormData as CodeFormData,
} from "@components/modules/forms/auth/VerificationCodeForm";
import AuthStore from "@store/auth/auth.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import Image from "@components/elements/Image/Image";
import { getImageUrl } from "@components/elements/Image/collection";
import NavLink from "@components/elements/NavLink/NavLink";
import { AppError } from "@model/utils/error";
import { ChannelTypes } from "@services/firebase/session.service/request.types";

export const signUpErrorCodeToMessage = (code: string): React.ReactNode => {
    if (code === "CODE_VERIFICATION_FAILED") {
        return <>Provided verification code is incorrect</>;
    }
    if (code === "NOT_EXISTS") {
        return <>There is no user with provided email</>;
    }
    return "We are having trouble, please try again later";
};

const MAIN_IMAGE_SRC = getImageUrl("search.png");

const VerificationPage: React.FC = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sessionStore, authStore] = useInstances(SessionStore, AuthStore);

    const [error, setError] = useState<AppError | null>(null);
    const [currentChannel, setChannel] = useState<"email" | "sms">(
        sessionStore.defaultChannel,
    );

    const loginForm: EmailFormData | undefined = location.state?.form;

    const handleSubmit = async ({ code }: CodeFormData) => {
        setError(null);
        if (loginForm == null) {
            return;
        }
        const verifyError = await sessionStore.verifyCode(
            loginForm.email,
            code,
        );
        setError(verifyError);
    };

    const handleResend =
        (channel: ChannelTypes): React.MouseEventHandler =>
        async (e: React.MouseEvent) => {
            e.preventDefault();
            setError(null);
            setChannel(channel);
            if (loginForm == null) {
                return;
            }
            const resendError = await sessionStore.loginWithEmail(
                loginForm.email,
                channel,
            );
            setError(resendError);
        };

    const handleBack = () => {
        navigate("/login");
    };

    useEffect(() => {
        if (loginForm == null) {
            navigate("/login", { replace: true });
        }
    }, [loginForm]);

    useEffect(() => {
        if (authStore.isAuthorized) {
            const pathname = location.state?.afterLogin ?? "/";
            navigate(pathname, { replace: true });
        }
    }, [authStore.isAuthorized]);

    return (
        <ModalBox>
            <ModalHeader
                BackButtonProps={{ onClick: handleBack }}
                hideEndButton
            />

            <ModalBox flexBasis="100%" p={4} alignItems="center">
                <Box mt={10} mb={5}>
                    <Image src={MAIN_IMAGE_SRC} height={115} width={208} />
                </Box>

                <Typography variant="h2" textAlign="center" mb={2.5}>
                    Check your {currentChannel === "email" ? "email" : "phone"}
                </Typography>
                <Typography
                    variant="body2"
                    textAlign="center"
                    color="grey.800"
                    mb={3.5}
                >
                    We sent you a code
                </Typography>

                {error && (
                    <Box mb={4} width="100%">
                        <Alert severity="error">
                            <AlertTitle>Oops!</AlertTitle>
                            {signUpErrorCodeToMessage(error.code)}
                        </Alert>
                    </Box>
                )}

                <VerificationCodeForm
                    loading={sessionStore.isLoading}
                    onSubmit={handleSubmit}
                />

                <Typography variant="body2" align="center" mt={4}>
                    {"Didn't get a code? Resend code to "}
                    <NavLink to="" onClick={handleResend("email")}>
                        Email
                    </NavLink>
                    {" or "}
                    <NavLink to="" onClick={handleResend("sms")}>
                        Phone
                    </NavLink>
                </Typography>
            </ModalBox>
        </ModalBox>
    );
});

export default VerificationPage;
