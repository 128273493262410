import { ReactElement, useCallback } from "react";
import Box from "@components/fondation/Box/Box";
import Stack from "@components/fondation/Stack/Stack";
import clsx from "clsx";
import UploadFile from "@components/fondation/icons/UploadFile";
import UploadError from "@components/fondation/icons/UploadError";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { printFileSize } from "@root/lib/numberUtils";

import styles from "./FileUpload.module.sass";

export type TSize = "small" | "medium" | "large";

export interface IFileUploadCardProps {
    fileName: string;

    // size in bytes
    size: number;

    // progress in percentage
    progress: number;

    isCompleted: boolean;

    error?: string;

    onDeleteClick?: () => void;

    onRestartClick?: () => void;
}

function FileUploadCard(props: IFileUploadCardProps): ReactElement {
    const {
        fileName,
        error,
        size,
        progress,
        isCompleted = false,
        onDeleteClick,
        onRestartClick,
    } = props;

    const withError = !!error;
    const sizeSrt = printFileSize(size);

    const text = !withError
        ? `${sizeSrt} • Uploaded ${progress.toFixed(0)}%`
        : error;

    const onDelete = useCallback(() => {
        onDeleteClick?.();
    }, []);

    const onRestart = useCallback(() => {
        onRestartClick?.();
    }, []);

    const containerClassName = clsx(styles.container, {
        [styles.error]: withError,
    });

    const textClassName = clsx(styles.text, {
        [styles.error]: withError,
    });

    const progressProps: LinearProgressProps = {
        className: styles.progress,
        color: "green",
        variant: "determinate",
        value: progress,
    };

    const renderStartIcons = () => {
        const iconProps: SvgIconProps = {
            fontSize: "large",
            viewBox: "0 0 20 20",
        };

        if (withError) {
            iconProps.color = "error";

            return <UploadError {...iconProps} />;
        }

        if (isCompleted) {
            iconProps.color = "green";
        } else {
            iconProps.color = "inherit";
        }

        return <UploadFile {...iconProps} />;
    };

    const renderEndIcons = () => {
        if (withError) {
            return [
                <IconButton
                    key="restart"
                    aria-label="restart"
                    onClick={onRestart}
                >
                    <ReplayIcon />
                </IconButton>,
                <IconButton
                    key="delete"
                    aria-label="delete"
                    onClick={onDelete}
                    color="error"
                >
                    <DeleteIcon />
                </IconButton>,
            ];
        }

        return (
            <IconButton aria-label="delete" onClick={onDelete}>
                <CloseIcon />
            </IconButton>
        );
    };

    return (
        <Stack
            direction="row"
            alignItems="center"
            className={containerClassName}
        >
            <Box className={styles.icon}>{renderStartIcons()}</Box>

            <Box flexGrow={1} overflow="hidden">
                <Typography variant="h6" className={styles.fileName}>
                    {fileName}
                </Typography>
                <Typography variant="body2" className={textClassName}>
                    {text}
                </Typography>
            </Box>

            <Stack direction="row">{renderEndIcons()}</Stack>

            {!withError && <LinearProgress {...progressProps} />}
        </Stack>
    );
}

FileUploadCard.defaultProps = {
    error: false,
};

export default FileUploadCard;
