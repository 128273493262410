import * as React from "react";
import Box from "@components/fondation/Box/Box";
import Typography from "@components/fondation/Typography/Typography";
import { BoxProps } from "@mui/material/Box";

export type PairRowData = {
    label: string;
    value: React.ReactNode;
};

export interface SimpleTableProps extends BoxProps {
    rows: PairRowData[];
}

export const SimpleTable: React.FC<SimpleTableProps> = React.memo(
    ({ rows, ...rest }) => (
        <Box component="table" width="100%" {...rest}>
            {rows.map(({ label, value }) => (
                <Box component="tr" sx={{ verticalAlign: "top" }} key={label}>
                    <Typography
                        variant="body2"
                        component="td"
                        paddingY={1}
                        color="grey.700"
                    >
                        {label}
                    </Typography>
                    <Typography variant="h6" component="td" width="50%">
                        {value}
                    </Typography>
                </Box>
            ))}
        </Box>
    ),
);

export default SimpleTable;
