import * as React from "react";
import Typography from "@components/fondation/Typography/Typography";
import CardBox, { CardBoxProps } from "@components/elements/Card/CardBox";
import Stack from "@components/fondation/Stack/Stack";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import IconButton from "@components/elements/Button/IconButton";

export interface EditablePreviewCardProps extends CardBoxProps {
    label?: React.ReactNode;
    onEdit?: React.MouseEventHandler;
}

export const EditablePreviewCard: React.FC<EditablePreviewCardProps> =
    React.memo(({ label = "Service Address", onEdit, children, ...props }) => (
        <CardBox p={4} {...props}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <div>
                    <Typography variant="h4" mb={2}>
                        {label}
                    </Typography>
                    {children}
                </div>

                <IconButton onClick={onEdit} color="primary">
                    <BorderColorOutlinedIcon />
                </IconButton>
            </Stack>
        </CardBox>
    ));

export default EditablePreviewCard;
