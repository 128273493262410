import { useEffect, useState, useMemo, type ReactNode } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";

type CustomTagManager = {
    active: boolean;
} & TagManagerArgs;

export default function Tags({
    children,
}: {
    children?: ReactNode;
}): JSX.Element {
    const [childrenLocal, setChildrenLocal] = useState<React.ReactNode>();
    const tags = useMemo(
        () =>
            [
                { active: true, gtmId: process.env.REACT_APP_TAG_MANAGER_ID },
            ] as CustomTagManager[],
        [],
    );
    useEffect(() => {
        if (tags) {
            const activeTags = tags.filter(
                (tag) => !!tag.active && !!tag.gtmId,
            );
            activeTags.forEach((tag) => TagManager.initialize(tag));
        }
        setChildrenLocal(children);
    }, [children, tags]);

    return <>{childrenLocal}</>;
}
