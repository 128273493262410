/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */

function shim(obj: any): any {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    if (obj.location) {
        obj.area = obj.location;
        delete obj.location;
    }

    if (typeof obj.area?.state === "object") {
        obj.area.state = obj.area.state.code;
    }

    if (obj.services?.electric?.state && !obj.services.electric.status) {
        obj.services.electric.status = obj.services.electric.state;
        delete obj.services.electric.state;
    }

    return obj;
}

function format(obj: any): any {
    if (obj instanceof Array) {
        return obj.map((value) => format(value));
    }
    if (typeof obj === "object" && !(obj instanceof Date)) {
        const result = {} as any;
        for (const key of Object.keys(obj)) {
            const value = obj[key];
            if (
                value !== null &&
                typeof value === "object" &&
                typeof value.toDate === "function"
            ) {
                result[key] = value.toDate();
            } else if (
                value !== null &&
                typeof value === "object" &&
                typeof value.firestore === "object"
            ) {
                result[key] = value;
            } else if (
                value !== null &&
                typeof value === "object" &&
                !(value instanceof Date)
            ) {
                const doc = format(value);
                if (doc) {
                    result[key] = format(value);
                }
            } else if (
                value !== undefined &&
                value !== null &&
                value !== "" &&
                !Number.isNaN(value)
            ) {
                result[key] = value;
            }
        }
        if (Object.keys(result).length > 0) {
            return result;
        }
        return undefined; // don't return an empty object
    }
    return obj;
}

// FIXME: taken from old codebase, need to be reworked or deleted
export function formatDocument<T = unknown>(obj: T): T {
    return shim(format(obj));
}
