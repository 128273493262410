import * as React from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import Grid, { GridProps } from "@mui/material/Grid";
import clsx from "clsx";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { r } from "@theme/utils";

import Typography from "@components/fondation/Typography/Typography";
import styles from "./Dropzone.module.sass";

const BYTES_IN_MB = 1_000_000;

const validateFileSize = (file: File, maxFileSize?: number) => {
    const sizeLimitInBytes = maxFileSize ? maxFileSize * BYTES_IN_MB : 0;

    if (sizeLimitInBytes && file.size > sizeLimitInBytes) {
        return {
            code: "FILE_TOO_LARGE",
            message: `File is larger than ${maxFileSize} MB`,
        };
    }
    return null;
};

export default function Dropzone({
    maxFileSize,
    onFileAdded,
}: {
    /**
     * @desc Maximum allowed file size in megabytes.
     */
    maxFileSize?: number;
    onFileAdded: (files: File[], rejected: FileRejection[]) => void;
}): JSX.Element {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "image/*": [],
            "application/pdf": [],
        },
        validator: (file: File) => validateFileSize(file, maxFileSize),
        onDrop: onFileAdded,
    });

    const containerClassName = clsx(styles.container, {
        [styles.isDragActive]: isDragActive,
    });

    const containerProps: GridProps = {
        direction: "column",
        alignItems: "center",
        justifyContent: "center",
        className: containerClassName,
        ...getRootProps(),
    };

    return (
        <Grid container {...containerProps}>
            <Grid item>
                <FileDownloadOutlinedIcon sx={{ fontSize: r(32) }} />
            </Grid>

            <Grid item>
                <Typography variant="body2" mb="3px" className={styles.browse}>
                    <b>Browse</b> or <b>Take Photo</b>
                </Typography>
            </Grid>

            <Grid item>
                <Typography
                    variant="body2"
                    sx={({ palette }) => ({ color: palette.grey[700] })}
                >
                    PDF or Image files accepted
                </Typography>
            </Grid>

            <input {...getInputProps()} />
        </Grid>
    );
}
