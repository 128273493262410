import { useEffect, useMemo } from "react";
import * as React from "react";
import { useInstances } from "react-ioc";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { UserStore } from "@store/auth/user.store";
import Stack from "@components/fondation/Stack/Stack";
import BusinessForm from "@components/modules/forms/BusinessForm";
import { BusinessInfo } from "@model/types/user";
import AboutYouHeader from "./AboutYouHeader";

const TITLE = "About your business";

const BusinessPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userStore] = useInstances(UserStore);

    const handleSubmit = async (form: BusinessInfo) => {
        const error = await userStore.updateBusiness(form);
        if (error) {
            return;
        }

        navigate("/contact-us", { state: { backPath: location.pathname } });
    };

    const initialValues: Partial<BusinessInfo> = useMemo(
        () => ({
            averageElectricBillSize:
                userStore.user?.business?.averageElectricBillSize,
            name: userStore.user?.business?.name,
        }),
        [userStore.user],
    );

    useEffect(() => {
        if (!userStore.isInit && !userStore.isLoading) {
            navigate("../register");
        }
    }, [userStore.isLoading]);

    const { isLoading } = userStore;

    return (
        <Stack>
            <AboutYouHeader title={TITLE} message={` `} />
            <BusinessForm
                loading={isLoading}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Stack>
    );
});

export default BusinessPage;
