import MUIAlert, { AlertProps as MUIAlertProps } from "@mui/material/Alert";
import styled from "@mui/material/styles/styled";
import { alpha } from "@mui/system/colorManipulator";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Warning from "@components/fondation/icons/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ReportIcon from "@mui/icons-material/Report";
import { r } from "@theme/utils";

const Alert = styled(MUIAlert)<MUIAlertProps>(
    ({ theme, onClick, severity = "success" }) => ({
        backgroundColor: alpha(theme.palette[severity].main, 0.15),
        color: theme.palette.secondary.main,
        padding: r(9, 12),
        cursor: onClick ? "pointer" : "default",
        alignItems: "center",
        "& .MuiAlert-message, .MuiAlert-action": {
            padding: 0,
        },
        ".MuiAlert-icon": {
            paddingBlock: r(3),
            marginRight: r(10),
        },
        ".MuiAlert-icon>*": {
            backgroundColor: theme.palette[severity].main,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.common.white,
            fontSize: r(40),
            padding: r(7.5),
        },
        "& .MuiAlert-message": {
            ...(theme.typography.body2 ?? {}),
            lineHeight: "160%",
        },
        "& .MuiAlertTitle-root": {
            ...(theme.typography.h6 ?? {}),
            color: theme.palette.primary.main,
            margin: 0,
            marginBottom: r(2),
        },
        "& .MuiAlert-action": {
            padding: 0,
            fontSize: r(20),
            marginBlock: r(9),
            marginRight: r(4),
            color: "inherit",
        },
    }),
);

Alert.defaultProps = {
    iconMapping: {
        success: <CheckCircleIcon fontSize="inherit" />,
        error: <ReportIcon fontSize="inherit" />,
        warning: <Warning fontSize="inherit" />,
        info: <InfoIcon fontSize="inherit" />,
    },
};
export type AlertProps = MUIAlertProps;
export default Alert;
