import { type ProductStore } from "@store/product/product.store";
import { type ServiceStatus } from "@model/types/user";

export function serviceStatusOneOf(
    product: ProductStore,
): (...statuses: Array<ServiceStatus>) => boolean {
    return (...statuses) => {
        const status = product.service?.status;

        return Boolean(status && statuses.includes(status));
    };
}
