import MUIDrawer, { DrawerProps } from "@mui/material/Drawer";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";

const ModalDrawer = styled(MUIDrawer)<DrawerProps>(({ theme, anchor }) => ({
    position: "absolute",
    overflow: "hidden",
    zIndex: 1400,

    ".MuiBackdrop-root": {
        position: "inherit",
    },

    ".MuiPaper-root": {
        position: "inherit",
        maxHeight: "calc(100% - 60px)",
        background: theme.palette.common.white,
        padding: r(20, 16),
        borderTopLeftRadius:
            anchor === "right" || anchor === "bottom" ? r(16) : 0,
        borderTopRightRadius:
            anchor === "left" || anchor === "bottom" ? r(16) : 0,
        borderBottomRightRadius:
            anchor === "left" || anchor === "top" ? r(16) : 0,
        borderBottomLeftRadius:
            anchor === "right" || anchor === "top" ? r(16) : 0,

        [theme.breakpoints.down("sm")]: {
            paddingBottom: r(70),
        },
    },
}));

ModalDrawer.defaultProps = {
    keepMounted: true,
};

export default ModalDrawer;
