import Link from "@mui/material/Link";
import { LabelProps } from "./Label";
import { PlanStatus, PlanStatusValue } from "./types";

export const statusMapper: Record<PlanStatus, PlanStatusValue> = {
    OLD: {
        label: "Old plan",
        color: "primary",
        bgColor: "grey.200",
    },
    CURRENT: {
        label: "Current plan",
        color: "primary",
        bgColor: "grey.200",
    },
    NEW_RESERVED: {
        label: "new rate reserved",
        color: "warning",
        bgColor: "rgba(243, 158, 57, 0.5)",
    },
    SELECTED: {
        label: "new Rate selected",
        color: "warning",
        bgColor: "rgba(243, 158, 57, 0.5)",
    },
    CONFIRMED: {
        label: "New plan confirmed",
        color: "success",
        bgColor: "rgba(14, 168, 117, 0.2)",
    },
    REJECTED: {
        label: "New plan rejected",
        color: "error",
        bgColor: "rgba(241, 92, 92, 0.4)",
    },
    DROPPED: {
        label: "Plan Dropped",
        color: "error",
        bgColor: "rgba(241, 92, 92, 0.4)",
    },
    INELIGIBLE: {
        label: "Ineligible Plan",
        color: "error",
        bgColor: "rgba(241, 92, 92, 0.4)",
    },
};

export const defaultMetrics: LabelProps[] = [
    {
        label: "Rate",
        color: "success.main",
        children: (
            <>
                $0.1063<sub>/kWh</sub>
            </>
        ),
    },
    {
        label: "Term",
        children: (
            <>
                36 <sub>months</sub>
            </>
        ),
    },
    {
        label: "Renewable",
        children: <>100%</>,
    },
];

export const detailedMetrics: LabelProps[] = [
    ...defaultMetrics,
    {
        label: "Start date",
        children: <>Nov 1, 2022</>,
    },
    {
        label: "End date",
        children: <>-</>,
    },
    {
        label: "Links",
        children: <Link>Terms of Service</Link>,
    },
];
