import * as React from "react";
import Loader from "@components/elements/Loader/Loader";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { Theme } from "@mui/material/styles";
import { StandardCSSProperties } from "@mui/system";
import axios from "axios";
import { FUNCTIONS_URL } from "@root/lib/constants";
import { User } from "@model/types/user";
import { UtilityStatus } from "./type";

export const utilityInitials = (name = "Utility"): string =>
    name[0].toUpperCase();

export const getStatusStyles = (
    status: UtilityStatus,
    theme: Theme,
): StandardCSSProperties => {
    if (status === "linked") {
        return {
            color: theme.palette.success.contrastText,
            background: theme.palette.success.main,
        };
    }

    if (status === "error") {
        return {
            color: theme.palette.error.contrastText,
            background: theme.palette.error.main,
        };
    }

    if (status === "partially-linked") {
        return {
            color: theme.palette.warning.contrastText,
            background: theme.palette.warning.main,
        };
    }

    // in-progress, unlinked, other...
    return {
        color: theme.palette.common.white,
        background: theme.palette.grey[700],
    };
};

export const getStatusIcon = (status: UtilityStatus): React.ReactNode => {
    if (status === "linked") {
        return <DoneIcon fontSize="inherit" />;
    }

    if (status === "partially-linked") {
        return <InsertLinkOutlinedIcon fontSize="inherit" />;
    }

    if (status === "error") {
        return <PriorityHighIcon fontSize="inherit" />;
    }

    if (status === "in-progress") {
        return <Loader speed={6} color="inherit" />;
    }

    // unlinked, other...
    return <CloseOutlinedIcon fontSize="inherit" />;
};

export const checkLOAStatus = (status?: UtilityStatus): boolean | undefined => {
    if (!status) {
        return false;
    }
    if (status === "linked") {
        return true;
    }
    if (status === "partially-linked") {
        return true;
    }
    if (status === "error") {
        return false;
    }
    if (status === "in-progress") {
        return false;
    }
    // unlinked, other...
    return false;
};

export const getStatusMessage = (
    status?: UtilityStatus,
    accountNumber?: string,
): string | undefined => {
    if (!status) {
        return accountNumber;
    }
    if (status === "linked") {
        return accountNumber;
    }
    if (status === "partially-linked") {
        return accountNumber;
    }
    if (status === "error") {
        return "Utility link issue";
    }
    if (status === "in-progress") {
        return "Processing data";
    }
    // unlinked, other...
    return "Not linked";
};

export const fetchLOA = async (user: User): Promise<string | null> => {
    if (user && user?.id) {
        const accToken = window.btoa(user?.id);
        const http = axios.create({
            baseURL: `${FUNCTIONS_URL}/backend`,
            responseType: "blob",
        });
        const response = await http.post(
            `/app_loa?state=${user.place?.state}`,
            {
                accessToken: accToken,
            },
        );
        return window.URL.createObjectURL(response.data);
    }
    return null;
};
