export const isSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent,
);

// Commented out, but we still want to keep it here just in case.
// const tabletDeviceRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i

const mobileDeviceRegex =
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

export enum DeviceTypes {
    DESKTOP = "desktop",
    MOBILE = "mobile",
}
export const getDeviceType = (device: string): DeviceTypes => {
    if (mobileDeviceRegex.test(device)) {
        return DeviceTypes.MOBILE;
    }

    return DeviceTypes.DESKTOP;
};

export const parseQueryParams = <T = unknown>(query: string): Partial<T> =>
    Object.fromEntries(
        decodeURI(query)
            .substring(1) // cut '?' from start
            .split("&")
            .map((pairStr) => {
                const pair = pairStr.split("=");
                return [pair[0], pair[1]?.replaceAll(/['"`]/g, "")];
            }),
    ) as Partial<T>;
