import * as React from "react";
import { observer } from "mobx-react-lite";

import SubPageLayout from "@pages/account/AccountSectionLayout";
import Typography from "@components/fondation/Typography/Typography";
import NavLink from "@components/elements/NavLink/NavLink";
import { EditableAddress } from "@components/prototypes/dashboard/EditableAddress";
import { AddressCard } from "@components/prototypes/dashboard/AddressCard";
import CardBox from "@components/elements/Card/CardBox";
import useToggle from "@components/prototypes/hooks/useToggle";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";

const FORM_LABEL = "Current Address";

const AddressPage: React.FC = observer(() => {
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));
    const { isActive, handleToggle: toggleEdit } = useToggle(false);

    return (
        <SubPageLayout label="Address">
            {isActive ? (
                <CardBox
                    bg={isMobile ? "secondary" : "primary"}
                    elevation={isMobile ? 0 : 1}
                    p={isMobile ? 0 : [6, 4.5]}
                >
                    <Typography variant="h4" mb={1}>
                        {FORM_LABEL}
                    </Typography>

                    <EditableAddress
                        onSubmit={toggleEdit}
                        onCancel={toggleEdit}
                    />
                </CardBox>
            ) : (
                <AddressCard
                    label={FORM_LABEL}
                    onEdit={toggleEdit}
                    p={isMobile ? 4 : [6, 4.5]}
                />
            )}

            {!isActive && (
                <Typography variant="h6" textAlign="center" mt={5}>
                    <NavLink to="move">I’m moving to a new address</NavLink>
                </Typography>
            )}
        </SubPageLayout>
    );
});

export default AddressPage;
