import { useInstance } from "react-ioc";

import { RateOffer } from "@store/product/RateOffer";
import { PairRowData } from "@components/elements/Table/SimpleTable";
import { potentialSavings } from "@store/selectors/offer.selectors";
import { makeRateTable, getSavingsHeader, SavingsHeader } from "./utils";

interface IRateOffer {
    header: SavingsHeader;
    rateInfo: PairRowData[];
    savings: number;
    offer: RateOffer;
}

export const useRateOffer = (): IRateOffer => {
    const offer = useInstance(RateOffer);

    return {
        header: getSavingsHeader(offer),
        rateInfo: makeRateTable(offer),
        savings: potentialSavings(offer, offer.productStore),
        offer,
    };
};
