import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";

const bgColor = "#EBEBED";
export const TicketPerforation = styled("hr")({
    border: "none",
    overflow: "initial",
    backgroundImage: `linear-gradient(90deg, ${bgColor}, ${bgColor} 70%, transparent 70%, transparent)`,
    backgroundSize: "17px 100%",
    height: "1.5px",
    marginBlock: r(16),
    marginInline: r(-8),
    position: "relative",
    "&:before, &:after": {
        content: "''",
        position: "absolute",
        width: r(16),
        height: r(16),
        borderRadius: r(16),
        background: bgColor,
        top: r(-8),
    },
    "&:before": {
        left: r(-16),
    },
    "&:after": {
        right: r(-16),
    },
});

export default TicketPerforation;
