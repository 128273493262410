import { r } from "@theme/utils";
import styled from "@mui/material/styles/styled";

import { getStatusIcon, getStatusStyles } from "./utils";
import { UtilityStatus } from "./type";

export interface UtilityStatusBadgeProps {
    status: UtilityStatus;
}

export const StatusBadge = styled("span")<UtilityStatusBadgeProps>(
    ({ theme, status }) => ({
        borderRadius: "50%",
        fontSize: r(10),
        width: "1em",
        height: "1em",
        border: "1px solid",
        padding: r(1),
        borderColor: theme.palette.grey[50],
        boxSizing: "content-box",
        ...getStatusStyles(status, theme),
    }),
);

export function UtilityStatusBadge({ status }: UtilityStatusBadgeProps) {
    return <StatusBadge status={status}>{getStatusIcon(status)}</StatusBadge>;
}

export default UtilityStatusBadge;
