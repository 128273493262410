import * as React from "react";
import Typography from "@components/fondation/Typography/Typography";
import Stack from "@components/fondation/Stack/Stack";
import Grid from "@components/fondation/Grid/Grid";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";

export interface CheckoutFormFrameProps {
    detailsSocket?: React.ReactNode;
    addressSocket?: React.ReactNode;
    accountSocket?: React.ReactNode;
    autopilotSocket?: React.ReactNode;
    controlSocket?: React.ReactNode;
}

export const CheckoutFormFrame: React.FC<CheckoutFormFrameProps> = React.memo(
    ({
        detailsSocket,
        addressSocket,
        accountSocket,
        autopilotSocket,
        controlSocket,
    }) => {
        const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

        return (
            <>
                <Typography variant="h2" mb={isMobile ? 7.5 : 5}>
                    Reserve your new rate
                </Typography>
                {isMobile ? (
                    <Grid container spacing={7.5}>
                        <Grid item xs={12}>
                            <Stack spacing={4}>
                                <Typography variant="h4">
                                    Rate Details
                                </Typography>
                                {detailsSocket}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={4}>
                                <Typography variant="h4">My Details</Typography>
                                {addressSocket}
                                {accountSocket}
                                {autopilotSocket}
                                {controlSocket}
                            </Stack>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={8.5}>
                        <Grid item xs={7}>
                            <Stack spacing={4}>
                                {addressSocket}
                                {accountSocket}
                                {controlSocket}
                            </Stack>
                        </Grid>
                        <Grid item xs={5}>
                            <Stack spacing={4}>
                                {detailsSocket}
                                {autopilotSocket}
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    },
);
