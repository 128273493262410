import { useMemo } from "react";
import { useInstances } from "react-ioc";
import UserAvatar from "@components/prototypes/Header/UserAvatar";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { MenuItemProps } from "@components/elements/List/MenuItem";
import { UserStore } from "@store/auth/user.store";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

interface Props {
    onLogout?: () => void;
}

const useAccountMenu = ({ onLogout }: Props): MenuItemProps[][] => {
    const [userStore] = useInstances(UserStore);

    return useMemo(() => {
        const fullName = userStore.user?.name;
        const email = userStore.user?.email;

        return [
            [
                {
                    id: "settings",
                    primaryText: fullName,
                    secondaryText: email,
                    icon: <UserAvatar />,
                },
            ],

            [
                {
                    id: "address",
                    active: true,
                    primaryText: "Address",
                    icon: <MapOutlinedIcon />,
                },
                {
                    id: "utility",
                    primaryText: "Utility Account",
                    icon: <ElectricalServicesOutlinedIcon />,
                },
            ],

            [
                {
                    id: "faq",
                    primaryText: "FAQs",
                    icon: <LiveHelpOutlinedIcon />,
                },
                {
                    id: "support",
                    primaryText: "Support",
                    icon: <SupportOutlinedIcon />,
                },
                {
                    id: "legal",
                    primaryText: "Legal",
                    icon: <PolicyOutlinedIcon />,
                },
            ],

            [
                {
                    id: "logout",
                    primaryText: "Logout",
                    icon: <ExitToAppOutlinedIcon />,
                    onClick: onLogout,
                },
            ],
        ];
    }, [userStore.user, onLogout]);
};

export default useAccountMenu;
