import { useCallback, useMemo, useState, type ReactNode } from "react";
import { FormikProvider, useFormik, yupToFormErrors } from "formik";

import Grid from "@components/fondation/Grid/Grid";
import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import Typography from "@components/fondation/Typography/Typography";
import RadioCard from "@components/elements/Radio/RadioCard";
import { createError } from "@model/utils/error";
import Stack from "@components/fondation/Stack/Stack";
import FormTextField from "@components/modules/forms/common/FormTextField";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { BaseSchema } from "yup";
import {
    getValidationSchema,
    helpersMapper,
    getFiledDefaultLabel,
    getFiledDefaultMessage,
    EnterBillFormData,
} from "./utils";
import { FormConfig } from "./types";
import InfoTooltip from "./InfoTooltip";

const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export const defaultInitialValues: EnterBillFormData = {
    accountNumber: "",
    contactName: "",
    meterNum: undefined,
    referenceNum: undefined,
    nameKey: undefined,
    userRate: undefined,
};

export interface ContactInfoFormProps {
    config?: FormConfig;
    initialValues?: Partial<EnterBillFormData>;
    loading?: boolean;
    children?: ReactNode;
    utilityName?: string;
    onSubmit: (form: EnterBillFormData) => void;
}

function EnterBillForm({
    initialValues = {},
    config,
    loading,
    children,
    utilityName = "utility",
    onSubmit,
}: ContactInfoFormProps): JSX.Element {
    const [isListedAsSupplier, setListedAsSupplier] = useState(
        !initialValues.userRate,
    );

    const validationContext = useMemo(
        () => ({ ...config, isListedAsSupplier }),
        [config, isListedAsSupplier],
    );

    const validationSchema: BaseSchema = useMemo(
        () => getValidationSchema(validationContext),
        [validationContext],
    );

    const initForm = useCallback(
        (prevValues = initialValues) => ({
            ...validationSchema.cast(
                { ...defaultInitialValues, ...prevValues },
                { stripUnknown: true, context: validationContext },
            ),
        }),
        [validationSchema, validationContext],
    );

    const formik = useFormik<EnterBillFormData>({
        initialValues: initForm(),
        validationSchema,
        validateOnChange: true,
        validate: (values) => {
            validationSchema
                .validate(values, { context: validationContext })
                .catch((err) => yupToFormErrors(err));
        },
        onSubmit: (form) => {
            UseRudderStack.INSTANCE.trackEvent(
                "upload_bill-button_click-continue",
                form,
            );
            onSubmit(
                validationSchema.cast(form, {
                    stripUnknown: true,
                    context: validationContext,
                }),
            );
        },
    });

    const getHelperCallback = (filedName: keyof EnterBillFormData) => {
        const messageKey = helpersMapper[filedName];
        const message = messageKey?.filedNameKey
            ? validationContext?.[messageKey.filedNameKey]
            : null;
        const title = messageKey?.labelNameKey
            ? validationContext?.[messageKey?.labelNameKey]
            : null;
        return createError(
            filedName,
            typeof title === "string" ? title : getFiledDefaultLabel(filedName),
            typeof message === "string"
                ? message
                : getFiledDefaultMessage(filedName),
        );
    };

    return (
        <FormikProvider value={formik}>
            <Stack
                component="form"
                flexBasis="100%"
                onSubmit={formik.handleSubmit}
                spacing={8}
            >
                <Stack spacing={3}>
                    {!!children && <>{children}</>}

                    <FormTextField
                        name="contactName"
                        label={getFiledDefaultLabel("contactName")}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InfoTooltip
                                    message={getHelperCallback("contactName")}
                                />
                            ),
                        }}
                        onBlur={() =>
                            UseRudderStack.INSTANCE.trackEvent(
                                "upload_bill-form_fill-full_name",
                            )
                        }
                    />

                    <FormTextField
                        name="accountNumber"
                        label={
                            validationContext?.accountNumLabel ||
                            getFiledDefaultLabel("accountNumber")
                        }
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InfoTooltip
                                    message={getHelperCallback("accountNumber")}
                                />
                            ),
                        }}
                        onBlur={() =>
                            UseRudderStack.INSTANCE.trackEvent(
                                "upload_bill-form_fill-account_number",
                            )
                        }
                    />

                    {!!validationContext?.showNameKey && (
                        <FormTextField
                            name="nameKey"
                            label={getFiledDefaultLabel("nameKey")}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InfoTooltip
                                        message={getHelperCallback("nameKey")}
                                    />
                                ),
                            }}
                        />
                    )}

                    {!!validationContext?.showReferenceNum && (
                        <FormTextField
                            name="referenceNum"
                            label={getFiledDefaultLabel("referenceNum")}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InfoTooltip
                                        message={getHelperCallback(
                                            "referenceNum",
                                        )}
                                    />
                                ),
                            }}
                        />
                    )}

                    {!!validationContext?.showMeterNum && (
                        <FormTextField
                            name="meterNum"
                            label={getFiledDefaultLabel("meterNum")}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InfoTooltip
                                        message={getHelperCallback("meterNum")}
                                    />
                                ),
                            }}
                        />
                    )}
                </Stack>

                <Stack>
                    <Typography variant="h4" mb={1}>
                        Is {utilityName} listed as your supplier?
                    </Typography>
                    <Grid container spacing={3}>
                        {yesNoOptions.map(({ value, label }) => (
                            <Grid item xs={6} key={label}>
                                <RadioCard
                                    fullWidth
                                    checked={isListedAsSupplier === value}
                                    onClick={() => {
                                        UseRudderStack.INSTANCE.trackEvent(
                                            "upload_bill-radio_select-supplier",
                                            { values: value },
                                        );
                                        setListedAsSupplier(value);
                                    }}
                                >
                                    {label}
                                </RadioCard>
                            </Grid>
                        ))}

                        {!validationContext.isListedAsSupplier && (
                            <Grid item xs={12}>
                                <FormTextField
                                    name="userRate"
                                    label={getFiledDefaultLabel("userRate")}
                                    fullWidth
                                    onBlur={(event) => {
                                        UseRudderStack.INSTANCE.trackEvent(
                                            "upload_bill-form_fill-supply_rate",
                                            {
                                                values: event.target.value,
                                            },
                                        );
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InfoTooltip
                                                message={getHelperCallback(
                                                    "userRate",
                                                )}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>

                <Stack flex="1 1 100%" justifyContent="flex-end">
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={loading}
                        endIcon={loading && <Loader />}
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </FormikProvider>
    );
}

export default EnterBillForm;
