import MUIStepLabel from "@mui/material/StepLabel";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";

interface StepLabelProps {}

const StepLabel = styled(MUIStepLabel)<StepLabelProps>(({ theme }) => ({
    ...theme.typography.subtitle1,
    fontWeight: 400,
    paddingBottom: r(8),
    position: "relative",
    ".MuiStepLabel-label": {
        "&.Mui-completed, &.Mui-active": {
            fontWeight: 700,
        },

        "&::after": {
            content: "''",
            position: "absolute",
            height: r(2),
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 1,
            backgroundColor: theme.palette.grey[200],
        },
        "&.Mui-completed::after": {
            color: theme.palette.green.main,
            backgroundColor: theme.palette.green.main,
        },
        "&.Mui-active::after": {
            color: theme.palette.mustard.main,
            backgroundColor: theme.palette.green.main,
        },
    },
    ".MuiStepLabel-iconContainer": {
        display: "none",
        paddingRight: r(4),
        "&.Mui-completed": {
            display: "flex",
            color: theme.palette.green.main,
        },
        ".MuiSvgIcon-root": {
            stroke: "currentColor",
            strokeWidth: r(3),
            fontSize: r(10),
        },
    },
}));

export default StepLabel;
