import * as React from "react";
import Typography from "@components/fondation/Typography/Typography";
import Stack from "@components/fondation/Stack/Stack";
import Button from "@components/elements/Button/Button";
import MarkedItem from "@components/elements/List/MarkedItem";
import { useNavigate } from "react-router-dom";
import { UtilityLinkErrorMessage } from "@model/types/user";

interface CallToLinkAccountProps {
    title: string;
    points: string[];
    isConfigError: null | UtilityLinkErrorMessage;
}

export const CallToLinkAccount: React.FC<CallToLinkAccountProps> = React.memo(
    ({ title, points, isConfigError }) => {
        const navigate = useNavigate();
        const path = isConfigError
            ? "/enrollment/provider/link"
            : "/enrollment/provider/connect";
        const onLink = () => navigate(path);

        return (
            <Stack spacing={3}>
                <Typography variant="h4">{title}</Typography>

                <Stack>
                    {points.map((item) => (
                        <MarkedItem key={item}>{item}</MarkedItem>
                    ))}
                </Stack>

                <Button variant="contained" color="primary" onClick={onLink}>
                    Link Account
                </Button>
            </Stack>
        );
    },
);

export default CallToLinkAccount;
