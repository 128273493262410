import type { ReactNode } from "react";

import Loader from "@components/elements/Loader/Loader";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import Stack from "@components/fondation/Stack/Stack";
import { AppMessage } from "@model/utils/error";

export type LoadingOutletProps = {
    loading?: boolean;
    error?: AppMessage | null;
    children?: ReactNode;
};

function LoadingLayout({
    children,
    loading,
    error,
}: LoadingOutletProps): JSX.Element {
    if (loading) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100vh">
                <Loader size="large" center />
            </Stack>
        );
    }

    if (error != null) {
        return (
            <Alert severity="error">
                <AlertTitle>{error.title ?? "Something went wrong"}</AlertTitle>
                {error.message ?? "Unknown error"}
            </Alert>
        );
    }

    return <>{children}</>;
}

export default LoadingLayout;
