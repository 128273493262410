/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rudderanalytics from "rudder-sdk-js";

class UseRudderStack {
    public static INSTANCE: UseRudderStack = new UseRudderStack();

    public initRS = () => {
        rudderanalytics.load(
            process.env.REACT_APP_RUDDERSTACK_API_KEY as string,
            process.env.REACT_APP_RUDDERSTACK_API_URL as string,
            {
                logLevel: "DEBUG",
                integrations: { All: true },
            },
        );
        rudderanalytics.ready(() => {
            console.log("We are all set!!!");
            this.startSession();
        });
    };

    public getSession = (): string | null | undefined =>
        sessionStorage.getItem("rudderSessionId");

    public startSession = () => {
        if (this.getSession()) {
            rudderanalytics.startSession(Number(this.getSession()));
        } else {
            rudderanalytics.startSession();
            const sessionId = rudderanalytics.getSessionId();
            if (sessionId) {
                sessionStorage.setItem("rudderSessionId", sessionId.toString());
            }
        }
    };

    public trackEvent = (eventName: string, properties?: any) => {
        rudderanalytics.track(eventName, properties);
    };

    public pageEvent = (category: string, name: string, properties?: any) => {
        rudderanalytics.page(category, name, properties, () => {
            console.log("PageEvent", category, name, properties);
        });
    };
}

export default UseRudderStack;

// interface RudderStackProps {
// 	type?: "page" | "track"
// 	name?: string
// 	properties?: any
// 	category?: string
// }

// const useRudderStack = (props?: RudderStackProps) => {
// 	const getSession = (): string | null | undefined => sessionStorage.getItem("rudderSessionId")
// 	const startSession = () => {
// 		if (getSession()) {
// 			rudderanalytics.startSession(Number(getSession()))
// 		} else {
// 			rudderanalytics.startSession()
// 			const sessionId = rudderanalytics.getSessionId()
// 			if (sessionId) {
// 				sessionStorage.setItem("rudderSessionId", sessionId.toString())
// 			}
// 		}
// 	}

// 	useEffect(() => {
// 		rudderanalytics.load("2UNnTtT1Si85vgguFakjLD6zGre", "https://joinarboriuyvt.dataplane.rudderstack.com")
// 		rudderanalytics.ready(() => {
// 			console.log("We are all set!!!")
// 			startSession()
// 		})
// 	}, [])

// 	const trackEvent = (eventName: string) => {
// 		rudderanalytics.track(eventName)
// 	}

// 	const pageEvent = (category: string, name: string, properties?: any) => {
// 		rudderanalytics.page(category, name, properties, () => {
// 			console.log("PageEvent", category, name, properties)
// 		})
// 	}
// 	useEffect(() => {
// 		if (props) {
// 			if (props.type === "page") {
// 				pageEvent(props.category ? props.category : "", props.name as string, props.properties)
// 			} else if (props.type === "track") {
// 				trackEvent(props.name as string)
// 			}
// 		}
// 	}, [props, props && props.name, props && props.type])
// }

// export default useRudderStack
