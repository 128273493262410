import { useEffect, useRef } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";

import BaseModalLayout from "@components/modules/layouts/ModalLayout/BaseModalLayout";
import Modal from "@components/elements/Modal/Modal";
import TermAndPolicyDrawer from "@components/prototypes/TermAndPolicy/TermAndPolicyDrawer";
import { Outlet, useLocation } from "react-router-dom";

const SessionModal: React.FC = observer(() => {
    const drawerContainerRef = useRef<Element>(null);
    const location = useLocation();

    useEffect(() => {
        // On session modal we want user to start on top of the form
        // on every related step. For that reason we try to set scroll position to 0
        // globally for window, also for every scrollable child box.
        window.scrollTo({ top: 0, behavior: "smooth" });
        const boxes = document.getElementsByClassName("modal-box");

        if (!boxes.length) {
            return;
        }
        for (let i = 0; i < boxes.length; i += 1) {
            boxes[i].scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [location]);

    return (
        <Modal open>
            <BaseModalLayout ref={drawerContainerRef}>
                <Outlet />
                <TermAndPolicyDrawer container={drawerContainerRef} />
            </BaseModalLayout>
        </Modal>
    );
});

export default SessionModal;
