import type { ReactNode } from "react";
import MUIThemeProvider from "@mui/material/styles/ThemeProvider";

import defaultTheme from "./defaultTheme";

function ThemeProvider({ children }: { children?: ReactNode }): JSX.Element {
    return <MUIThemeProvider theme={defaultTheme}>{children}</MUIThemeProvider>;
}

export default ThemeProvider;
