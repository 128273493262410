import { ReactElement } from "react";
import NavLink from "@components/elements/NavLink/NavLink";
import Link from "@components/elements/Link/Link";
import { LinkOption } from "./types";

export const navLinkRenderer = ({
    link,
    content,
    type,
}: LinkOption): ReactElement => {
    if (type === "path") {
        return <NavLink to={link}>{content}</NavLink>;
    }
    return <Link href={link}>{content}</Link>;
};
