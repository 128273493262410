import { Utility } from "@model/types/utilities";
import axios from "axios";
import { FUNCTIONS_URL } from "@root/lib/constants";
import AbstractService from "../AbstractService";
import { formatDocument } from "../utils";

type GetUtilityResponse = { ok: boolean; utilityRate: Utility };

export class UtilityService extends AbstractService {
    constructor() {
        super("utilities");
    }

    /* eslint-disable class-methods-use-this */
    getByUserId(userId: string) {
        return axios
            .create({
                baseURL: `${FUNCTIONS_URL}/backend`,
            })
            .post<GetUtilityResponse>("/utility", { userId });
    }
    /* eslint-enable class-methods-use-this */

    getByUtilityId = async (utilityId: string): Promise<Utility> => {
        const snapshot = await this.getById(utilityId);
        return formatDocument(snapshot.data()) as Promise<Utility>;
    };
}
