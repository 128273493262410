import MUIStep from "@mui/material/Step";
import styled from "@mui/material/styles/styled";

interface StepOverrideProps {}

const Step = styled(MUIStep)<StepOverrideProps>({
    flex: "1 1 100%",
    padding: 0,
});

export default Step;
