import { useEffect, useState, Children, type ReactNode } from "react";
import Stack from "@components/fondation/Stack/Stack";
import CarouselPoints from "@components/elements/Carousel/CarouselPoints";
import Carousel from "@components/elements/Carousel/Carousel";
import Elevation from "@components/elements/Card/CardElevation";

export function MobileRateContractContainer({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const [active, setActive] = useState(0);
    const childrenArray = Children.toArray(children);

    useEffect(() => {
        setActive(0);
    }, [childrenArray.length]);

    return (
        <Stack spacing={3}>
            <Carousel index={active} onChangeIndex={setActive}>
                {childrenArray.map((item, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Elevation key={idx}>{item}</Elevation>
                ))}
            </Carousel>
            <CarouselPoints
                index={active}
                slides={childrenArray.length}
                onChangeIndex={setActive}
            />
        </Stack>
    );
}

export default MobileRateContractContainer;
