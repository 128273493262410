import { FUNCTIONS_URL } from "@root/lib/constants";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
    uri: `${FUNCTIONS_URL}/graphql`,
    cache: new InMemoryCache(),
});

export function GQLProvider({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
