import * as React from "react";
import Typography from "@components/fondation/Typography/Typography";
import Stack from "@components/fondation/Stack/Stack";
import CardBox from "@components/elements/Card/CardBox";
import Image from "@components/elements/Image/Image";
import { getImageUrl } from "@components/elements/Image/collection";

const DEFAULT_TITLE = "Something went wrong";
const DEFAULT_MESSAGE =
    "We couldn't load this data. Please try again in a few minutes.";

export interface UnavailableRatePlanProps {
    image?: string;
    title?: string;
    message?: string;
}

export const UnavailableRatePlan: React.FC<UnavailableRatePlanProps> =
    React.memo(
        ({
            title = DEFAULT_TITLE,
            message = DEFAULT_MESSAGE,
            image = getImageUrl("link.png"),
        }) => (
            <CardBox p={6}>
                <Stack alignItems="center" justifyContent="center">
                    <Image src={image} height={80} />
                    <Typography variant="h3" mt={4} mb={1}>
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        textAlign="center"
                        maxWidth={600}
                    >
                        {message}
                    </Typography>
                </Stack>
            </CardBox>
        ),
    );
