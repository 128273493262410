/**
 * @todo This is a candidate for a standalone package, and will be useful to have available on the backend as well (subbing `global` for `window` in the
 * standard Node fashion).  Revisit when we have a monorepo
 */

// adapted from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#subjectpublickeyinfo_import

// eslint-disable-next-line @typescript-eslint/ban-types
type Encryptable = object | string;

const KEY_MATCH_REGEX =
    /-----BEGIN PUBLIC KEY-----\s+(?<key>[\s\S]+)\s+-----END PUBLIC KEY-----/;
const KEY_ALGORITHM = { name: "RSA-OAEP" };
const KEY_ALG_HASH = { ...KEY_ALGORITHM, hash: "SHA-256" };

function stringToArrayBuffer(data: string): ArrayBuffer {
    const finalBuffer = new ArrayBuffer(data.length);
    const bufferedData = new Uint8Array(finalBuffer);

    for (let i = 0; i < data.length; i++) {
        bufferedData[i] = data.charCodeAt(i);
    }
    return finalBuffer;
}

const CRYPTO_KEY = ((pemKey: string | undefined) => {
    const keyContents = pemKey?.match(KEY_MATCH_REGEX)?.groups?.key?.trim();

    if (!keyContents) {
        return null;
    }

    return window.crypto.subtle.importKey(
        "spki",
        stringToArrayBuffer(window.atob(keyContents)),
        KEY_ALG_HASH,
        false,
        ["encrypt"],
    );
})(process.env.REACT_APP_SECURE_BROWSER_DATA_KEY);

export async function encrypt<T extends Encryptable>(
    toEncrypt: T,
): Promise<string> {
    const key = await CRYPTO_KEY;

    if (!key) {
        throw new Error("No key available");
    }

    const encryptedData = await crypto.subtle.encrypt(
        KEY_ALGORITHM,
        key,
        stringToArrayBuffer(JSON.stringify(toEncrypt)),
    );

    return window.btoa(
        String.fromCharCode.apply(null, new Uint8Array(encryptedData) as any),
    );
}
