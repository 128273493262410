import * as React from "react";
import { useNavigate } from "react-router-dom";

import PrivacyPolicy from "@components/prototypes/TermAndPolicy/PrivacyPolicy";
import SubPageLayout from "@pages/account/AccountSectionLayout";
import DashboardLayout from "@components/modules/layouts/DashboardLayout";

const PrivacyPolicyPage: React.FC = React.memo(() => {
    const navigate = useNavigate();

    const handleBack = () => navigate(-1);

    return (
        <DashboardLayout>
            <SubPageLayout
                label="Legal"
                onBack={handleBack}
                maxWidth={570}
                m="auto"
            >
                <PrivacyPolicy />
            </SubPageLayout>
        </DashboardLayout>
    );
});

export default PrivacyPolicyPage;
