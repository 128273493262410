import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";
import { toast } from "react-toastify";
import Typography from "@components/fondation/Typography/Typography";
import Grid from "@components/fondation/Grid/Grid";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import Box from "@components/fondation/Box/Box";
import Stack from "@components/fondation/Stack/Stack";
import { getImageUrl } from "@components/elements/Image/collection";
import CardBox from "@components/elements/Card/CardBox";
import { Link, Divider } from "@mui/material";
import Image from "@components/elements/Image/Image";
import Button from "@components/elements/Button/Button";
import ProductStore from "@store/product/product.store";
import { SuccessToast } from "@components/elements/Toast/Toast";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { makeStyles } from "@mui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const referralBoxContent = {
    title: "Refer a Friend and Get Rewarded",
    message:
        "Help your friends save and we'll send you both $10 when they switch. Copy your referral link below.",
};

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: "3.5rem",
    },
    cardbox: {
        backgroundColor: "#FFE26B",
    },
    mobileImg: {
        height: "150px",
    },
    desktopImg: {
        height: "190px",
    },
}));

export const ReferralBox = observer(() => {
    const [productStore] = useInstances(ProductStore);
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));
    const classes = useStyles();
    const getReferralLink = () => {
        if (productStore?.user?.email) {
            return `https://www.joinarbor.com/referred?referral=${productStore?.user?.email}`;
        }
        return "";
    };

    const successUpdateMessage = () =>
        toast(
            <SuccessToast
                title="Alright"
                description="Thanks for filling out the form. Check for more power-saving recommendations."
            />,
        );

    const emailBody = {
        body: `Hey! Join me on Arbor and get a $10 gift card. Arbor customers save on average $544 dollars on their electricity bill. Sign up today with my link:`,
        subject: `Come join me on Arbor which saved me money on my electricity bill`,
    };

    const smsBody = {
        body: `Hey! Join me on Arbor and get a $10 gift card. Arbor customers save on average $544 dollars on their electricity bill. Sign up today with my link:`,
    };

    const handleCopyToClickboard = () => {
        if (productStore?.user?.email) {
            navigator.clipboard.writeText(getReferralLink());
            successUpdateMessage();
        }
    };

    return isMobile ? (
        <Stack spacing={7.5}>
            <CardBox p={8} className={classes.cardbox}>
                <Grid container spacing={9}>
                    <Grid item lg={3}>
                        <Image height="150px" src={getImageUrl("groovy.png")} />
                    </Grid>
                    <Grid
                        textAlign="center"
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Box mt={5}>
                            <Typography variant="h3">
                                {referralBoxContent.title}
                            </Typography>
                        </Box>
                        <Box mt={5}>
                            <Typography variant="body1">
                                {referralBoxContent.message}
                            </Typography>
                        </Box>
                        <Box mt={5}>
                            <Stack>
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        textAlign="center"
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                    >
                                        <Link
                                            href={`sms:&body=${encodeURIComponent(
                                                smsBody.body,
                                            )} ${encodeURIComponent(
                                                getReferralLink(),
                                            )}`}
                                            variant="body2"
                                        >
                                            <ChatBubbleOutlineIcon
                                                className={classes.icon}
                                            />
                                        </Link>
                                        <Typography variant="h5">
                                            Text
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        textAlign="center"
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                    >
                                        <Link
                                            href={`mailto:?subject=${
                                                emailBody.subject
                                            }&body=${encodeURIComponent(
                                                emailBody.body,
                                            )} ${encodeURIComponent(
                                                getReferralLink(),
                                            )}\n`}
                                        >
                                            <MailOutlineIcon
                                                className={classes.icon}
                                            />
                                        </Link>
                                        <Typography variant="h5">
                                            Email
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                        <Box mt={5}>
                            <Stack>
                                <Button
                                    onClick={handleCopyToClickboard}
                                    variant="contained"
                                >
                                    COPY MY REFERRAL LINK
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </CardBox>
        </Stack>
    ) : (
        <>
            <Stack spacing={6}>
                <Box>
                    <CardBox p={8} className={classes.cardbox}>
                        <Grid container spacing={9}>
                            <Grid item lg={8}>
                                <Box mt={5}>
                                    <Typography variant="h3">
                                        {referralBoxContent.title}
                                    </Typography>
                                </Box>
                                <Box mt={5}>
                                    <Typography variant="body1">
                                        {referralBoxContent.message}
                                    </Typography>
                                </Box>
                                <Box mt={5}>
                                    <Grid container>
                                        <Grid item lg={4} />
                                        <Grid item lg={3} textAlign="left">
                                            <Typography variant="h6">
                                                SHARE YOUR LINK
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} />
                                    </Grid>
                                </Box>
                                <Box mt={3}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={3} />
                                        <Grid item lg={3} alignItems="right">
                                            <Link
                                                href={`sms:&body=${encodeURIComponent(
                                                    smsBody.body,
                                                )} ${encodeURIComponent(
                                                    getReferralLink(),
                                                )}`}
                                                variant="body2"
                                            >
                                                <ChatBubbleOutlineIcon
                                                    sx={{ fontSize: "3.5rem" }}
                                                />
                                            </Link>
                                            <Typography variant="h5">
                                                Text
                                            </Typography>
                                            {/* <IconButton color="primary" onClick={handleCallOnPhone}>

                                            </IconButton> */}
                                        </Grid>
                                        <Grid item lg={3} alignItems="left">
                                            <Link
                                                href={`mailto:?subject=${
                                                    emailBody.subject
                                                }&body=${encodeURIComponent(
                                                    emailBody.body,
                                                )} ${encodeURIComponent(
                                                    getReferralLink(),
                                                )}\n\n`}
                                            >
                                                <MailOutlineIcon
                                                    sx={{ fontSize: "3.5rem" }}
                                                />
                                            </Link>
                                            <Typography variant="h5">
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} />
                                    </Grid>
                                </Box>
                                <Box mt={3}>
                                    <Grid container>
                                        <Grid item lg={3} />
                                        <Grid item lg={5}>
                                            <Divider>
                                                <Typography variant="subtitle2">
                                                    OR
                                                </Typography>
                                            </Divider>
                                        </Grid>
                                        <Grid item lg={3} />
                                    </Grid>
                                </Box>
                                <Box mt={3}>
                                    <Grid container>
                                        <Grid item lg={3} />
                                        <Grid item lg={5}>
                                            <Button
                                                fullWidth
                                                startIcon={<ContentCopyIcon />}
                                                onClick={handleCopyToClickboard}
                                                variant="outlined"
                                            >
                                                COPY LINK
                                            </Button>
                                        </Grid>
                                        <Grid item lg={3} />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item lg={3}>
                                <Image
                                    height="190px"
                                    src={getImageUrl("door_01.png")}
                                />
                            </Grid>
                        </Grid>
                    </CardBox>
                </Box>
            </Stack>
        </>
    );
});

export default ReferralBox;
