import * as React from "react";
import { observer } from "mobx-react-lite";

import CardBox from "@components/elements/Card/CardBox";
import { Typography } from "@mui/material";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import SubPageLayout from "@pages/account/AccountSectionLayout";

const AccountSupportPage: React.FC = observer(() => {
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

    return (
        <SubPageLayout label="Support">
            <CardBox
                bg={isMobile ? "secondary" : "primary"}
                elevation={isMobile ? 0 : 1}
                p={isMobile ? 0 : 6}
            >
                <Typography variant="h4" mb={2.5}>
                    Got questions? We're here to help!
                </Typography>
                <Typography variant="body1">
                    Contact us via email at <b>support@joinarbor.com </b>
                    or through the chat widget in the lower right-hand corner.
                </Typography>
            </CardBox>
        </SubPageLayout>
    );
});

export default AccountSupportPage;
