import * as React from "react";
import Typography from "@components/fondation/Typography/Typography";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";
import { Avatar, Skeleton } from "@mui/material";
import { UtilityStore } from "@store/auth/utilities.store";
import ProductStore from "@store/product/product.store";
import Stack from "@components/fondation/Stack/Stack";
import {
    EditablePreviewCard,
    EditablePreviewCardProps,
} from "./EditablePreviewCard";
import { utilityInitials } from "../utilities/UtilityMeta/utils";

interface AddressCard extends EditablePreviewCardProps {}

export const UtilityPreviewCard: React.FC<AddressCard> = observer(
    ({ label = "Utility Account", ...props }) => {
        const [utilityStore, product] = useInstances(
            UtilityStore,
            ProductStore,
        );
        const { utilityLogo, utilityShortName } = utilityStore;

        const linkData = product.service?.accountNumber
            ? [
                  product.service?.contactName ?? " - ",
                  product.service?.accountNumber,
              ].join(", ")
            : " not linked";

        return (
            <EditablePreviewCard {...props} label={label}>
                <Stack direction="row" gap={2}>
                    {utilityLogo || utilityShortName ? (
                        <Avatar
                            src={utilityLogo}
                            sx={{ height: 20, width: 20 }}
                            variant="rounded"
                        >
                            {utilityInitials(utilityShortName)}
                        </Avatar>
                    ) : (
                        <Skeleton
                            variant="rounded"
                            sx={{ margin: 0 }}
                            width={20}
                            height={20}
                        />
                    )}

                    <Typography variant="body2" alignSelf="center">
                        <b>{utilityShortName}, </b>
                        {linkData}
                    </Typography>
                </Stack>
            </EditablePreviewCard>
        );
    },
);

export default AddressCard;
