import * as React from "react";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";

import DashboardLayout from "@components/modules/layouts/DashboardLayout";
import UserRate from "@components/prototypes/dashboard/UserRate";
import UtilityAccountStore from "@store/auth/utility-account.store";
import { Product } from "@components/prototypes/dashboard/Product/Product";
import AppProvider from "@root/AppProvider";
import ProductStore from "@store/product/product.store";
import { RateOffer } from "@store/product/RateOffer";
import RateContract from "@store/product/RateContract";

AppProvider.register(ProductStore, RateOffer, RateContract);

export const DashboardPage: React.FC = observer(() => {
    const [service] = useInstances(UtilityAccountStore);

    const isContractReady =
        service?.service?.status == null ||
        service?.service?.status === "confirmed" ||
        service?.service?.status === "submitted" ||
        service?.service?.status === "active" ||
        service?.service?.status === "ineligible" ||
        service?.service?.status === "rejected" ||
        service?.service?.status === "dropped";

    return (
        <DashboardLayout>
            {isContractReady ? <UserRate /> : <Product />}
        </DashboardLayout>
    );
});
