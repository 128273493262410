import * as React from "react";
import Grid from "@components/fondation/Grid/Grid";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useInstance, useInstances } from "react-ioc";

import Typography from "@components/fondation/Typography/Typography";
import Image from "@components/elements/Image/Image";
import ModalHeader from "@components/elements/HeaderBar/ModalHeader";
import ModalBox from "@components/elements/Modal/ModalBox";
import { getImageUrl } from "@components/elements/Image/collection";
import SessionStore from "@store/auth/session.store";
import EmailForm, { FormData } from "@components/modules/forms/auth/EmailForm";
import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";

const IMAGE_SRC = getImageUrl("renewables.png");
const TITLE =
    "Find out what incentives and savings are available in your area.";

const MESSAGE = `Arbor helps anyone with a power bill take advantage of the best energy incentives, savings, and renewables in their area. It’s 100% free.`;

const UnsupportedPage: React.FC = observer(() => {
    const { session, isLoading } = useInstance(SessionStore);
    const [sessionStore] = useInstances(SessionStore);
    const navigate = useNavigate();
    const sessionEmail = session?.email || session?.preselect?.email;
    const withEmailForm = sessionEmail == null;

    const handleSubmit = async ({ email }: FormData) => {
        await sessionStore.setCallbackEmail(email);
        sessionStore.redirectEnergyKarma(
            session?.area?.postalCode,
            email || sessionEmail,
        );
    };

    const handleBack = () => {
        navigate("/area");
    };

    return (
        <ModalBox flexBasis="100%">
            <ModalHeader
                BackButtonProps={{ onClick: handleBack }}
                hideEndButton
            />

            <ModalBox flexBasis="100%" p={4}>
                <Grid
                    container
                    spacing={6}
                    justifyContent="center"
                    marginY="auto"
                >
                    <Grid item xs={12} textAlign="center">
                        <Image src={IMAGE_SRC} height={120} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h2" textAlign="center" mb={3}>
                            {TITLE} <br />
                        </Typography>
                        <Typography
                            variant="body1"
                            color="grey.800"
                            textAlign="center"
                        >
                            {MESSAGE}
                            <br />
                            <br />
                            {withEmailForm}
                        </Typography>
                    </Grid>

                    {withEmailForm ? (
                        <Grid item xs={12}>
                            <EmailForm
                                submitLabel="Check Available Savings"
                                loading={isLoading}
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={handleSubmit}
                                disabled={isLoading}
                                endIcon={isLoading && <Loader />}
                            >
                                Check Available Savings
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </ModalBox>
        </ModalBox>
    );
});

export default UnsupportedPage;
