import { inject } from "react-ioc";
import { autorun, makeAutoObservable } from "mobx";

import { AppError, createError } from "@model/utils/error";
import { UtilityService } from "@services/firebase/utility.service";
import { PreferenceRenewableOption } from "@services/firebase/user.service/request.types";
import UserAPIService from "@services/firebase/user.service";
import { UserService as IUserService } from "@model/types/user";
import { UserStore } from "./user.store";
import SessionStore from "./session.store";

class UtilityAccountStore {
    // injections

    userService = inject(this, UserAPIService);

    userStore = inject(this, UserStore);

    sessionStore = inject(this, SessionStore);

    utilitiesService = inject(this, UtilityService);

    // constructors

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            if (!this.isAutopilotSync) {
                this.autopilot = Boolean(this.userStore.user?.autopilot);
            }
        });
        autorun(() => {
            if (!this.isPreferenceSync) {
                this.renewable = this.userStore.user?.renewable;
            }
        });
    }

    // attributes

    error: AppError | null = null;

    isAutopilotSync = false;

    isPreferenceSync = false;

    autopilot = false;

    renewable?: PreferenceRenewableOption = "not-important";
    // computed

    get service(): IUserService | undefined {
        return this.userStore.user?.services?.electric;
    }

    get hasLink(): boolean {
        return this.service?.utility?.link != null;
    }

    // actions

    toggleAutopilot = async (autopilot: boolean) => {
        try {
            this.isAutopilotSync = true;
            this.autopilot = autopilot;
            this.error = await this.userStore.inUserScope({
                call: async (userId) => {
                    await this.userService.updateLog(
                        userId,
                        "preference-autopilot",
                        {
                            serviceType: "electric",
                            autopilot: this.autopilot,
                        },
                    );
                    await this.userStore.whenUserUpdated(
                        (user) => user.autopilot === autopilot,
                    );
                },
            });
        } catch (error) {
            this.error = createError(
                "UNKNOWN",
                "Error",
                "Unexpected error while switch autopilot",
            );
        } finally {
            this.isAutopilotSync = false;
        }
        return this.error;
    };

    changePreference = async (renewable: PreferenceRenewableOption) => {
        try {
            this.isPreferenceSync = true;
            this.renewable = renewable;

            this.error = await this.userStore.inUserScope({
                call: async (userId) => {
                    await this.userService.updateLog(
                        userId,
                        "preference-renewable",
                        {
                            serviceType: "electric",
                            selectedOption: renewable,
                        },
                    );
                    await this.userStore.whenUserUpdated(
                        (user) => user.renewable === renewable,
                    );
                },
            });
        } catch (error) {
            this.error = createError(
                "UNKNOWN",
                "Error",
                "Unexpected error while change preferences",
            );
        } finally {
            this.isPreferenceSync = false;
        }
        return this.error;
    };
}

export default UtilityAccountStore;
