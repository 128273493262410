import { type RateOffer } from "@store/product/RateOffer";
import { type ProductStore } from "@store/product/product.store";

import { isRenewable } from "./plan.selectors";
import { hasBetterRateAvailable } from "./rates.selectors";
import { serviceStatusOneOf } from "./service.selectors";

export function potentialSavings(
    offer: RateOffer,
    product: ProductStore,
): number {
    if (isRenewable(product)) {
        return 0;
    }

    const serviceStatus = serviceStatusOneOf(product);

    if (
        serviceStatus("searching", "review") ||
        (serviceStatus("user-approving") &&
            hasBetterRateAvailable(product, offer))
    ) {
        return offer.savingsToCurrent;
    }

    return Math.max(offer.savingsToAvg, offer.savingsToBase);
}
