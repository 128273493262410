import { SUPPORT_ROUTE } from "@model/constants/utilities/app";
import { StaticUtility } from "@model/constants/utilities/utilities-list";
import utilitiesMap from "@model/constants/utilities/utilities-map";
import {
    ServiceStatus,
    ServiceUtility,
    UtilityLinkErrorMessage,
} from "@model/types/user";
import { AppError } from "./error";

const resourceUrl = process.env.REACT_APP_STATIC_URL;

// @deprecated
export const getUtilityByID = (utilityId: string): StaticUtility =>
    utilitiesMap[utilityId] ?? null;

export const getUtilityLogo = (
    utility?: StaticUtility | null,
): string | undefined => {
    if (!utility || !utility?.key) {
        return undefined;
    }
    return `${resourceUrl}/utility/${utility.key}/logo.png`;
};

export const getUtilityLogoSrc = (utilityId: string): string | undefined => {
    const utility = getUtilityByID(utilityId);
    return getUtilityLogo(utility);
};

export const getUtilityShortName = (
    utility?: StaticUtility | ServiceUtility | null,
): string => {
    if (!utility) {
        return "utility";
    }
    return utility?.name?.replaceAll(/\(.+\)/g, "").trim();
};
type ErrorMapType = Record<
    UtilityLinkErrorMessage,
    (utility: string) => AppError<UtilityLinkErrorMessage>
>;
const UTILITY_LINK_ERRORS_MAP: ErrorMapType = {
    LOGIN_FAILURE: (utilityName) => ({
        code: "LOGIN_FAILURE",
        title: `We were unable to sign in to your ${utilityName} account`,
        message: "Please double check your credentials and try again",
    }),
    NO_ACCOUNTS_FOUND: (utilityName) => ({
        code: "NO_ACCOUNTS_FOUND",
        title: `We were unable to sign in to your ${utilityName} account`,
        message: "Please double check your credentials and try again",
    }),
    MULTI_FACTOR_AUTH_FAILURE: (utilityName) => ({
        code: "MULTI_FACTOR_AUTH_FAILURE",
        title: `It looks like you have Multi-factor Authentication enabled on your ${utilityName} account`,
        message:
            "Please disable MFA on your utility's website or select another linking option",
    }),
    UNSUPPORTED_CAPTCHA: (utilityName) => ({
        code: "UNSUPPORTED_CAPTCHA",
        title: `It looks like your ${utilityName} account is protected by a CAPTCHA`,
        message:
            "Please disable this on your utility's website or select another linking option",
    }),
    USER_ACTION_REQUIRED: (utilityName) => ({
        code: "USER_ACTION_REQUIRED",
        title: `We signed in to your ${utilityName} account, but were unable to access your utility data`,
        message: "If this error persists, please select another linking option",
    }),
    SECURITY_QUESTIONS: (utilityName) => ({
        code: "SECURITY_QUESTIONS",
        title: `It looks like your ${utilityName} account is protected by additional security questions`,
        message:
            "Please disable them on your utility's website or select another linking option",
    }),
    ACCOUNT_LOCKED: (utilityName) => ({
        code: "ACCOUNT_LOCKED",
        title: `It looks like your ${utilityName} account is locked`,
        message:
            "Please unlock it on your utility's website or select another linking option",
    }),
    E_BILL_REQUIRED: (utilityName) => ({
        code: "E_BILL_REQUIRED",
        title: `It looks like your ${utilityName} account is not setup for electronic billing`,
        message:
            "Please enable this on your utility's website settings or select another linking option",
    }),
    ERROR_OTHERS: (utilityName) => ({
        code: "ERROR_OTHERS",
        title: `It is taking longer than expected to link your ${utilityName} account`,
        message: "If this error persists, please select another linking option",
    }),
};

export const parseUtilityLinkError = (
    utility: ServiceUtility,
): AppError<UtilityLinkErrorMessage> => {
    const key = utility.link?.error?.message;
    const utilityName = getUtilityShortName(utility);
    if (!key || !UTILITY_LINK_ERRORS_MAP[key]) {
        return UTILITY_LINK_ERRORS_MAP.ERROR_OTHERS(utilityName);
    }
    return UTILITY_LINK_ERRORS_MAP[key](utilityName);
};

// FE Service status keys
export type UtilityAccountStatus =
    | "PROCESSING_DATA"
    | "SEARCHING_RATE"
    | "RATE_FOUND"
    | "RATE_CONFIRMED"
    | "INCOMPLETE"
    | "NONE"
    | "ACTIVE";

export type UtilityAccountStatusDescriptor = {
    key: UtilityAccountStatus;
    label: string;
    description: string;
    step: number;
};

// service status descriptive content
export const AVAILABLE_STATUSES_MAP: Record<
    UtilityAccountStatus,
    UtilityAccountStatusDescriptor
> = {
    NONE: {
        key: "NONE",
        label: "Not Connected",
        description:
            "We need your account information for your service to start monitoring for a lower rate.",
        step: 1,
    },
    PROCESSING_DATA: {
        key: "PROCESSING_DATA",
        label: "Processing utility data",
        description:
            "We are processing your utility data. This can take up to 24 hours. We will notify you when complete, and begin searching for a better rate.",
        step: 2,
    },
    SEARCHING_RATE: {
        key: "SEARCHING_RATE",
        label: "Searching for a better rate",
        description:
            "You're already on the best available rate. That's great! You'll hear from us again when a lower rate becomes available.",
        step: 3,
    },
    INCOMPLETE: {
        key: "INCOMPLETE",
        label: "Additional info needed",
        description:
            "Check your email for additional instructions, or reach out to support and we'll be happy to assist.",
        step: 4,
    },
    RATE_FOUND: {
        key: "RATE_FOUND",
        label: "New rate found",
        description:
            "We found you a better rate based on your preferences and requested it on your behalf. Once confirmed, we will update you, usually within 2 weeks.",
        step: 5,
    },
    RATE_CONFIRMED: {
        key: "RATE_CONFIRMED",
        label: "New rate confirmed",
        description: `We found you a better rate based on your preferences and your enrollment is now confirmed. You should see it on your utility bill within 1-2 billing cycles. If you have any questions, reach out to [support](${SUPPORT_ROUTE}).`,
        step: 6,
    },
    ACTIVE: {
        key: "ACTIVE",
        label: "Active",
        description: "",
        step: 7,
    },
};

export const getStatusDescription = (
    status?: ServiceStatus,
): UtilityAccountStatusDescriptor => {
    if (!status) {
        return AVAILABLE_STATUSES_MAP.NONE;
    }

    if (["pending"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.PROCESSING_DATA;
    }

    if (["searching"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.SEARCHING_RATE;
    }

    if (["incomplete", "rejected"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.INCOMPLETE;
    }

    if (["user-approving", "review", "submitted"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.RATE_FOUND;
    }

    if (["confirmed"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.RATE_CONFIRMED;
    }

    if (["active"].includes(status)) {
        return AVAILABLE_STATUSES_MAP.ACTIVE;
    }

    return AVAILABLE_STATUSES_MAP.NONE;
};
