import type { ReactNode } from "react";

import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Box from "@components/fondation/Box/Box";
import Typography from "@components/fondation/Typography/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@components/fondation/Stack/Stack";
import CardBox from "../CardBox";

const Container = styled(CardBox)(({ theme, color }) => ({
    display: "flex",
    flexDirection: "column",
    border: "none",
    background:
        color === "primary"
            ? theme.palette.common.white
            : theme.palette.grey[50],
    boxShadow: color === "primary" ? undefined : "none",
    height: "100%",

    ".Header-root": {
        margin: r(-16),
        marginBottom: r(16),
        borderRadius: 0,
        borderTopRightRadius: theme.shape.borderRadius,
        borderTopLeftRadius: theme.shape.borderRadius,
    },

    ".Footer-root": {
        margin: r(-16),
        marginTop: r(16),
        borderRadius: 0,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
}));

export default function RatePlanCard({
    header,
    supplier,
    children,
    footer,
    color = "primary",
    controlSocket,
    ...restProps
}: {
    header?: ReactNode;
    supplier?: ReactNode;
    children?: ReactNode;
    footer?: ReactNode;
    controlSocket?: ReactNode;
    color?: "primary" | "secondary";
}): JSX.Element {
    return (
        <Container color={color} {...restProps}>
            {header}

            <Stack mb={2.5} direction="row" justifyContent="space-between">
                <Box>
                    <Typography variant="caption">Supplier</Typography>
                    <Typography variant="subtitle1">{supplier}</Typography>
                </Box>

                {controlSocket}
            </Stack>

            <Divider />

            <Box flexBasis="100%" mt={2.5}>
                {children}
            </Box>

            {footer}
        </Container>
    );
}
