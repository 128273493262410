import * as React from "react";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Typography from "@components/fondation/Typography/Typography";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@components/fondation/Box/Box";
import CardBox from "../CardBox";

export interface CollapsibleCardProps<I extends number> {
    index: I;
    title: string;
    children: React.ReactNode;
    color?: (index: I) => string;
}

const Container = styled(CardBox, { target: "SequenceCard-root" })({
    display: "flex",
    flex: "1 1 100%",
});

const Badge = styled(Typography)<{ color: string }>(({ theme, color }) => {
    const size = r(32);
    return {
        paddingBlock: r(6),
        textAlign: "center",
        minWidth: size,
        width: size,
        height: size,
        backgroundColor: color,
        color: theme.palette.common.white,
        borderRadius: "50%",
        marginRight: r(14),
        marginBottom: r(14),
        position: "relative",
        "&:before": {
            content: "''",
            position: "absolute",
            inset: r(-3),
            border: `6px solid ${alpha(color, 0.2)}`,
            boxSizing: "content-box",
            borderRadius: "inherit",
        },
    };
});

const Content = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[800],
}));

const SequenceCard = React.memo(
    <I extends number>(props: CollapsibleCardProps<I>) => {
        const { children, title, index, color, ...rest } = props;

        return (
            <Container {...rest}>
                <Badge variant="h6" color={color?.(index) ?? "#000"}>
                    {index.toString().padStart(2, "0")}
                </Badge>

                <Box>
                    <Typography variant="h5" mb={1} color="grey.900">
                        {title}
                    </Typography>

                    <Content variant="body2">{children}</Content>
                </Box>
            </Container>
        );
    },
);

export default SequenceCard;
