// @deprecated
export interface StaticUtility {
    id: string;
    name: string;
    key: string;
    logo?: boolean;
    bill?: number;
}

// @deprecated
export const utilitiesList: StaticUtility[] = [
    {
        id: "f4379132e6af49d09ea998060cbf5928",
        name: "AEP Energy (Columbus Southern)",
        key: "aep",
        logo: true,
        bill: 3,
    },
    {
        id: "12e5a6a42c5f4fbc95cbb8acf265c4f0",
        name: "AEP Energy (Ohio Power)",
        key: "aep",
        logo: true,
        bill: 3,
    },
    {
        id: "588f51ee4552d6a6014556fa66080012",
        name: "AEP Texas Central Co.",
        key: "aep",
        logo: true,
        bill: 3,
    },
    {
        id: "588f51ee4552d6a6014556fa1f650011",
        name: "AEP Texas North Co.",
        key: "aep",
        logo: true,
        bill: 3,
    },
    {
        id: "58af8fbcd8ec45bda107044c8701c9b4",
        name: "AES Ohio",
        key: "aes",
        logo: true,
        bill: 2,
    },
    {
        id: "7476794b970a4101986917c483f392fb",
        name: "ATCO Electric",
        key: "atcoelec",
    },
    {
        id: "3eedcce6fe5341f2a55eabf8ae9dbc27",
        name: "ATCO Gas",
        key: "atcogas",
    },
    {
        id: "588f1795677458ca0167adf887f06fa6",
        name: "Alberta Inc.",
        key: "alb",
    },
    {
        id: "588f17956733a11e0167426ed8ac02b3",
        name: "Alectra Utilities",
        key: "au",
    },
    {
        id: "588f1795677458ca0167a9ac27f66dc9",
        name: "Algoma Power Inc.",
        key: "alg",
    },
    {
        id: "588f51ee4b0cf546014b43a5d5ff200d",
        name: "Alliant",
        key: "alliant",
    },
    {
        id: "588f51ee4b0cf546014b439b725f1f4b",
        name: "Alliant Energy",
        key: "alliant",
    },
    {
        id: "588f1795684b314d01689671b6df13dd",
        name: "Alta Gas",
        key: "ag",
    },
    {
        id: "909524420c2c41938e4c17ac076bac9d",
        name: "Ameren",
        key: "ameren",
        logo: true,
        bill: 4,
    },
    {
        id: "ef3aee3eb6e44a1c874ace852f6c8268",
        name: "Apex Utilities",
        key: "apex",
    },
    {
        id: "58464f0055fe67ab0156514d30101307",
        name: "Apopka Natural Gas",
        key: "apopka",
    },
    {
        id: "588f17956ba5399f016bb40a768321c1",
        name: "Appalachian Power",
        key: "apco",
    },
    {
        id: "58464f0063e099c30163f1111eeb10f5",
        name: "ArkLa - (CenterPoint)",
        key: "arkla",
    },
    {
        id: "588f1795677458ca0167a9aec5db6dcd",
        name: "Atikokan Hydro Inc.",
        key: "atk",
    },
    {
        id: "58464f004dd10fe7014de99a5f790407",
        name: "Atlanta Gas Light",
        key: "agl",
        logo: true,
        bill: 1,
    },
    {
        id: "11c4363d5ddc4c7ea8b1845bbf1ce803",
        name: "Atlantic City Electric Co",
        key: "aeco",
        logo: true,
        bill: 3,
    },
    {
        id: "58464f005652d31b0156757f0f3108f1",
        name: "Atmos",
        key: "atmos",
    },
    {
        id: "58464f0063e099c30163f087d37f0b1f",
        name: "Atmos Energy",
        key: "ae",
    },
    {
        id: "588f1795677458ca0167aa0704496ecd",
        name: "BC Hydro",
        key: "bch",
    },
    {
        id: "6ae8dff8221e4659a9ea105844f815b2",
        name: "Baltimore Gas and Electric",
        key: "bge",
        logo: true,
        bill: 3,
    },
    {
        id: "58464f0063e099c30163f0b54fa00b2e",
        name: "Black Hills Energy",
        key: "bhe",
    },
    {
        id: "58464f0063e099c30163f0c1761110f2",
        name: "Blackhills (SourceGas)",
        key: "sg",
    },
    {
        id: "588f1795677458ca0167a9b09a156dd2",
        name: "Bluewater Power Distribution Corporation",
        key: "bpd",
    },
    {
        id: "588f17956e81bdea016e938b46e60a2a",
        name: "Boralex",
        key: "boralex",
    },
    {
        id: "588f1795677458ca0167a9b27df76dd8",
        name: "Brant Power Company Inc.",
        key: "bpc",
    },
    {
        id: "588f1795677458ca0167a9b3b4236ddc",
        name: "Brantford Power Inc.",
        key: "btf",
    },
    {
        id: "588f1795677458ca0167a9b505ba6dde",
        name: "Burlington Hydro Inc.",
        key: "bhy",
    },
    {
        id: "1ac3bcc7155445d8a2efb94debbe9fea",
        name: "COMED",
        key: "comed",
        logo: true,
        bill: 1,
    },
    {
        id: "693b700470e14259a07a99949636ec63",
        name: "CONED",
        key: "coned",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9b7295f6de0",
        name: "Cambridge and North Dumfries Hydro Inc.",
        key: "cdnh",
    },
    {
        id: "588f1795684b314d0168963ee5c613b3",
        name: "Canadian Niagara Power Inc. (Fortis Ontario Company)",
        key: "cn",
    },
    {
        id: "588f1795684b314d0168966f10e413dc",
        name: "Cardston",
        key: "crs",
    },
    {
        id: "588f17956e81bdea016e938bcd3f0a2b",
        name: "Cartier Wind Energy",
        key: "cwe",
    },
    {
        id: "588f1795677458ca0167a9b9523b6de3",
        name: "Cat Lake Power Utility",
        key: "clpu",
    },
    {
        id: "58464f0063e099c30163f02b61d50af9",
        name: "CenterPoint Energy",
        key: "cpe",
        logo: true,
    },
    {
        id: "588f51ee4552d6a6014556f9cd470010",
        name: "CenterPoint Energy Houston Electric, LLC",
        key: "cpe",
        logo: true,
    },
    {
        id: "58464f0052565f60015276d80a2803c3",
        name: "Central Florida Gas",
        key: "cfg",
    },
    {
        id: "f318f9ea0c4c4660aa8a43fff577a1c6",
        name: "Central Hudson",
        key: "cenhud",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4552d6a6014556f7bac9000b",
        name: "Central Maine Power Company",
        key: "cmp",
        logo: true,
    },
    {
        id: "588f1795677458ca0167a9bb56e76de5",
        name: "Centre Wellington Hydro Ltd.",
        key: "cwh",
    },
    {
        id: "588f1795677458ca0167a9b278e36dd7",
        name: "Chapleau Public Utilities Corporation",
        key: "cpuc",
    },
    {
        id: "58464f004ea9251a014eab7d0d10000c",
        name: "Chesapeake Utilities Corp",
        key: "chesapeake",
    },
    {
        id: "58464f0063e099c30163f6013e1a118d",
        name: "Cheyenne Light and Fuel",
        key: "clf",
    },
    {
        id: "588f51ee476c6c7c01476d4dd558222c",
        name: "Citizens",
        key: "citizens",
    },
    {
        id: "aaaeaf71a2cf4aafbe72fc1983807e18",
        name: "Cleveland Illuminating Co",
        key: "cei",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d0168964600e013b5",
        name: "Collus PowerStream Energy Corp",
        key: "co",
    },
    {
        id: "58464f0063e099c30163f1158be310f9",
        name: "Colorado Natural Gas",
        key: "cng",
    },
    {
        id: "588f51ee482d5fbb014832cda6393e10",
        name: "Columbia Gas",
        key: "columbia",
        bill: 1,
    },
    {
        id: "588f17956e81bdea016e93877f660a28",
        name: "Columbia Power Corporation",
        key: "colpc",
    },
    {
        id: "58464f0063e099c30163f617844311ac",
        name: "Connecticut Natural Gas",
        key: "cng",
    },
    {
        id: "588f51ee4b0cf546014b439c21721f55",
        name: "Consumers Energy",
        key: "consumers",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d016896482daa13b6",
        name: "Cooperative Hydro Embrun Inc.",
        key: "he",
    },
    {
        id: "588f1795677458ca0167a9bed2916de6",
        name: "Cornwall Electric",
        key: "ce",
    },
    {
        id: "588f1795684b314d016887a843941208",
        name: "Crowsnest Pass",
        key: "crp",
    },
    {
        id: "588f51ee4b0cf546014b439cf7e01f67",
        name: "DTE Energy",
        key: "dte",
        logo: true,
        bill: 1,
    },
    {
        id: "347a3f48836346648c1e22c340a6498e",
        name: "Delmarva Power & Light",
        key: "dpl",
        logo: true,
        bill: 1,
    },
    {
        id: "58464f0063d8a29d0163dc5c2d68014c",
        name: "Delta Natural Gas",
        key: "delta",
    },
    {
        id: "588f51ee4c379050014c8b7686bb2033",
        name: "Dominion East Ohio",
        key: "dominion",
        logo: true,
        bill: 2,
    },
    {
        id: "588f17956ba5399f016bb403a37721bf",
        name: "Dominion Energy Virginia",
        key: "dominion",
        logo: true,
        bill: 2,
    },
    {
        id: "58464f0063e099c30163f0247e290af0",
        name: "Dominion Hope Gas",
        key: "dominion",
        logo: true,
        bill: 2,
    },
    {
        id: "b050681dc11e41a686076c676a2cd16e",
        name: "Duke Energy",
        key: "duke",
        logo: true,
        bill: 1,
    },
    {
        id: "316eb5dc5ae6469196054fef29b139c6",
        name: "Duquesne Light",
        key: "duq",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9c039dc6dea",
        name: "E.L.K. Energy Inc.",
        key: "elk",
    },
    {
        id: "588f51ee4552d6a6014556f76780000a",
        name: "EMERA (Formerly Bangor Hydro-Electric Company)",
        key: "bangor",
    },
    {
        id: "588f51ee4552d6a6014556f6e4cf0009",
        name: "EMERA (Formerly Maine Public Service Company)",
        key: "mpsc",
    },
    {
        id: "588f1795677458ca0167ae01e4556faf",
        name: "ENMAX",
        key: "enmx",
    },
    {
        id: "588f17956733a11e0167426d993d02b2",
        name: "EPCOR Utilities Inc",
        key: "epcor",
    },
    {
        id: "588f1795684b314d0168967e97f813e1",
        name: "EQUS REA",
        key: "eqr",
    },
    {
        id: "588f51ee4a49fc5a014a5fb938da0178",
        name: "Elizabethtown Gas",
        key: "etg",
        logo: true,
    },
    {
        id: "58464f0063e099c30163f0bae6c510e3",
        name: "Empire District Gas",
        key: "edg",
    },
    {
        id: "588f1795677458ca0167a9c401ca6df3",
        name: "EnWin Utilities Ltd.",
        key: "eul",
    },
    {
        id: "588f1795677458ca0167aa036d016ec5",
        name: "Enbridge Inc.",
        key: "enb",
    },
    {
        id: "588f1795677458ca0167a9c181e66deb",
        name: "Energy+ Inc.",
        key: "eni",
    },
    {
        id: "588f1795677458ca0167a9c2e9286df1",
        name: "Entegrus Powerlines Inc.",
        key: "epi",
    },
    {
        id: "58464f0063fe4cba01641ecf7adb04eb",
        name: "Equitable Gas",
        key: "equitable",
    },
    {
        id: "588f1795677458ca0167a9c5418e6df5",
        name: "Erie Thames Powerlines Corp.",
        key: "etp",
    },
    {
        id: "588f1795677458ca0167a9c6b0a56df9",
        name: "Espanola Regional Hydro Distribution Corp.",
        key: "espl",
    },
    {
        id: "588f1795677458ca0167a9c815716dff",
        name: "Essex Power Corporation",
        key: "esx",
    },
    {
        id: "58464f0054440eed01544f03e1dd00c9",
        name: "Eversource (Columbia Gas of Massachusetts - Bay State Gas) ",
        key: "eversource",
        logo: true,
        bill: 3,
    },
    {
        id: "65bbaede52f44fb0bc860ea04f00488e",
        name: "Eversource (Connecticut Light Power)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "f142db3b945d43839177ec0035506284",
        name: "Eversource (Formerly Boston Edison - NSTAR)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "58464f006197a2bb0161b94b72bc0611",
        name: "Eversource (Formerly Cambridge - NSTAR)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "58464f006197a2bb0161b94a57870610",
        name: "Eversource (Formerly Commonwealth - NSTAR)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "588f51ee4552d6a6014556f912c8000e",
        name: "Eversource (Formerly PSNH)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "c18b2ab11f1c4254b10dea7746bcfd9b",
        name: "Eversource (Western Massachusetts Electric Company)",
        key: "eversource",
        logo: true,
        bill: 2,
    },
    {
        id: "c90962d064444a9598e188560ce01a33",
        name: "FORTIS Alberta",
        key: "fortisab",
    },
    {
        id: "588f1795677458ca0167a9c9b5176e03",
        name: "Festival Hydro Inc.",
        key: "fstl",
    },
    {
        id: "58464f004dd10fe7014de99ecf800408",
        name: "Florida City Gas",
        key: "fcg",
        bill: 1,
    },
    {
        id: "58464f0052565f60015276d31dc203c2",
        name: "Florida Public Utilities Company",
        key: "fpu",
    },
    {
        id: "588f1795677458ca0167a9caade16e04",
        name: "Fort Albany Power Corporation",
        key: "fap",
    },
    {
        id: "588f1795677458ca0167a9cc957c6e11",
        name: "Fort Frances Power Corp.",
        key: "ffp",
    },
    {
        id: "588f1795684b314d0168967f6a8513e2",
        name: "Fort Macleod",
        key: "fm",
    },
    {
        id: "588f17956e81bdea016e938810e10a29",
        name: "FortisBC",
        key: "fortisbc",
    },
    {
        id: "58464f0063e099c30163f60f6ff31192",
        name: "Frederick Gas",
        key: "fs",
    },
    {
        id: "58464f0063e099c30163f61b101711b0",
        name: "Frontier",
        key: "frontier",
    },
    {
        id: "588f1795677458ca0167a9cd8b696e13",
        name: "Greater Sudbury Hydro",
        key: "gsh",
    },
    {
        id: "58464f0063e099c30163f02084710ae7",
        name: "Greer",
        key: "greer",
    },
    {
        id: "588f1795677458ca0167a9cf0c7c6e14",
        name: "Grimsby Power Inc.",
        key: "grby",
    },
    {
        id: "588f1795677458ca0167a9d0a1336e18",
        name: "Guelph Hydro",
        key: "guel",
    },
    {
        id: "58464f0063e099c30163f5f7b8f11185",
        name: "Gulf South",
        key: "gulf",
    },
    {
        id: "588f1795677458ca0167ae07c8fc6fb1",
        name: "Haldimand County Hydro",
        key: "hld",
    },
    {
        id: "588f1795677458ca0167a9d4db886e1e",
        name: "Halton Hills Hydro",
        key: "hhh",
    },
    {
        id: "588f1795677458ca0167ae0db5086fb2",
        name: "Hamilton Hydro Inc",
        key: "ham",
    },
    {
        id: "588f1795677458ca0167a9d1d9c16e1a",
        name: "Hearst Power Distribution Company Ltd.",
        key: "hpdc",
    },
    {
        id: "588f1795684b314d0168965ba9bf13d3",
        name: "Horizon Utilities Corporation (Hamilton)",
        key: "ha",
    },
    {
        id: "588f1795677458ca0167aa01e2a16ec4",
        name: "Hydro 2000 Inc.",
        key: "h2k",
    },
    {
        id: "588f1795677458ca0167a9d3696d6e1b",
        name: "Hydro Hawkesbury Inc.",
        key: "hhi",
    },
    {
        id: "588f17956733a11e016742d1c30902c8",
        name: "Hydro One",
        key: "ho",
    },
    {
        id: "588f1795684b314d0168965d0a3c13d4",
        name: "Hydro One Brampton Networks Inc",
        key: "hb",
    },
    {
        id: "588f17956733a11e016742d36b7a02c9",
        name: "Hydro Ottawa",
        key: "hot",
    },
    {
        id: "588f17956e81bdea016e938c91a30a2c",
        name: "Hydro-Quebec",
        key: "hyq",
    },
    {
        id: "588f17956e81bdea016e938d29740a2d",
        name: "Innergex Renewable Energy",
        key: "ire",
    },
    {
        id: "588f1795684b314d0168966234cf13d5",
        name: "Innisfil Hydro Distribution Systems Ltd",
        key: "ih",
    },
    {
        id: "588f1795677458ca0167a9d6cae56e23",
        name: "Innpower Corporation",
        key: "innp",
    },
    {
        id: "fbb75858f16a464088e539c5f2454f63",
        name: "Jersey Central Power & Light",
        key: "jcpl",
        logo: true,
        bill: 1,
    },
    {
        id: "58464f0063e099c30163f60d11bf1191",
        name: "Jo-Carroll",
        key: "jocarroll",
        logo: true,
    },
    {
        id: "58464f0063e099c30163f042bef60b08",
        name: "Kansas Gas Service",
        key: "kgs",
    },
    {
        id: "588f1795677458ca0167a9d927db6e2b",
        name: "Kashechewan Power Corporation",
        key: "kcp",
    },
    {
        id: "588f1795677458ca0167a9d81d3e6e29",
        name: "Kenora Hydro Electric Company",
        key: "khe",
    },
    {
        id: "588f1795677458ca0167a9da2b636e2d",
        name: "Kingston Utilities",
        key: "kiu",
    },
    {
        id: "588f1795677458ca0167a9db9a656e32",
        name: "Kitchener-Wilmot Hydro",
        key: "kwh",
    },
    {
        id: "58464f0063e099c30163f0b850271098",
        name: "Laclede Gas Company",
        key: "lgc",
    },
    {
        id: "588f1795677458ca0167a9dca21e6e34",
        name: "Lakefront Utilities",
        key: "lfu",
    },
    {
        id: "588f1795677458ca0167a9de4b856e3f",
        name: "Lakeland Power Distribution Inc.",
        key: "lkp",
    },
    {
        id: "588f1795684b314d0168967c575413de",
        name: "Lethbridge",
        key: "lbr",
    },
    {
        id: "588f51ee4ccd410e014cf69d65e30348",
        name: "Liberty",
        key: "liberty",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9df580d6e40",
        name: "London Hydro Inc.",
        key: "loh",
    },
    {
        id: "58464f005ce23671015d14131a240e50",
        name: "Louisville Gas & Electric Co.",
        key: "lge",
        bill: 1,
    },
    {
        id: "20224b7a1d704dc2bc1066ac8707b9fb",
        name: "METED",
        key: "meted",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4b0cf546014b43a643cb200e",
        name: "Madison Gas and Electric",
        key: "mge",
    },
    {
        id: "08f4a8b55a60448ba83afa37bf36e7da",
        name: "Manitoba Hydro",
        key: "manhy",
    },
    {
        id: "58464f0063e099c30163f61fc03511c6",
        name: "Memphis Light Gas & Water",
        key: "mlgw",
    },
    {
        id: "588f51ee4b0cf546014b439e1ca31f76",
        name: "Michigan Gas Utilities",
        key: "mgu",
    },
    {
        id: "588f51ee4b0cf546014b4398885e1f1f",
        name: "MidAmerican Energy",
        key: "midamerican",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9e0f12a6e42",
        name: "Midland Power Utility Corporation",
        key: "midpu",
    },
    {
        id: "588f1795677458ca0167a9e1c67f6e44",
        name: "Milton Hydro",
        key: "mil",
    },
    {
        id: "58464f00500095e0015024b88c4403c2",
        name: "Minnesota Energy Resources",
        key: "mnenergy",
    },
    {
        id: "58464f0063e099c30163f044d8480b17",
        name: "Missouri Gas Energy",
        key: "mge",
    },
    {
        id: "588f51ee4778cd6c014778f380f20003",
        name: "NICOR",
        logo: true,
        key: "nicor",
        bill: 1,
    },
    {
        id: "037f81f740754be2abf50f23fe950fe2",
        name: "NYSEG",
        key: "nyseg",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4a49fc5a014a5fbd118b017c",
        name: "National Fuel Gas Company",
        key: "nfg",
        bill: 1,
    },
    {
        id: "588f51ee4ccd410e014cf6112a370345",
        name: "National Grid",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "369e8144309a4428bf89e1be12c81811",
        name: "National Grid (Formerly Niagara Mohawk Power Corporation)",
        key: "nimo",
    },
    {
        id: "e29d3b3f178b44e681a9dbb3dd0a8bec",
        name: "National Grid (Massachusetts Electric)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "4365d3a3a82f46a1b79e788c3bc8ed48",
        name: "National Grid (Nantucket Electric)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "005071d19c5b46a0ac8aa99cc1b485bc",
        name: "National Grid (Narragansett Electric)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "588f51ee493de9e401494e21525b0075",
        name: "National Grid Long Island (Formerly Keyspan)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "588f51ee4a49fc5a014a5fbebf8d017d",
        name: "National Grid New York (Formerly Keyspan)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "588f51ee4a49fc5a014a5fbf9527017e",
        name: "National Grid Upstate New York (Niagara Mohawk)",
        key: "ngrid",
        logo: true,
        bill: 2,
    },
    {
        id: "588f51ee4552d6a6014556f8be38000d",
        name: "New Hampshire Electric Cooperative",
        key: "nhec",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4a49fc5a014a5fb9ea230179",
        name: "New Jersey Natural Gas",
        key: "njng",
        logo: true,
    },
    {
        id: "58464f0063e099c30163f5fc2da51188",
        name: "New Mexico Gas Company",
        key: "nmgs",
    },
    {
        id: "588f1795677458ca0167a9e327236e47",
        name: "Newmarket-Tay Power Distribution Ltd.",
        key: "nt",
    },
    {
        id: "588f1795677458ca0167a9e5c3876e4f",
        name: "Niagara Peninsula Energy Inc.",
        key: "npe",
    },
    {
        id: "588f1795677458ca0167a9e489636e4d",
        name: "Niagara-on-the-Lake Hydro Inc.",
        key: "nolh",
    },
    {
        id: "588f1795677458ca0167a9e736876e5c",
        name: "North Bay Hydro",
        key: "nbh",
    },
    {
        id: "588f51ee4778cd6c014778f322410002",
        name: "North Shore Gas",
        key: "nsg",
        logo: true,
        bill: 1,
    },
    {
        id: "13eccc2f6a7e4e4a9e06600742ee9280",
        name: "NorthWestern Energy",
        key: "nwe",
    },
    {
        id: "588f51ee4778cd6c014778f3cf000004",
        name: "Northern Indiana Public Service company",
        key: "nipsco",
        bill: 1,
    },
    {
        id: "58464f00622be82201623f310fd7023c",
        name: "Northern Maine",
        key: "nmg",
    },
    {
        id: "588f1795677458ca0167a9e8684f6e63",
        name: "Northern Ontario Wires Inc.",
        key: "now",
    },
    {
        id: "396c10c4ad2a4a2b83696f26fd80b36d",
        name: "OHIO EDISON",
        key: "oe",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9e95e116e64",
        name: "Oakville Hydro Electricity Distribution Inc.",
        key: "oak",
    },
    {
        id: "588f51ee4b0cf546014b439783da1f14",
        name: "Ohio Valley Gas",
        key: "ovg",
    },
    {
        id: "58464f0063e099c30163f0276cda0af8",
        name: "Oklahoma Natural Gas",
        key: "ong",
    },
    {
        id: "588f51ee4552d6a6014556faad170013",
        name: "Oncor Electric Delivery Co.",
        key: "oncor",
        logo: true,
    },
    {
        id: "8af6be2cfdd548dcba5a74fef92d952e",
        name: "Orange and Rockland",
        key: "onr",
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9ea83986e67",
        name: "Orangeville Hydro",
        key: "orh",
    },
    {
        id: "588f1795684b314d01689663403213d6",
        name: "Orillia Power Distribution Corp.",
        key: "op",
    },
    {
        id: "588f1795684b314d01689664467d13d7",
        name: "Oshawa PUC Networks Inc.",
        key: "os",
    },
    {
        id: "588f1795677458ca0167a9ee70526e78",
        name: "Ottawa River Power Corporation",
        key: "orpc",
    },
    {
        id: "8b2000c56fff43ba8d33ad2a4faf946f",
        name: "PECO",
        key: "peco",
        logo: true,
        bill: 3,
    },
    {
        id: "c2827423692c48f097f3270dffbb8682",
        name: "PENELEC",
        key: "penelec",
        logo: true,
        bill: 1,
    },
    {
        id: "76ea4004153f41cc9316303dc585d283",
        name: "PEPCO",
        key: "pepco",
        logo: true,
        bill: 3,
    },
    {
        id: "3629f629cc8b454db5b395454418db50",
        name: "PPL",
        key: "ppl",
        logo: true,
        bill: 1,
    },
    {
        id: "a007cf3b78d146c6ae9143f6dfc13ca3",
        name: "PSEG",
        key: "pseg",
        logo: true,
        bill: 1,
    },
    {
        id: "0300d88b35a0432d93b565b830cfa2bb",
        name: "PSEG",
        key: "pseg",
        logo: true,
        bill: 1,
    },
    {
        id: "58464f00568ac6560156dba6979015eb",
        name: "PSEG Long Island",
        key: "pseg",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9f0cdde6e83",
        name: "PUC Distribution Inc.",
        key: "puc",
    },
    {
        id: "588f51ee4c379050014c8bac84f12036",
        name: "Pacific Gas & Electric",
        key: "pge",
    },
    {
        id: "588f17956b71d134016b76d0ae0504f4",
        name: "Penelec (Waverly)",
        key: "penelec",
        logo: true,
        bill: 1,
    },
    {
        id: "fd3304ed66194890be0cfbeb979dfdad",
        name: "PennPower",
        key: "pp",
        logo: true,
    },
    {
        id: "588f51ee4778cd6c014778f2aa650001",
        name: "Peoples Gas",
        key: "peoples",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4ccd410e014ccde190aa0019",
        name: "Peoples Natural Gas",
        key: "peoples",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9efd4916e7f",
        name: "Peterborough Distribution Inc.",
        key: "ptd",
    },
    {
        id: "58464f00634c168c0163939e4ec01065",
        name: "Philadelphia Gas Works",
        key: "pgw",
        bill: 3,
    },
    {
        id: "58464f0063e099c30163f021ae4e0ae9",
        name: "Piedmont",
        key: "pied",
    },
    {
        id: "588f1795684b314d0168967cf9b513df",
        name: "Ponoka",
        key: "po",
    },
    {
        id: "68776f9103fb4afcaac1440609fccacc",
        name: "Potomac Edison/ Allegany Power",
        key: "pe",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d0168966526e413d8",
        name: "PowerStream Inc",
        key: "hv",
    },
    {
        id: "58464f0063e099c30163f61de90f11b3",
        name: "Public Service of North Carolina",
        key: "psnc",
    },
    {
        id: "3f8c80de88cc4dd38708d990768f4e1e",
        name: "RG&E",
        key: "rge",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d0168967df37613e0",
        name: "Red Deer",
        key: "rd",
    },
    {
        id: "588f1795677458ca0167a9f1ab9d6e86",
        name: "Renfrew Hydro",
        key: "rfh",
    },
    {
        id: "588f1795677458ca0167a9f2f4a66e8c",
        name: "Rideau St. Lawrence Distribution Inc.",
        key: "rids",
    },
    {
        id: "588f17956e81bdea016e938d9ea40a2e",
        name: "Rio Tinto Alcan",
        key: "rta",
    },
    {
        id: "9721c37d36ce41938e2bfa27735d80a6",
        name: "Rockland Electric Co",
        key: "reco",
    },
    {
        id: "58464f0063e099c30163f0233e0f0aee",
        name: "SCANA - SCE & G",
        key: "sceg",
    },
    {
        id: "588f51ee4c379050014c8bb4c171203d",
        name: "SEMCO Energy",
        key: "semco",
    },
    {
        id: "58464f00510502e001512eb79d9703e5",
        name: "San Diego Gas and Electric",
        key: "sdge",
        bill: 1,
    },
    {
        id: "588f1795677458ca0167ae163ca36fb9",
        name: "Saskatoon Light&Power",
        key: "spl",
    },
    {
        id: "9869fc50c7dd47479d2c73fb72820869",
        name: "Sentra ",
        key: "sentra",
    },
    {
        id: "588f51ee4d4d53fe014d4dd5858b0055",
        name: "Sharyland Utilities",
        key: "sharyland",
    },
    {
        id: "58464f0063e099c30163f6115e431196",
        name: "Shenandoah Gas",
        key: "sgv",
    },
    {
        id: "588f1795677458ca0167a9f456316e90",
        name: "Sioux Lookout Hydro Electric Commission",
        key: "silh",
    },
    {
        id: "58464f00510502e001512eb88a4f03e7",
        name: "SoCal Gas",
        key: "socal",
        bill: 1,
    },
    {
        id: "588f51ee4a49fc5a014a5fbad6f5017a",
        name: "South Jersey Gas",
        key: "sjg",
        logo: true,
    },
    {
        id: "58464f0063e099c30163f61694c111a5",
        name: "Southern Connecticut Gas",
        key: "scg",
        bill: 1,
    },
    {
        id: "588f51ee4c379050014caa2b46d42298",
        name: "Southern Maryland Electric Co-Op",
        key: "smeco",
        logo: true,
    },
    {
        id: "58464f0063e099c30163f113217d10f6",
        name: "Southwest Gas",
        key: "sg",
    },
    {
        id: "142640f9cc64457186a1494800057d5f",
        name: "Spire Energy",
        key: "spire",
        bill: 1,
    },
    {
        id: "588f1795684b314d01689642ca5a13b4",
        name: "St. Catharines Hydro Utility Services Inc",
        key: "chus",
    },
    {
        id: "588f1795684b314d01689665b27c13d9",
        name: "St. Thomas Energy Inc.",
        key: "st",
    },
    {
        id: "58464f0063e099c30163f0bc715710e9",
        name: "Summit Natural Gas",
        key: "sng",
    },
    {
        id: "58464f004dd10fe7014de9a63e870409",
        name: "TECO (Peoples Gas)",
        key: "teco",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d01689666562313da",
        name: "Tay Hydro Electric Distribution Co. Inc.",
        key: "tay",
    },
    {
        id: "588f51ee45be78be0145c86d6a631ef7",
        name: "Texas-New Mexico Power",
        key: "tnmp",
    },
    {
        id: "588f1795677458ca0167a9f56a066e97",
        name: "Thunder Bay Hydro",
        key: "tbh",
    },
    {
        id: "588f1795677458ca0167a9f654b66e99",
        name: "Tillsonburg Hydro Inc.",
        key: "tilh",
    },
    {
        id: "83b4f9bfa7d74ef08c17ce8ac76dd06f",
        name: "Toledo Edison",
        key: "te",
        logo: true,
        bill: 1,
    },
    {
        id: "588f17956733a11e016742d414a502ca",
        name: "Toronto Hydro Electric System",
        key: "thes",
    },
    {
        id: "58464f005c1345c5015c26a73bca0422",
        name: "UGI Central (formerly UGI Central Penn Gas, Inc.)",
        key: "ugi",
        logo: true,
        bill: 1,
    },
    {
        id: "588f51ee4a49fc5a014a5fbc0bae017b",
        name: "UGI North (formerly UGI Penn Natural Gas, Inc.)",
        key: "ugi",
        logo: true,
        bill: 1,
    },
    {
        id: "93eec62217064867bc4f496ead21a64e",
        name: "UGI South (formerly UGI Utilities, Inc.)",
        key: "ugi",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167aa04d31d6ec9",
        name: "Union Gas Northeast",
        key: "ungne",
    },
    {
        id: "588f17956d73ef42016d7450a8af0060",
        name: "Union Gas Northwest",
        key: "ungnw",
    },
    {
        id: "588f17956d73ef42016d7450c0150061",
        name: "Union Gas South",
        key: "ungs",
    },
    {
        id: "58464f0063d8a29d0163dc5d5024014d",
        name: "Union Light Heat & Power",
        key: "ulhp",
    },
    {
        id: "58464f0063e099c30163f609e923118e",
        name: "United Cities Gas",
        key: "ucg",
    },
    {
        id: "a1f3f83cf57b47f5b1a26a645a473fc9",
        name: "United Illuminating",
        key: "ui",
        logo: true,
        bill: 1,
    },
    {
        id: "213a52ccdc2d4312814caf18064de11d",
        name: "Unitil",
        key: "unitil",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795684b314d01689668381513db",
        name: "Utilities Kingston",
        key: "uk",
    },
    {
        id: "588f51ee4b0cf546014b439355021ee5",
        name: "Vectren North",
        key: "cpe",
        logo: true,
    },
    {
        id: "588f51ee4c379050014c8ba641bf2035",
        name: "Vectren Ohio",
        key: "cpe",
        logo: true,
    },
    {
        id: "588f51ee4b0cf546014b4393b8e31eee",
        name: "Vectren South",
        key: "cpe",
        logo: true,
    },
    {
        id: "588f1795677458ca0167a9f77a306e9a",
        name: "Veridian Connections",
        key: "verd",
    },
    {
        id: "58464f0063e099c30163f01ebf990ae4",
        name: "Virginia Natural Gas",
        key: "vng",
    },
    {
        id: "588f1795677458ca0167a9f8eb5e6e9e",
        name: "Wasaga Distribution Inc.",
        key: "wasg",
    },
    {
        id: "588f51ee4ccd410e014ccda62cff0018",
        name: "Washington Gas & Light",
        key: "wgl",
        logo: true,
    },
    {
        id: "588f1795677458ca0167a9f9f4866ea3",
        name: "Waterloo North Hydro",
        key: "waterloo",
    },
    {
        id: "58464f004dd10fe7014dee2d4f050424",
        name: "Waterville Gas and Oil Company",
        key: "wgo",
    },
    {
        id: "588f51ee4b0cf546014b43a842402015",
        name: "We-Energies",
        key: "we",
    },
    {
        id: "588f1795677458ca0167a9fca0b46eaa",
        name: "Welland Hydro-Electric System Corp.",
        key: "welh",
    },
    {
        id: "588f1795677458ca0167a9fb61506ea7",
        name: "Wellington North Power Inc.",
        key: "weln",
    },
    {
        id: "588f1795677458ca0167a9fde6186eb9",
        name: "West Coast Huron Energy",
        key: "wche",
    },
    {
        id: "9f246a0bfd2440b8bb7ada80f570f02d",
        name: "West Penn Power",
        key: "wpp",
        logo: true,
        bill: 1,
    },
    {
        id: "588f1795677458ca0167a9fed6bb6ebb",
        name: "Westario Power",
        key: "westario",
    },
    {
        id: "588f1795677458ca0167a9ffe3cb6ec2",
        name: "Whitby Hydro Electric Corporation",
        key: "whitby",
    },
    {
        id: "588f51ee4b0cf546014b43a7566a200f",
        name: "Wisconsin Public Service",
        key: "wps",
    },
    {
        id: "58464f0063e099c30163f113c3b710f7",
        name: "Xcel Energy",
        key: "xcel",
    },
    {
        id: "58464f0063e099c30163f6157201119d",
        name: "Yankee Gas",
        key: "yankee",
    },
];

export default utilitiesList;
