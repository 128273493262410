/* eslint-disable camelcase */
import {
    useCallback,
    useState,
    type ReactNode,
    type MouseEventHandler,
} from "react";
import Grid from "@components/fondation/Grid/Grid";
import { FormikContext, FormikHelpers, useFormik } from "formik";

import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@components/fondation/Box/Box";
import { FormControl } from "@mui/material";
import { BaseSchema } from "yup";
import Typography from "@components/fondation/Typography/Typography";
import RadioGroup from "@components/elements/Radio/RadioGroup";
import RadioCard from "@components/elements/Radio/RadioCard";
import FormTextField from "@components/modules/forms/common/FormTextField";
import { errorMessage, hasError } from "@components/modules/forms/common/utils";
import AddressAutocomplete from "../components/AddressAutocomplete";
import {
    parseAutocomplete,
    defaultInitialValues,
    addressValidationSchema,
    ownershipOptions,
} from "./utils";
import { AddressFormData } from "./types";

type PlaceResult = google.maps.places.PlaceResult;

const validationSchema: BaseSchema = addressValidationSchema;

export interface AddressFormProps {
    initialValues?: Partial<AddressFormData>;
    loading?: boolean;
    children?: ReactNode;
    cancelable?: boolean;
    submittable?: boolean;
    onSubmit: (
        form: AddressFormData,
        helpers: FormikHelpers<AddressFormData>,
        isGoogleAddress?: boolean,
    ) => void;
    onCancel?: MouseEventHandler;
}

function EditAddressForm({
    children,
    initialValues,
    loading,
    cancelable = true,
    submittable = true,
    onSubmit,
    onCancel,
}: AddressFormProps): JSX.Element {
    const [isGoogleAddress, setGoogleAddress] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: { ...defaultInitialValues, ...initialValues },
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema,
        onSubmit: (form, helpers) =>
            onSubmit(validationSchema.cast(form), helpers, isGoogleAddress),
    });

    const setAddressValues = useCallback(
        (result: PlaceResult | null | string) => {
            if (result == null) {
                formik.setFieldValue("address", "");
                setGoogleAddress(false);
                return;
            }
            if (typeof result === "string") {
                formik.setFieldValue("address", result as string);
                setGoogleAddress(false);
                return;
            }
            setGoogleAddress(true);
            const { place_id, address_components } = result;
            if (!address_components || !place_id) {
                return;
            }
            const newFormData = parseAutocomplete(place_id, address_components);

            formik.resetForm({ values: { ...formik.values, ...newFormData } });
            formik.validateForm();
        },
        [formik.values],
    );

    const disabled = loading || formik.isSubmitting || !submittable;
    const withLoader = loading && !submittable;

    return (
        <FormikContext.Provider value={formik}>
            <FormControl component="form" onSubmit={formik.handleSubmit}>
                <Box mb={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>

                        <Grid item xs={12}>
                            <AddressAutocomplete
                                defaultValue={formik.values.address}
                                name="address"
                                inputLabel="Street Address"
                                error={hasError(formik, "address")}
                                helperText={errorMessage(formik, "address")}
                                onBlur={formik.handleBlur}
                                onChange={setAddressValues}
                            />
                            {hasError(formik, "address") && (
                                <FormHelperText error>
                                    {errorMessage(formik, "address")}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <FormTextField
                                fullWidth
                                name="apartment"
                                label="Apt/Suite"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormTextField fullWidth name="city" label="City" />
                        </Grid>

                        <Grid item xs={6}>
                            <FormTextField
                                fullWidth
                                name="state"
                                label="State"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormTextField
                                fullWidth
                                name="postalCode"
                                label="Zip Code"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={6}>
                                <Typography variant="h4" mb={2}>
                                    Do you own this property?
                                </Typography>
                                <RadioGroup
                                    name="owner"
                                    value={
                                        formik.values.owner == null
                                            ? undefined
                                            : formik.values.owner === true
                                            ? "own"
                                            : "rent"
                                    }
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "owner",
                                            e.target.value === "own",
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                >
                                    <Grid container spacing={3}>
                                        {ownershipOptions.map(
                                            ({ name, label }) => (
                                                <Grid item xs={6} key={label}>
                                                    <RadioCard
                                                        value={name}
                                                        fullWidth
                                                    >
                                                        {label}
                                                    </RadioCard>
                                                </Grid>
                                            ),
                                        )}
                                    </Grid>
                                    {hasError(formik, "owner") && (
                                        <FormHelperText error>
                                            {errorMessage(formik, "owner")}
                                        </FormHelperText>
                                    )}
                                </RadioGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Grid
                        container
                        spacing={3}
                        flexDirection={{ xs: "column-reverse", md: "initial" }}
                    >
                        <Grid item xs={12} md={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                size="large"
                                type="reset"
                                disabled={disabled || !cancelable}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={disabled}
                                endIcon={withLoader && <Loader />}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
        </FormikContext.Provider>
    );
}

export default EditAddressForm;
