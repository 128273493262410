import Grid from "@mui/material/Grid";
import Label, { LabelProps } from "./Label";

export default function MetricsGrid({
    cellData = [],
}: {
    cellData: LabelProps[];
}): JSX.Element {
    return (
        <Grid container spacing={4}>
            {cellData.map((props) => (
                <Grid item xs={6} md={4} key={props.label}>
                    <Label {...props} />
                </Grid>
            ))}
        </Grid>
    );
}
