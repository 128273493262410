import * as React from "react";

import { r } from "@theme/utils";
import styled from "@mui/material/styles/styled";
import Stack from "@components/fondation/Stack/Stack";
import Typography from "@components/fondation/Typography/Typography";
import { alpha } from "@mui/system/colorManipulator";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import Loader from "@components/elements/Loader/Loader";

export interface MenuItemProps extends ButtonBaseProps {
    primaryText?: React.ReactNode;
    secondaryText?: React.ReactNode;
    icon?: React.ReactNode;
    active?: boolean;
    id: string;
    loading?: boolean;
}

const Container = styled(ButtonBase)<{ active?: boolean }>(
    ({ theme, active }) => ({
        display: "flex",
        flex: "1 1 100%",
        justifyContent: "space-between",
        alignItems: "center",
        width: "fill-available",
        textAlign: "start",
        padding: r(13),
        margin: r(4),
        borderRadius: theme.shape.borderRadius,
        borderLeft: "2px solid transparent",
        borderColor: active ? theme.palette.green.main : "transparent",
        backgroundColor: active
            ? alpha(theme.palette.green.main, 0.15)
            : "transparent",
        cursor: "pointer",
        minHeight: r(48),
        ".MuiTouchRipple-root": {
            color: alpha(theme.palette.green.main, 0.7),
        },
        "&:hover": {
            borderColor: theme.palette.green.main,
            backgroundColor: alpha(theme.palette.green.main, 0.1),
        },
    }),
);

const ItemIcon = styled("div")(({ theme }) => ({
    display: "flex",
    color: theme.palette.green.main,
    marginRight: r(13),
    ".MuiSvgIcon-root ": {
        fontSize: r(24),
    },
}));

const MenuItem: React.FC<MenuItemProps> = React.memo(
    ({ icon, primaryText, secondaryText, loading, ...rest }) => (
        <Container {...rest}>
            <Stack
                direction="row"
                alignItems="center"
                maxWidth="100%"
                overflow="hidden"
            >
                <ItemIcon>{icon}</ItemIcon>
                <Stack flex="1 1 100%" overflow="hidden">
                    <Typography variant="h5" overflow="hidden">
                        {primaryText}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="grey.800"
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {secondaryText}
                    </Typography>
                </Stack>
            </Stack>

            {loading ? <Loader /> : <KeyboardArrowRightOutlinedIcon />}
        </Container>
    ),
);

export default MenuItem;
