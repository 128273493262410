import * as React from "react";
import { useNavigate } from "react-router-dom";

import TermsOfService from "@components/prototypes/TermAndPolicy/TermsOfService";
import SubPageLayout from "@pages/account/AccountSectionLayout";
import DashboardLayout from "@components/modules/layouts/DashboardLayout";

const TermsOfServicePage: React.FC = React.memo(() => {
    const navigate = useNavigate();

    const handleBack = () => navigate(-1);

    return (
        <DashboardLayout>
            <SubPageLayout
                label="Legal"
                onBack={handleBack}
                maxWidth={570}
                m="auto"
            >
                <TermsOfService />
            </SubPageLayout>
        </DashboardLayout>
    );
});

export default TermsOfServicePage;
