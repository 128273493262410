import { useMemo } from "react";

import { type ProductStore } from "@store/product/product.store";
import { type RateOffer } from "@store/product/RateOffer";

import { isRenewable } from "./plan.selectors";

export function hasBetterRateAvailable(
    product: ProductStore,
    offer: RateOffer,
): boolean {
    if (!offer.quote?.rate) {
        return false;
    }

    if (!product.service?.userRate) {
        return false;
    }

    const { userRate } = product.service;
    const { rate: recommendedRate } = offer.quote;

    const result = recommendedRate > 0 && recommendedRate <= userRate;

    if (result !== offer.isSwitchRecommended) {
        console.warn(
            JSON.stringify({
                message: "Switch recommended without better rate available",
                renewable: isRenewable(product),
                recommendedRate,
                userRate,
            }),
        );
    }

    return result;
}

export function useHasBetterRateAvailable(
    product: ProductStore,
    offer: RateOffer,
): ReturnType<typeof hasBetterRateAvailable> {
    return useMemo(
        () => hasBetterRateAvailable(product, offer),
        [product.service?.userRate, offer.quote?.rate],
    );
}
