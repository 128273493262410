/* eslint-disable no-useless-escape */
import { useMemo } from "react";

import * as React from "react";
import zip from "lodash/zip";
import styled from "@emotion/styled";
import { LinkOption } from "./types";

interface MarkdownProps {
    children?: string;
    renderLink?: (option: LinkOption) => React.ReactNode;
}

const Container = styled("span")({
    a: {
        wordBreak: "break-all",
    },
});

const pathRegex =
    /(^\/[\w\d]+)(\/[\w\d]+)*((\?[\w\_]*)(\=[\w\d]*)?((\&[\w\_]*)(\=[\w\d]*))*)?$/;

const markdownLinkRegex =
    /\[[[\w\d\s/!@#$%&'*/=?^_`{|}~:.\+\-]+\]\([\w\d\s/!@#$%&'*/=?^_`{|}~:.\+\-]+\)/g;

const parseLink = (text: string): LinkOption => {
    const [content, link] = text.substring(1, text.length - 1).split("](");
    return { content, link, type: link.match(pathRegex) ? "path" : "url" };
};

const defaultLinkRenderer = (link: LinkOption) => (
    <a href={link.link}>{link.content}</a>
);

const Markdown: React.FC<MarkdownProps> = React.memo(
    ({ children = "", renderLink = defaultLinkRenderer }) => {
        const links = useMemo(
            () => Array.from(children.matchAll(markdownLinkRegex)),
            [children],
        );
        const otherContent = useMemo(
            () => children.split(markdownLinkRegex),
            [children],
        );

        if (links.length + 1 !== otherContent.length) {
            return <>{children}</>;
        }
        return (
            <Container>
                {zip(
                    otherContent,
                    links.map((item) => renderLink(parseLink(item[0]))),
                )}
            </Container>
        );
    },
);

export default Markdown;
