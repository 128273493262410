import type { ReactNode } from "react";
import Typography from "@components/fondation/Typography/Typography";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Box from "@components/fondation/Box/Box";
import TicketPerforation from "./TicketPerforation";

export interface HeaderProps {
    label?: ReactNode;
    title?: ReactNode;
    children?: ReactNode;
}

export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    padding: r(16),
}));

export function TicketStub({
    label,
    title,
    children,
}: HeaderProps): JSX.Element {
    return (
        <Container>
            <Typography
                variant="h3"
                color="inherit"
                fontSize={r(20)}
                hidden={label == null}
                textAlign="center"
            >
                {label}
                <TicketPerforation />
            </Typography>

            <Typography variant="h4" color="inherit" mb={1}>
                {title}
            </Typography>

            <Typography variant="body2" color="inherit">
                {children}
            </Typography>
        </Container>
    );
}

export default TicketStub;
