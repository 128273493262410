import { User } from "@model/types/user";

export const renderFullAddress = (user: User): string | null => {
    const { place, apartment } = user;
    if (!place) {
        return null;
    }
    return `${place.address}, ${apartment ? `Apt ${apartment}, ` : ""}${
        place.city
    }, ${place.state} ${place.postalCode}`;
};
