import type { ReactNode } from "react";
import Grid from "@components/fondation/Grid/Grid";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import Stack from "@components/fondation/Stack/Stack";

export function DesktopRateContractContainer({
    firstSocket,
    secondSocket,
}: {
    firstSocket: ReactNode;
    secondSocket: ReactNode;
}): JSX.Element {
    return (
        <Grid
            container
            spacing={{
                xs: 4,
                lg: 0,
            }}
        >
            <Grid item lg={4} md={6} xs={12}>
                {firstSocket}
            </Grid>

            <Grid item xs={1}>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                >
                    <EastOutlinedIcon />
                    <EastOutlinedIcon />
                </Stack>
            </Grid>

            <Grid item lg={7} md={6} xs={12}>
                {secondSocket}
            </Grid>
        </Grid>
    );
}

export default DesktopRateContractContainer;
