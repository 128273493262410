/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";

import Box from "@components/fondation/Box/Box";
import SwitchTabs from "@components/elements/Tabs/SwitchTabs";
import Tab from "@components/elements/Tabs/Tab";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import BillsStore from "@store/bills.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import BillUploadPage from "./UploadBillPage";
import LinkManuallyPage from "./LinkManuallyPage";

const tabs = ["Fill Out Form", "Upload Bill"];

const UtilityDetailsPage: React.FC = observer(() => {
    const billsStore = useInstance(BillsStore);

    const [page, setPage] = useState(0);

    const handleChange = (_: any, newValue: number) => {
        UseRudderStack.INSTANCE.trackEvent("upload_bill-toggle-bill_upload", {
            values: newValue,
        });
        setPage(newValue);
    };

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "upload_bill", {
            page_name: "upload_bill",
            name: "upload_bill",
            url: window.location.href,
        });
        billsStore.resetFolder();
    }, []);

    return (
        <ModalBox overflow="initial" sx={{ minHeight: "100%", flexShrink: 0 }}>
            <Box display="flex" justifyContent="center" pb={4.5}>
                <SwitchTabs value={page} onChange={handleChange}>
                    {tabs.map((label) => (
                        <Tab label={label} key={label} />
                    ))}
                </SwitchTabs>
            </Box>

            <Box display="flex" flexGrow={1}>
                {page === 0 && <LinkManuallyPage />}
                {page === 1 && <BillUploadPage />}
            </Box>
        </ModalBox>
    );
});

export default UtilityDetailsPage;
