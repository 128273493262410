import * as React from "react";
import { observer } from "mobx-react-lite";
import ImageViewer from "@components/elements/ImageViewer/ImageViewer";
import AppProvider from "@root/AppProvider";
import ImageViewerStore from "@store/image-viewer.store";
import { useInstance } from "react-ioc";

AppProvider.register(ImageViewerStore);

export const AppImageViewer: React.FC = observer(() => {
    const viewerContext = useInstance(ImageViewerStore);

    const {
        activeImgSrc,
        isOpened,
        close,
        next,
        prev,
        showNextButton,
        showPrevButton,
    } = viewerContext;

    if (!isOpened) {
        return null;
    }

    return (
        <ImageViewer
            open
            src={activeImgSrc}
            showNextButton={showNextButton}
            showPrevButton={showPrevButton}
            onClose={close}
            onNext={next}
            onPrev={prev}
        />
    );
});
