import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { DashboardPage } from "@pages/dashboard/Dashboard";
import SessionModal from "@pages/auth/AuthModal";
import WelcomePage from "@pages/auth/SignUp/WelcomePage";
import SelectUtilityPage from "@pages/auth/SignUp/SelectUtilityPage";
import UnsupportedPage from "@pages/auth/SignUp/Unsupported";
import ContactUsPage from "@pages/auth/SignUp/ContactUsPage";
import LoginPage from "@pages/auth/SignIn/LoginPage";
import { GetStartedPage } from "@pages/auth/SignUp/GetStartedPage";
import SuccessPage from "@pages/auth/SignUp/SuccessPage";
import MagicLinkPage from "@pages/auth/SignUp/MagicLinkPage";
import EnrollmentOutlet from "@pages/auth/SignUp/EnrollmentOutlet";
import SignUpPage from "@pages/auth/SignUp/about-you/SignUpPage";
import AddressPage from "@pages/auth/SignUp/about-you/AddressPage";
import BusinessPage from "@pages/auth/SignUp/about-you/BusinessPage";
import RenewablePreferencesPage from "@pages/auth/SignUp/preferences/Renewable";
import IntroductionPage from "@pages/auth/SignUp/provider/IntroductionPage";
import AccountForkPage from "@pages/auth/SignUp/provider/AccountForkPage";
import ConnectUtilityPage from "@pages/auth/SignUp/provider/ConnectUtilityPage";
import ConnectionFailedPage from "@pages/auth/SignUp/provider/ConnectionFailedPage";
import UtilityDetailsPage from "@pages/auth/SignUp/provider/UtilityDetailsPage";
import RatePreferencesPage from "@pages/auth/SignUp/preferences/Rate";
import AccountOutlet from "@pages/account/AccountOutlet";
import { CheckoutPage } from "@pages/checkout/Checkout";
import PrivateOutlet from "@components/modules/security/PrivateOutlet";
import CheckoutMessagePage from "@pages/checkout/Success";
import AccountSettingsPage from "@pages/account/Settings";
import AccountAddressPage from "@pages/account/Address";
import AccountUtilityPage from "@pages/account/UtilityAccount";
import AccountSupportPage from "@pages/account/Support";
import AccountFaqsPage from "@pages/account/Faqs";
import TermsOfServicePage from "@pages/account/Legal/TermsOfService";
import PrivacyPolicyPage from "@pages/account/Legal/PrivacyPolicy";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import LegalPage from "@pages/account/Legal";
import EnrollmentLayout from "@components/modules/layouts/EnrollmentLayout";
import VerificationPage from "@pages/auth/SignIn/VerificationPage";
import AutopilotPage from "@pages/auth/SignUp/preferences/Autopilot";
import VerifyingPage from "@pages/auth/SignUp/VerifyingPage";
import MoveAddressPage from "@pages/account/MoveAddress";
import useOpenReplay from "@components/prototypes/hooks/useOpenReplay";
import useFacebookPixel from "@components/prototypes/hooks/useFacebookPixel";
import UnsupportedConfirmationPage from "@pages/auth/SignUp/UnsupportedConfirmation";
import SessionOutlet from "@components/modules/security/SessionOutlet";
import UtilityOutlet from "@components/prototypes/utilities/UtilityOutlet";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
// import QueryParamLayout from "./routes/QueryParamLayout"

const SentryRoutes = withSentryReactRouterV6Routing(Routes);
const RootRouter: React.FC = observer(() => {
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

    useEffect(() => {
        // initialize the rudderstack
        UseRudderStack.INSTANCE.initRS();
        if (!document.getElementById("share-a-sale-master-pixel")) {
            const script = document.createElement("script");
            script.id = "share-a-sale-master-pixel";
            script.src = "https://www.dwin1.com/61663.js";
            script.type = "text/javascript";
            script.defer = true;
        }
    }, []);

    if (process.env.REACT_APP_OPEN_REPLAY_ID) {
        useOpenReplay();
    }
    useFacebookPixel();
    return (
        <SentryRoutes>
            {/* <Route element={<QueryParamLayout />}> */}
            <Route path="/" element={<SessionOutlet />}>
                <Route
                    path="/"
                    element={
                        <EnrollmentLayout>
                            <Outlet />
                        </EnrollmentLayout>
                    }
                >
                    <Route
                        index
                        element={<Navigate to="/dashboard" replace />}
                    />
                    <Route element={<SessionModal />}>
                        <Route path="magiclink" element={<MagicLinkPage />} />

                        <Route path="area">
                            <Route index element={<WelcomePage />} />
                            <Route
                                path="unsupported"
                                element={<UnsupportedPage />}
                            />
                            <Route
                                path="unsupported/confirmation"
                                element={<UnsupportedConfirmationPage />}
                            />
                        </Route>

                        <Route
                            path="utilities"
                            element={<SelectUtilityPage />}
                        />

                        <Route path="contact-us" element={<ContactUsPage />} />

                        <Route path="login" element={<LoginPage />} />

                        <Route
                            path="verification"
                            element={<VerificationPage />}
                        />

                        <Route path="enrollment" element={<UtilityOutlet />}>
                            <Route
                                index
                                element={<Navigate to="get-started" replace />}
                            />

                            <Route path="success" element={<SuccessPage />} />

                            <Route
                                path="verifying"
                                element={<VerifyingPage />}
                            />

                            <Route
                                path="get-started"
                                element={<GetStartedPage />}
                            />

                            <Route element={<EnrollmentOutlet />}>
                                <Route path="about">
                                    <Route
                                        index
                                        element={
                                            <Navigate to="register" replace />
                                        }
                                    />
                                    <Route
                                        path="register"
                                        element={<SignUpPage />}
                                    />
                                    <Route
                                        path="address"
                                        element={<AddressPage />}
                                    />
                                    <Route
                                        path="business"
                                        element={<BusinessPage />}
                                    />
                                </Route>

                                <Route path="preferences">
                                    <Route
                                        index
                                        element={
                                            <Navigate to="autopilot" replace />
                                        }
                                    />
                                    <Route
                                        path="renewable"
                                        element={<RenewablePreferencesPage />}
                                    />
                                    <Route
                                        path="autopilot"
                                        element={<AutopilotPage />}
                                    />
                                    <Route
                                        path="rate"
                                        element={<RatePreferencesPage />}
                                    />
                                </Route>

                                <Route path="provider">
                                    <Route
                                        index
                                        element={<IntroductionPage />}
                                    />
                                    <Route
                                        path="account-fork"
                                        element={<AccountForkPage />}
                                    />
                                    <Route
                                        path="connect"
                                        element={<ConnectUtilityPage />}
                                    />
                                    <Route
                                        path="failed"
                                        element={<ConnectionFailedPage />}
                                    />
                                    <Route
                                        path="link"
                                        element={<UtilityDetailsPage />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route element={<PrivateOutlet />}>
                    <Route element={<UtilityOutlet />}>
                        <Route path="dashboard" element={<DashboardPage />} />

                        <Route path="switch" element={<CheckoutPage />} />
                        <Route
                            path="switch/message"
                            element={<CheckoutMessagePage />}
                        />

                        <Route path="account" element={<AccountOutlet />}>
                            {!isMobile && (
                                <Route
                                    index
                                    element={
                                        <Navigate
                                            to="/account/settings"
                                            replace
                                        />
                                    }
                                />
                            )}
                            <Route
                                path="settings"
                                element={<AccountSettingsPage />}
                            />
                            <Route
                                path="address"
                                element={<AccountAddressPage />}
                            />
                            <Route
                                path="address/move"
                                element={<MoveAddressPage />}
                            />
                            <Route
                                path="utility"
                                element={<AccountUtilityPage />}
                            />
                            <Route path="faq" element={<AccountFaqsPage />} />
                            <Route
                                path="support"
                                element={<AccountSupportPage />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to="/account" replace />}
                            />
                            <Route path="legal" element={<LegalPage />} />
                        </Route>
                        <Route path="privacy" element={<PrivacyPolicyPage />} />
                        <Route path="terms" element={<TermsOfServicePage />} />
                    </Route>
                    <Route path="privacy" element={<PrivacyPolicyPage />} />
                    <Route path="terms" element={<TermsOfServicePage />} />
                </Route>

                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            {/* </Route> */}
        </SentryRoutes>
    );
});

export default function AppRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <RootRouter />
        </BrowserRouter>
    );
}
