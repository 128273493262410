import { useEffect } from "react";
import { useInstances } from "react-ioc";
import * as Sentry from "@sentry/react";
import OpenReplay from "@openreplay/tracker";
import SessionStore from "@store/auth/session.store";
import { UserStore } from "@store/auth/user.store";

export const useOpenReplay = ((projectKey: string | undefined) => {
    if (!projectKey) {
        return function dummyUseOpenReplay() {
            return null;
        };
    }

    const OPEN_REPLAY_TRACKER = new OpenReplay({
        projectKey,
        __DISABLE_SECURE_MODE: true,
        captureIFrames: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return function useOpenReplay(): void {
        const [sessionStore, userStore] = useInstances(SessionStore, UserStore);

        useEffect(() => {
            // OPEN REPLAY Initialize
            if (!OPEN_REPLAY_TRACKER.isActive()) {
                OPEN_REPLAY_TRACKER.start();
            }

            // OPEN REPLAY Metadata
            if (sessionStore?.session?.email || userStore.user?.email) {
                OPEN_REPLAY_TRACKER.setUserID(
                    sessionStore.session?.email ?? userStore.user?.email ?? "",
                );
                Sentry.setTag(
                    "openReplayUserId",
                    sessionStore.session?.email ?? userStore.user?.email,
                );
            }

            // OPEN REPLAY Metadata
            if (window.location.pathname) {
                OPEN_REPLAY_TRACKER.setMetadata(
                    "URL",
                    window.location.pathname,
                );
            }

            if (OPEN_REPLAY_TRACKER.getSessionToken()) {
                Sentry.setTag(
                    "openReplaySessionToken",
                    OPEN_REPLAY_TRACKER.getSessionToken(),
                );
            }

            if (sessionStore.sessionId) {
                Sentry.setTag("firestoreSessionId", sessionStore.sessionId);
            }
            if (userStore.userId) {
                Sentry.setTag("firestoreUserId", userStore.userId);
            }
        }, [
            OPEN_REPLAY_TRACKER,
            sessionStore?.session?.email,
            userStore.user?.email,
            sessionStore.sessionId,
            userStore.userId,
        ]);
    };
})(process.env.REACT_APP_OPEN_REPLAY_ID);

export default useOpenReplay;
