import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import Alert from "@components/elements/Alert/Alert";
import Box from "@components/fondation/Box/Box";
import LinkUtilityStore from "@store/auth/link-utility.store";
import NavLink from "@components/elements/NavLink/NavLink";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { UserStore } from "@store/auth/user.store";

export const Messages = observer(
    ({ mb }: { mb: { md?: number; xs?: number } }) => {
        const [
            { isConfigError, linkingStatus: status, error: linkError },
            userStore,
        ] = useInstances(LinkUtilityStore, UserStore);

        const address = userStore.user?.address;

        if (!address) {
            return (
                <Box mb={mb}>
                    <Alert severity="info">
                        <AlertTitle>Please provide your address</AlertTitle>
                        Don&lsquo;t worry, we don&lsquo;t like junk mail either
                        <br />
                        <NavLink to="/enrollment/about/address">
                            Add your address
                            <EastOutlinedIcon />
                        </NavLink>
                    </Alert>
                </Box>
            );
        }

        if (status === "unlinked") {
            return (
                <Box mb={mb}>
                    <Alert severity="info">
                        <AlertTitle>
                            Finish linking your utility account
                        </AlertTitle>
                        And get more personalized results
                        <br />
                        <NavLink to="/enrollment/provider/connect">
                            Link account
                            <EastOutlinedIcon />
                        </NavLink>
                    </Alert>
                </Box>
            );
        }

        if (status === "error") {
            return (
                <Box mb={mb}>
                    <Alert severity="error">
                        <AlertTitle>
                            {linkError?.title ?? "Account connection failed"}
                        </AlertTitle>
                        {linkError?.message ?? "Unknown error"}
                        <br />
                        <NavLink
                            to={
                                isConfigError
                                    ? "/enrollment/provider/link"
                                    : "/enrollment/provider/connect"
                            }
                        >
                            Link account
                            <EastOutlinedIcon />
                        </NavLink>
                    </Alert>
                </Box>
            );
        }

        if (status === "in-progress") {
            return (
                <Box mb={mb}>
                    <Alert severity="warning">
                        <AlertTitle>
                            We are processing your utility data
                        </AlertTitle>
                        Standby for personalized, apples-to-apples results. In
                        the meantime, here&lsquo;s our top pick.
                    </Alert>
                </Box>
            );
        }

        return null;
    },
);
