import { addMonths } from "date-fns";
import { computed } from "mobx";
import { inject } from "react-ioc";
import type {
    ContractQuote,
    UserContract,
    UserService as IUserService,
    UserSupplier,
} from "@model/types/user";
import { parseUTC } from "@root/lib/date";
import ProductStore from "./product.store";

export default class RateContract {
    productStore = inject(this, ProductStore);

    @computed get service(): IUserService | undefined {
        return this.productStore.service;
    }

    @computed get contract(): UserContract | undefined {
        return this.service?.contract;
    }

    @computed get quote(): ContractQuote | undefined {
        return this.contract?.quote;
    }

    @computed get contractRate(): number | undefined {
        return this.contract?.rate;
    }

    @computed get supplier(): UserSupplier | undefined {
        return this.quote?.rate?.supplier;
    }

    @computed get term(): number {
        return this.quote?.rate?.term ?? 0;
    }

    @computed get documents(): Record<string, string> | undefined {
        return this.contract?.documents;
    }

    @computed get estStartDate(): Date | null {
        return this.quote?.rate?.effectiveDate
            ? parseUTC(this.quote?.rate?.effectiveDate)
            : null;
    }

    @computed get estEndDate(): Date | null {
        if (this.estStartDate == null) {
            return null;
        }
        return addMonths(this.estStartDate, this.term);
    }

    @computed get isRenewable(): boolean {
        return !!this.quote?.rate.green;
    }

    @computed get estimatedSavings(): number {
        if (this.productStore.activePlan === "best-renewable") {
            return 0;
        }
        return this.quote?.estimatedSavings ?? 0;
    }
}
