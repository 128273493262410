import { parseQueryParams } from "@root/lib/browserUtils";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export type IUrlQueryParams = Record<string, string>;

const useQueryParams = <T extends IUrlQueryParams>(): Partial<T> => {
    const { search } = useLocation();
    return useMemo(() => parseQueryParams<T>(search), [search]);
};

export default useQueryParams;
