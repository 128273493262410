/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";

import * as React from "react";
import Grid from "@components/fondation/Grid/Grid";
import FormControl from "@mui/material/FormControl";
import { FormikContext, FormikHelpers, useFormik } from "formik";

import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import Typography from "@components/fondation/Typography/Typography";
import RadioGroup from "@components/elements/Radio/RadioGroup";
import RadioCard from "@components/elements/Radio/RadioCard";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@components/fondation/Box/Box";
import { BaseSchema } from "yup";
import { hasError } from "@components/modules/forms/common/utils";
import FormTextField from "@components/modules/forms/common/FormTextField";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import AddressAutocomplete from "../components/AddressAutocomplete";
import {
    fullAddressValidationSchema,
    parseAutocomplete,
    ownershipOptions,
    classOptions,
    defaultInitialValues,
} from "./utils";
import { AddressFormData } from "./types";

type PlaceResult = google.maps.places.PlaceResult;

export interface FullAddressFormProps {
    initialValues?: Partial<AddressFormData>;
    loading?: boolean;
    children?: React.ReactNode;
    onSubmit: (
        form: AddressFormData,
        helpers: FormikHelpers<AddressFormData>,
        isGoogleAddress?: boolean,
    ) => void;
}

const validationSchema: BaseSchema = fullAddressValidationSchema;

function AddressForm({
    children,
    initialValues,
    loading,
    onSubmit,
}: FullAddressFormProps): JSX.Element {
    const [isGoogleAddress, setGoogleAddress] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: { ...defaultInitialValues, ...initialValues },
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema,
        onSubmit: (form, helpers) =>
            onSubmit(validationSchema.cast(form), helpers, isGoogleAddress),
    });

    const setAddressValues = useCallback(
        (result: PlaceResult | null | string) => {
            if (result == null) {
                formik.setFieldValue("address", "");
                setGoogleAddress(false);
                return;
            }
            if (typeof result === "string") {
                formik.setFieldValue("address", result as string);
                setGoogleAddress(false);
                return;
            }

            setGoogleAddress(true);
            const { place_id, address_components } = result as PlaceResult;
            if (!address_components || !place_id) {
                return;
            }
            const newFormData = parseAutocomplete(place_id, address_components);

            formik.resetForm({ values: { ...formik.values, ...newFormData } });
            formik.validateForm();
            UseRudderStack.INSTANCE.trackEvent(
                "address-form_fill-street_address",
                { values: newFormData.address },
            );
            UseRudderStack.INSTANCE.trackEvent("address-form_fill-apt_suite", {
                values: newFormData.apartment,
            });
            UseRudderStack.INSTANCE.trackEvent("address-form_fill-state", {
                values: newFormData.state,
            });
            UseRudderStack.INSTANCE.trackEvent("address-form_fill-zip_code", {
                values: newFormData.postalCode,
            });
        },
        [formik.values],
    );

    const errorMessage = (filedName: keyof AddressFormData) => {
        if (!formik.touched[filedName]) {
            return "";
        }
        return formik.errors[filedName] ?? "";
    };

    const disabled = loading || formik.isSubmitting || !formik.isValid;

    useEffect(() => {
        if (formik.values.propertyClass !== "residential") {
            formik.setFieldValue("owner", undefined);
        }
    }, [formik.values.propertyClass]);

    // useEffect(() => {
    // 	formik.validateForm()
    // }, [formik.values])

    return (
        <FormikContext.Provider value={formik}>
            <FormControl
                component="form"
                onSubmit={(event) => {
                    formik.handleSubmit(event);
                    UseRudderStack.INSTANCE.trackEvent(
                        "address-button_click-continue",
                    );
                }}
            >
                <Box mb={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {children}
                        </Grid>

                        <Grid item xs={12}>
                            <AddressAutocomplete
                                defaultValue={formik.values.address}
                                name="address"
                                inputLabel="Street Address"
                                error={hasError(formik, "address")}
                                helperText={errorMessage("address")}
                                onBlur={(event) =>
                                    setAddressValues(event.target.value)
                                }
                                onChange={setAddressValues}
                            />
                            {hasError(formik, "address") && (
                                <FormHelperText error>
                                    {errorMessage("address")}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <FormTextField
                                fullWidth
                                name="apartment"
                                label="Apt/Suite"
                                onBlur={(event) => {
                                    UseRudderStack.INSTANCE.trackEvent(
                                        "address-form_fill-apt_suite",
                                        { values: event.target.value },
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormTextField
                                fullWidth
                                name="city"
                                label="City"
                                onBlur={(event) => {
                                    UseRudderStack.INSTANCE.trackEvent(
                                        "address-form_fill-city",
                                        { values: event.target.value },
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormTextField
                                fullWidth
                                name="state"
                                label="State"
                                onBlur={(event) => {
                                    UseRudderStack.INSTANCE.trackEvent(
                                        "address-form_fill-state",
                                        { values: event.target.value },
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormTextField
                                fullWidth
                                name="postalCode"
                                label="Zip Code"
                                onBlur={(event) => {
                                    UseRudderStack.INSTANCE.trackEvent(
                                        "address-form_fill-zip_code",
                                        { values: event.target.value },
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={8}>
                    <Typography variant="h4" mb={2}>
                        Is this a home or business?
                    </Typography>
                    <RadioGroup
                        value={formik.values.propertyClass}
                        name="propertyClass"
                        onChange={(event) => {
                            formik.handleChange(event);
                            UseRudderStack.INSTANCE.trackEvent(
                                "address-radio_select-property_type",
                                { values: event.target.value },
                            );
                        }}
                        onBlur={formik.handleBlur}
                    >
                        <Grid container spacing={3}>
                            {classOptions.map(({ name, label }) => (
                                <Grid item xs={6} key={name}>
                                    <RadioCard value={name} fullWidth>
                                        {label}
                                    </RadioCard>
                                </Grid>
                            ))}
                        </Grid>
                        {hasError(formik, "propertyClass") && (
                            <FormHelperText error>
                                {errorMessage("propertyClass")}
                            </FormHelperText>
                        )}
                    </RadioGroup>
                </Box>

                {formik.values.propertyClass === "residential" && (
                    <Box mb={6}>
                        <Typography variant="h4" mb={2}>
                            Do you own this property?
                        </Typography>
                        <RadioGroup
                            name="owner"
                            value={
                                formik.values.owner === true
                                    ? "own"
                                    : formik.values.owner === false
                                    ? "rent"
                                    : undefined
                            }
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "owner",
                                    e.target.value === "own",
                                );
                                UseRudderStack.INSTANCE.trackEvent(
                                    "address-radio_select-homeowner",
                                    { values: e.target.value },
                                );
                            }}
                            onBlur={formik.handleBlur}
                        >
                            <Grid container spacing={3}>
                                {ownershipOptions.map(({ name, label }) => (
                                    <Grid item xs={6} key={label}>
                                        <RadioCard value={name} fullWidth>
                                            {label}
                                        </RadioCard>
                                    </Grid>
                                ))}
                            </Grid>
                            {hasError(formik, "owner") && (
                                <FormHelperText error>
                                    {errorMessage("owner")}
                                </FormHelperText>
                            )}
                        </RadioGroup>
                    </Box>
                )}

                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={disabled}
                    endIcon={loading && <Loader />}
                >
                    Continue
                </Button>
            </FormControl>
        </FormikContext.Provider>
    );
}

export default AddressForm;
