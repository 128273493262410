export const sizeUnits = ["B", "KB", "MB", "TB", "PT", "EB", "ZB", "YB"];

export const randomNumber = (min: number, range: number): number =>
    Math.floor(Math.random() * range) + min;

export const printFileSize = (bytes: number): string => {
    if (bytes < 0) {
        return `0 ${sizeUnits[0]}`;
    }
    let size = bytes;
    let unitIndex = 0;
    while (size > 1024) {
        size /= 1024;
        unitIndex += 1;
    }
    return `${size.toFixed(2)} ${sizeUnits[unitIndex]}` ?? "?";
};

// Parse phone numbers from formatted string and transform it to number like in example below
// (099) 333-4444 -> 0993334444
export const parseFormattedPhoneNumber = (phone = ""): string =>
    phone.replace(/[^0-9]/g, "");

// Parse international phone number and transform it to number like in example below
// +10993334444 -> 0993334444
export const parseInternationalPhoneNumber = (phone = ""): string =>
    phone.replace(/[^0-9]/g, "").slice(-10);

export const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
});

export const shortMoneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const rateFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
});

export const shortRateFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
