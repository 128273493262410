import type { ReactNode } from "react";
import Typography from "@components/fondation/Typography/Typography";
import Box from "@components/fondation/Box/Box";
import styled from "@mui/material/styles/styled";

export interface HeaderProps {
    before?: ReactNode;
    after?: ReactNode;
    children?: ReactNode;
}

const Container = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    width: "100%",
    alignItems: "center",
    "&>*:last-child": {
        justifySelf: "flex-end",
    },
    [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "auto auto auto",
    },
}));

export function Header({ before, after, children }: HeaderProps): JSX.Element {
    return (
        <Container>
            <Box>{before}</Box>

            <Typography variant="h6" textAlign="center">
                {children}
            </Typography>

            <Box>{after}</Box>
        </Container>
    );
}

export default Header;
