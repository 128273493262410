import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";

import Box from "@components/fondation/Box/Box";
import Typography from "@components/fondation/Typography/Typography";
import NavLink from "@components/elements/NavLink/NavLink";
import TermsAndPolicyStore from "@store/app/terms-policy.store";
import useZendeskWidget from "../hooks/useZendeskWidget";
import UseRudderStack from "../hooks/useRudderStack";

const AgreementFooter = observer(() => {
    const { show } = useInstance(TermsAndPolicyStore);
    useZendeskWidget();

    const onTermsClick = () => {
        show("terms");
        UseRudderStack.INSTANCE.trackEvent(
            "about_you-link_click-terms_of_service",
        );
    };
    const onPolicyClick = () => {
        show("policy");
        UseRudderStack.INSTANCE.trackEvent(
            "about_you-link_click-privacy_policy",
        );
    };

    const onLoaClick = () => {
        show("loa");
        UseRudderStack.INSTANCE.trackEvent("about_you-link_click-loa");
    };

    return (
        <Box width="100%" textAlign="center">
            <Typography
                variant="subtitle1"
                sx={(t) => ({
                    marginBottom: "3px",
                    fontWeight: "bold",
                    color: t.palette.grey[900],
                })}
            >
                We Care About Your Privacy.
            </Typography>
            <Typography
                variant="caption"
                sx={(t) => ({
                    color: t.palette.grey[700],
                })}
            >
                By clicking “Agree and Continue” I am agreeing to contract
                electronically and assenting to Arbor&apos;s{" "}
                <NavLink to="" onClick={onTermsClick}>
                    Terms of Service
                </NavLink>
                ,{" "}
                <NavLink to="" onClick={onPolicyClick}>
                    Privacy Policy
                </NavLink>
                , and{" "}
                <NavLink to="" onClick={onLoaClick}>
                    Letter of Authorization
                </NavLink>
                .
            </Typography>
        </Box>
    );
});

export default AgreementFooter;
