import * as React from "react";
import styled from "@mui/material/styles/styled";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@components/elements/List/ListItemAvatar";
import ListItemText from "@components/elements/List/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Badge from "@mui/material/Badge";
import { r } from "@theme/utils";
import ListItem from "@components/elements/List/ListItem";
import { ListItemProps } from "@mui/material/ListItem";
import { UtilityStatusBadge } from "./UtilityStatusBadge";
import { UtilityStatus } from "./type";
import { utilityInitials } from "./utils";

export interface UtilityAccountMetaProps extends ListItemProps {
    message?: React.ReactNode;
    status?: UtilityStatus;
    name?: string;
    logoSrc?: string;
}

export const Container = styled(ListItem)({
    width: "100%",
    padding: 0,
    borderRadius: "inherit",
    alignItems: "flex-start",
    textAlign: "start",

    ".MuiListItemAvatar-root": {
        textAlign: "center",
        minWidth: r(32),
        marginRight: r(8),
        marginBlock: 0,
    },

    ".MuiListItemText-root": {
        marginBlock: 0,
    },

    ".MuiAvatar-root": {
        width: r(40),
        height: r(40),
    },

    ".MuiAvatar-img": {
        objectFit: "contain",
        backgroundColor: "white",
    },
});

const UtilityAccountMeta: React.FC<UtilityAccountMetaProps> = React.memo(
    ({ status = "unlinked", name, message, logoSrc, ...rest }) => (
        <Container {...rest}>
            <ListItemAvatar>
                {name || logoSrc ? (
                    <Badge
                        badgeContent={<UtilityStatusBadge status={status} />}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <Avatar src={logoSrc} variant="rounded">
                            {utilityInitials(name)}
                        </Avatar>
                    </Badge>
                ) : (
                    <Skeleton
                        variant="rounded"
                        sx={{ margin: 0 }}
                        width={32}
                        height={32}
                    />
                )}
            </ListItemAvatar>
            <ListItemText
                primary="Account:"
                secondary={message ?? <Skeleton width="70%" />}
                primaryTypographyProps={{
                    variant: "caption",
                    color: "grey.800",
                }}
                secondaryTypographyProps={{
                    variant: "subtitle1",
                    color: "grey.900",
                }}
            />
        </Container>
    ),
);

export default UtilityAccountMeta;
