import { useEffect } from "react";

const useZendeskWidget = (): void => {
    useEffect(() => {
        const script = document.createElement("script");
        script.id = "ze-snippet";
        script.src =
            `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}` ??
            "";

        const settings = document.createElement("script");
        settings.id = "ze-settings";
        settings.textContent = `window.zESettings = {
			webWidget: {
			color: { theme: '#000000' },
			}
		};`;

        if (process.env.REACT_APP_ZENDESK_KEY) {
            document.body.appendChild(script);
            document.body.appendChild(settings);
        }

        return () => {
            document.getElementById("ze-snippet")?.remove();
            document.getElementById("ze-settings")?.remove();
        };
    }, []);
};
export default useZendeskWidget;
