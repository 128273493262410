import { observer } from "mobx-react-lite";

import CardBox from "@components/elements/Card/CardBox";
import List from "@components/elements/List/List";
import RichListItem from "@components/elements/List/RichListItem";
import Divider from "@components/fondation/Divider/Divider";
import { getSmarterListMock } from "@components/elements/List/mocks";
import Typography from "@components/fondation/Typography/Typography";
import { r } from "@theme/utils";
import { Fragment } from "react";

export const ClimateAction = observer(() => (
    <CardBox>
        <Typography variant="h4" mb={3}>
            One year of clean energy equals:
        </Typography>

        <List>
            {getSmarterListMock.map((props, idx) => (
                <Fragment key={props.imgSrc}>
                    {idx !== 0 && (
                        <Divider
                            variant="inset"
                            component="li"
                            sx={{
                                marginLeft: r(90),
                                marginBlock: r(10),
                            }}
                        />
                    )}
                    <RichListItem {...props} />
                </Fragment>
            ))}
        </List>
    </CardBox>
));

export default ClimateAction;
