import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";

import Button from "@components/elements/Button/Button";
import RatePreviewCard from "@components/prototypes/dashboard/RatePreviewCard";
import ProductStore from "@store/product/product.store";
import { findRate } from "@store/product/utils";
import AutopilotCardField from "@components/modules/forms/common/AutopilotCardField";
import Loader from "@components/elements/Loader/Loader";
import CheckoutAddress from "@components/prototypes/dashboard/CheckoutAddress";
import CheckoutUtility from "@components/prototypes/dashboard/CheckoutUtility";
import { useNavigate } from "react-router-dom";
import { UtilityStore } from "@store/auth/utilities.store";
import { CheckoutFormFrame } from "./CheckoutFormFrame";
import { getValidationSchema } from "./utils";

type FeatureFlags = {
    needAutopilotForm: boolean;
    needUtilityForm: boolean;
    needAddressForm: boolean;
};

interface CheckoutFormProps {}

export const CheckoutForm: React.FC<CheckoutFormProps> = observer(() => {
    const navigate = useNavigate();
    const [product, utilityStore] = useInstances(ProductStore, UtilityStore);
    const { service, user, utilityRate } = product;

    if (!service || !user || !utilityRate) {
        throw new Error(
            "Some important for Checkout Form data are not available",
        );
    }

    const [autopilot, setAutopilot] = useState(!!user.autopilot);

    const flags: FeatureFlags = {
        needAutopilotForm: !user.autopilot,
        needUtilityForm:
            !service.accountNumber &&
            !service.updates &&
            !service.utility?.link?.ok,
        needAddressForm: !user.address || !user.place,
    };

    const config = utilityStore?.utility?.powerkiosk;

    const validationSchema = useMemo(
        () => getValidationSchema(config),
        [config],
    );

    const handleSubmit = async () => {
        const error = await product.switchPlan({
            ...validationSchema.cast(user.services?.electric, {
                stripUnknown: true,
                context: config,
            }),
            autopilot,
            rate: findRate(utilityRate, product.activePlan),
        });
        if (!error) {
            navigate("/switch/message");
            // if (!product.isUrjanetSupported || product.service?.utility?.link) {
            // 	// TODO
            // } else {
            // 	navigate("/enrollment/provider/connect")
            // }
        }
    };

    useEffect(() => {
        setAutopilot(!!user.autopilot);
    }, [user.autopilot]);

    const loading = product.isLoading;
    const switchDisabled =
        flags.needAddressForm || flags.needUtilityForm || loading;

    const addressNode = <CheckoutAddress cancelable={!flags.needAddressForm} />;

    const ratePlanNode = <RatePreviewCard />;

    const accountNode = <CheckoutUtility cancelable={!flags.needUtilityForm} />;

    const autopilotNode = flags.needAutopilotForm && (
        <AutopilotCardField value={autopilot} onChange={setAutopilot} />
    );

    return (
        <CheckoutFormFrame
            addressSocket={addressNode}
            detailsSocket={ratePlanNode}
            accountSocket={accountNode}
            autopilotSocket={autopilotNode}
            controlSocket={
                <Button
                    variant="contained"
                    type="submit"
                    disabled={switchDisabled}
                    endIcon={loading && <Loader />}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            }
        />
    );
});
