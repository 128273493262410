import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const DEFAULTS = {
    applicationId: process.env.REACT_APP_DATADOG_APP_ID!,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
    env: process.env.REACT_APP_ENVIRONMENT!,
    version: process.env.REACT_APP_DATADOG_VERSION,
    site: "datadoghq.com",
    service: "arbor-core",
};

export function initDatadog() {
    if (!DEFAULTS.applicationId || !DEFAULTS.clientToken || !DEFAULTS.env) {
        return;
    }

    datadogRum.init({
        ...DEFAULTS,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
    });

    datadogLogs.init({
        ...DEFAULTS,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        useSecureSessionCookie: true,
        forwardConsoleLogs: ["info", "warn", "error"],
    });
}
