import { useState } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";
import { useNavigate } from "react-router-dom";

import Typography from "@components/fondation/Typography/Typography";
import Box from "@components/fondation/Box/Box";
import ModalHeader from "@components/elements/HeaderBar/ModalHeader";
import EmailForm, {
    FormData as EmailFormData,
} from "@components/modules/forms/auth/EmailForm";
import SessionStore from "@store/auth/session.store";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import AuthStore from "@store/auth/auth.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import Image from "@components/elements/Image/Image";
import { getImageUrl } from "@components/elements/Image/collection";
import NavLink from "@components/elements/NavLink/NavLink";
import { AppError } from "@model/utils/error";

export const signUpErrorCodeToMessage = (code: string): React.ReactNode => {
    if (code === "CODE_VERIFICATION_FAILED") {
        return <>Provided verification code is incorrect</>;
    }
    if (code === "NOT_EXISTS") {
        return <>There is no user with provided email</>;
    }
    return "We are having trouble, please try again later";
};

const MAIN_IMAGE_SRC = getImageUrl("groovy.png");
const TITLE = "Sign in to your account";
const MESSAGE = "Sign in instantly, no password required.";

const LoginPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const [sessionStore] = useInstances(SessionStore, AuthStore);

    const [error, setError] = useState<AppError | null>(null);

    const handleLogin = async (form: EmailFormData) => {
        setError(null);
        const loginError = await sessionStore.loginWithEmail(form.email);
        if (loginError == null) {
            navigate("/verification", { state: { form } });
        }
        setError(loginError);
    };

    const handleBack = () => {
        navigate("/area");
    };

    return (
        <ModalBox>
            <ModalHeader
                BackButtonProps={{ onClick: handleBack }}
                hideEndButton
            />

            <ModalBox flexBasis="100%" p={4} alignItems="center">
                <Box mt={10} mb={5}>
                    <Image src={MAIN_IMAGE_SRC} height={115} width={208} />
                </Box>

                <Typography variant="h2" textAlign="center" mb={2.5}>
                    {TITLE}
                </Typography>
                <Typography
                    variant="body2"
                    textAlign="center"
                    color="grey.800"
                    mb={3.5}
                >
                    {MESSAGE}
                </Typography>

                {error && (
                    <Box mb={4} width="100%">
                        <Alert severity="error">
                            <AlertTitle>Oops!</AlertTitle>
                            {signUpErrorCodeToMessage(error.code)}
                        </Alert>
                    </Box>
                )}

                <EmailForm
                    loading={sessionStore.isLoading}
                    submitLabel="Sign In"
                    onSubmit={handleLogin}
                />

                <Typography variant="body2" align="center" mt={4}>
                    Need a new account? <NavLink to="/area">Register</NavLink>
                </Typography>
            </ModalBox>
        </ModalBox>
    );
});

export default LoginPage;
