/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from "react-ioc";
import { autorun, makeAutoObservable, reaction, toJS, when } from "mobx";
import * as Sentry from "@sentry/react";

import { Session } from "@model/types/session";
import { UserArea, UserUtility } from "@model/types/user";
import { AppError, catchException, createError } from "@model/utils/error";
import { AppService } from "@services/firebase/app.service";
import AreaService from "@services/firebase/area.service";
import AuthService from "@services/firebase/auth.service";
import SessionService from "@services/firebase/session.service";
import { Unsubscribe } from "firebase/firestore";
import UserService from "@services/firebase/user.service";
import AppStore from "@store/app/app.store";
import { DEFAULT_COUNTRY_CODE } from "@model/constants/utilities/app";
import { SignUpFormData } from "@components/modules/forms/common/types";
import { ChannelTypes } from "@services/firebase/session.service/request.types";
import { trackEvent } from "@model/utils/tracking";
import AuthStore from "./auth.store";
import { UserStore } from "./user.store";

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE);

const isSignUpInProgress = (session: Session): boolean =>
    session.signInMethod === "register";

const isLoginInProgress = (session: Session): boolean =>
    session.signInMethod === "login";

// FIXME: We do such stupid mapping, for future support of error codes from API;
//				For now they just return string value, but it will be actual error codes later.
const getAuthErrorCode = (backendMessage?: string) => {
    if (backendMessage === "There is no user with the provided email") {
        return "NOT_EXISTS";
    }
    if (backendMessage === "A user with the provided email already exists") {
        return "EMAIL_EXISTS";
    }
    if (backendMessage === "A user with the provided phone already exists") {
        return "PHONE_EXISTS";
    }
    if (backendMessage === "The phone provided is invalid") {
        return "INVALID_PHONE";
    }
    if (
        backendMessage ===
        "It looks like your account is disabled. Please reach out to support to restore your access"
    ) {
        return "ACCOUNT_BLOCKED";
    }
    return "UNKNOWN_ERROR";
};

const texas = "http://tx.joinarbor.com/";
const energyKarma = "http://ek.joinarbor.com/";

class SessionStore {
    // injections

    app = inject(this, AppStore);

    appService = inject(this, AppService);

    sessionService = inject(this, SessionService);

    userService = inject(this, UserService);

    areaService = inject(this, AreaService);

    authService = inject(this, AuthService);

    authStore = inject(this, AuthStore);

    userStore = inject(this, UserStore);

    // constructor
    constructor() {
        makeAutoObservable(this);

        this.init(this.userStore.isUserChecked);

        reaction(() => this.userStore.isUserChecked, this.init);

        autorun(() => {
            const sessionId = sessionStorage.getItem("sessionId");
            if (
                !this.authStore.isAuthorized &&
                sessionId != null &&
                this.session?.id === sessionId &&
                this.session?.status === "registered"
            ) {
                sessionStorage.removeItem("sessionId");
                this.subscribeOnUserUpdate();
            }
        });

        if (IS_DEV_MODE) {
            autorun(() => console.log("SESSION", toJS(this.session)));
        }
    }

    // attributes

    session: Session | null = null;

    isLoading = false;

    error: AppError | null = null;

    unsubscribe: Unsubscribe | null = null;

    // computed

    get isInit(): boolean {
        return !!this.session?.createdAt;
    }

    get sessionId(): string | undefined {
        return this.session?.id ?? sessionStorage.sessionId;
    }

    get area(): UserArea | undefined | null {
        return this.session?.area ?? null;
    }

    get isAreaValid(): boolean {
        return !!this.area?.ok && !!this.utilities?.electric?.length;
    }

    get isLoginInProgress(): boolean {
        return !!this.session && isLoginInProgress(this.session);
    }

    get isSignUpInProgress(): boolean {
        return !!this.session && isSignUpInProgress(this.session);
    }

    get utilities(): Record<"electric" | "gas", UserUtility[]> | null {
        return this.area?.utilities ?? null;
    }

    get currentUtilityId(): string | undefined {
        return (
            this.userStore.user?.services?.electric?.utility.id ??
            this.session?.services?.electric?.utility.id
        );
    }

    get currentSelectedUtility(): UserUtility | null {
        return this.currentUtilityId != null
            ? this.utilities?.electric?.find(
                  (it) => it.id === this.currentUtilityId,
              ) ?? null
            : null;
    }

    get currentUtilityShortName(): string | undefined {
        return this.currentSelectedUtility?.name
            ?.replaceAll(/\(.+\)/g, "")
            .trim();
    }

    get isUtilityValid(): boolean {
        return !!this.currentUtilityId;
    }

    get defaultChannel(): ChannelTypes {
        return this.app.device === "desktop" ? "email" : "sms";
    }

    // actions

    getSessionId = async (): Promise<string> =>
        this.sessionId || this.createSession();

    setSession = (session: typeof this.session) => {
        this.session = session;
    };

    init = async (isUserReady = false) => {
        const sessionId = this.userStore.user?.sessionId;

        if (!isUserReady || !sessionId) {
            this.subscribeOnUserUpdate();
        }

        if (this.session?.id === sessionId) {
            return;
        }

        this.subscribeOnUserUpdate(sessionId);
    };

    subscribeOnUserUpdate = async (sessionId?: string) => {
        try {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
            const currentSessionId = sessionId || (await this.getSessionId());

            this.unsubscribe = this.sessionService.subscribe(
                currentSessionId,
                (session) => {
                    this.setSession(session);
                    if (sessionStorage.sessionId === currentSessionId) {
                        return;
                    }
                    sessionStorage.sessionId = currentSessionId;
                },
            );
        } catch (error) {
            catchException(error);
            this.unsubscribe = null;
            this.error = createError();
        }
    };

    private whenSessionUpdated = async (
        predicate: (session: Session) => boolean = () => true,
    ) => {
        const lastModified = this.session?.modifiedAt?.toString();
        await when(() => {
            if (!this.session) {
                return false;
            }
            const newModified = this.session?.modifiedAt?.toString();
            if (lastModified === newModified) {
                return false;
            }
            return predicate(this.session);
        });
    };

    private createSession = async () => {
        try {
            trackEvent("Entered");

            const { id } = await this.sessionService.createSession();
            this.appService.grabIP(id);
            if (id) {
                sessionStorage.sessionId = id;
                return id;
            }
        } catch (error) {
            catchException(error);
        }
        throw Error("CREATE_SESSION_FAILED");
    };

    inSessionScope = async ({
        call,
        onError,
        timeout = 60_000,
    }: {
        call: (sessionId: string) => string | void | Promise<string | void>;
        onError?: (error: unknown) => AppError | null;
        timeout?: number;
    }) => {
        try {
            if (!this.sessionId) {
                this.error = createError(
                    "SESSION_NOT_INITED",
                    "Action is not allowed",
                );
                return this.error;
            }

            this.isLoading = true;
            this.error = null;

            const timer = new Promise((resolve) => {
                setTimeout(() => resolve("REQUEST_TIMEOUT"), timeout);
            });

            const res = await Promise.any([call(this.sessionId), timer]);

            if (typeof res === "string") {
                throw new Error(res);
            }
        } catch (error: any) {
            catchException(error);

            this.error = onError?.(error) ?? createError(error.message);
        } finally {
            this.isLoading = false;
        }
        return this.error;
    };

    updateSessionArea = async (postalCode: string, email?: string) =>
        this.inSessionScope({
            call: async (sessionId) => {
                trackEvent("Zip");

                await this.sessionService.updateLog(sessionId, "select-area", {
                    countryCode: DEFAULT_COUNTRY_CODE,
                    postalCode,
                    email,
                });

                await this.whenSessionUpdated(
                    (session) => session.area?.postalCode === postalCode,
                );

                if (!this.session?.area?.ok && !this.session?.area?.isTexas) {
                    trackEvent("Unsupported");
                    this.sessionService.updateLog(sessionId, "set-state", {
                        status: "unsupported",
                        email,
                    });
                    return "UNSUPPORTED";
                }
                if (this.session?.area?.isTexas) {
                    this.redirectTexas(postalCode, email);
                }
            },
        });

    redirectTexas = (zipcode: string, email?: string) => {
        window.open(
            `${texas}?zipcode=${zipcode}${email ? `&email=${email}` : ""}`,
            "_self",
        );
    };

    redirectEnergyKarma = (zipcode?: string, email?: string) => {
        window.open(
            `${energyKarma}?${zipcode ? `zipcode=${zipcode}` : ""}${
                email ? `&email=${email}` : ""
            }`,
            "_self",
        );
    };

    setCallbackEmail = async (email: string) =>
        this.inSessionScope({
            call: async (sessionId) => {
                await this.sessionService.updateLog(sessionId, "set-state", {
                    email,
                });
            },
        });

    clearSession = async () =>
        this.inSessionScope({
            call: async (sessionId) => {
                await this.sessionService.updateLog(
                    sessionId,
                    "clear-session",
                    {},
                );
            },
        });

    selectUtility = async (utility: UserUtility) =>
        this.inSessionScope({
            call: async (sessionId) => {
                if (this.currentUtilityId === utility.id) {
                    return;
                }

                const stateId = this.area?.state;

                if (!stateId) {
                    throw Error("Area is not supported");
                }

                this.isLoading = true;
                this.error = null;

                const request = {
                    stateId,
                    electricUtilityId: utility.id,
                    electricUtilityZone: utility.zone,
                };

                if (this.userStore.userId) {
                    await this.userService.updateLog(
                        this.userStore.userId,
                        "select-utility",
                        request,
                    );
                } else {
                    await this.sessionService.updateLog(
                        sessionId,
                        "select-utility",
                        request,
                    );
                }
            },
        });

    signUpWithMagicLink = async (form: SignUpFormData) =>
        this.inSessionScope({
            call: async (sessionId) => {
                await this.sessionService.updateLog(
                    sessionId,
                    "send-magic-link",
                    {
                        phone: form.phone,
                        email: form.email,
                        name: `${form.firstName} ${form.lastName}`,
                        option: "register",
                        channel: this.defaultChannel,
                        local: this.app.isLocalhost,
                    },
                );

                await this.whenSessionUpdated(
                    (next) => !!next.success || !!next.error,
                );

                const error = this.session?.error;
                if (error != null && error !== "none") {
                    return getAuthErrorCode(error);
                }
            },
        });

    signInWithLink = async () =>
        this.inSessionScope({
            call: async () => {
                const email = this.session?.email || "";
                const link = this.session?.signInLink || "";
                const loginError = await this.authStore.loginWithLink(
                    email,
                    link,
                );
                const { authUser } = this.authStore;
                if (loginError || !authUser) {
                    throw new Error("LOGIN_FAILED");
                }
            },
        });

    finalizeSignUp = async (
        email?: string,
        link?: string,
        form?: SignUpFormData,
    ) =>
        this.inSessionScope({
            call: async (sessionId) => {
                const credentials = await this.authService.checkExistingUser(
                    form?.email || "",
                );
                if (credentials.length >= 1) {
                    const error =
                        "A user with the provided email already exists";
                    return getAuthErrorCode(error);
                }

                await this.sessionService.updateLog(sessionId, "create-user", {
                    sessionId,
                    email: form?.email,
                    phoneNumber: form?.phone,
                    name: `${form?.firstName} ${form?.lastName}`,
                });

                await this.whenSessionUpdated(
                    (next) => next.status === "registered" || !!next.error,
                );

                const error = this.session?.error;

                if (error != null && error !== "none") {
                    Sentry.captureMessage(error, "error");
                    return getAuthErrorCode(error);
                }

                if (!this.userStore.error) {
                    trackEvent("Registered");
                }
            },
        });

    loginWithEmail = async (email: string, channel?: ChannelTypes) =>
        this.inSessionScope({
            call: async (sessionId) => {
                await this.sessionService.updateLog(
                    sessionId,
                    "send-magic-link",
                    {
                        email,
                        channel: channel ?? this.defaultChannel,
                        option: "login",
                        local: this.app.isLocalhost,
                    },
                );

                await this.whenSessionUpdated(
                    (next) => !!next.success || !!next.error,
                );

                const error = this.session?.error;

                if (error != null && error !== "none") {
                    Sentry.captureMessage(error, "error");
                    return getAuthErrorCode(error);
                }
            },
        });

    verifyCode = async (email: string, code: string) =>
        this.inSessionScope({
            call: async (sessionId) => {
                this.sessionService.updateLog(sessionId, "check-verification", {
                    code,
                    email,
                    option: "login",
                    signInLink: this.session?.signInLink,
                    device: this.app.device,
                    oauth: false,
                });

                await this.whenSessionUpdated(
                    () => this.session?.success != null,
                );

                if (!this.session?.signInLink) {
                    return "CODE_VERIFICATION_FAILED";
                }

                await this.authService.loginWithEmailLink(
                    email,
                    this.session?.signInLink,
                );
            },
        });
}

export default SessionStore;
