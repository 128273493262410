import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, RouteProps, Outlet, useLocation } from "react-router-dom";
import { useInstance } from "react-ioc";
import AuthStore from "@store/auth/auth.store";

const PrivateOutlet: React.FC<RouteProps> = observer(() => {
    const location = useLocation();
    const auth = useInstance(AuthStore);

    if (!auth.isAuthorized) {
        return (
            <Navigate
                to="/login"
                replace
                state={{ afterLogin: location.pathname, replace: true }}
            />
        );
    }

    return <Outlet />;
});

export default PrivateOutlet;
