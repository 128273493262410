import * as React from "react";
import { observer } from "mobx-react-lite";

import ModalDrawer from "@components/elements/Drawer/ModalDrawer";
import Typography from "@components/fondation/Typography/Typography";
import Button from "@components/elements/Button/Button";
import { AppMessage } from "@model/utils/error";

export interface DrawerContentProps {
    container?: Element;
    content?: AppMessage | null;
    open?: boolean;
    onClose?: () => void;
}

export const HelperDrawer: React.FC<DrawerContentProps> = observer(
    ({ open = false, container, content, onClose }) => (
        <ModalDrawer
            open={open}
            onClose={onClose}
            anchor="bottom"
            container={container}
        >
            <Typography variant="h4" mb={2}>
                {content?.title}
            </Typography>

            <Typography variant="body2" mb={4}>
                {content?.message}
            </Typography>

            <Button variant="outlined" onClick={onClose}>
                Close
            </Button>
        </ModalDrawer>
    ),
);

export default HelperDrawer;
