import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";

import SequenceContainer from "@components/elements/Card/SequenceCard/SequenceContainer";
import SequenceCard from "@components/elements/Card/SequenceCard";
import { getHowItWorksCopy } from "@components/elements/Card/SequenceCard/constants";
import { indexToColor } from "@components/elements/Card/SequenceCard/utils";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import { UtilityStore } from "@store/auth/utilities.store";

export const HowItWorksList = observer(() => {
    const { utilityShortName } = useInstance(UtilityStore);
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

    const sequence = useMemo(
        () => getHowItWorksCopy(utilityShortName),
        [utilityShortName],
    );

    return (
        <SequenceContainer orientation={isMobile ? "vertical" : "horizontal"}>
            {sequence.map(({ id, title, content }, idx) => (
                <SequenceCard
                    index={idx + 1}
                    color={indexToColor}
                    title={title}
                    key={id}
                >
                    {content}
                </SequenceCard>
            ))}
        </SequenceContainer>
    );
});

export default HowItWorksList;
