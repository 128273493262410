import { format } from "util";
import { cloudFunctions } from "@services/firebase/firebase";

export const FUNCTIONS_URL = (cloudFunctions as any).emulatorOrigin
    ? `${(cloudFunctions as any).emulatorOrigin}/${
          cloudFunctions.app.options.projectId
      }/${cloudFunctions.region}`
    : format(
          process.env.REACT_APP_BACKEND_API?.replace(/\/backend/, ""),
          process.env.REACT_APP_FIREBASE_PROJECT_ID,
      );
