import * as React from "react";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Chip from "@components/elements/Chip/Chip";
import { getImageUrl } from "@components/elements/Image/collection";
import { statusMapper } from "../constants";
import { PlanStatus } from "../types";
import UIContainer from "./HeaderBase";

export interface HeaderProps {
    status?: PlanStatus;
}

const Status = styled(Chip)({
    position: "absolute",
    left: "50%",
    bottom: 0,
    transform: "translate(-50%, 50%)",
});

const Container = styled(UIContainer, { target: "Header-root" })({
    position: "relative",
    minHeight: r(86),
    backgroundImage: `url(${getImageUrl("hero_00.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center -108px",
    backgroundSize: "440px 264px",
});

const ImageHeader: React.FC<HeaderProps> = React.memo((props) => {
    const { status = "OLD" } = props;
    const context = statusMapper[status];

    return (
        <Container bgcolor={context.bgColor}>
            <Status label={context.label} color={context.color} />
        </Container>
    );
});

export default ImageHeader;
