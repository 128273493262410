import { inject } from "react-ioc";
import { makeAutoObservable, reaction } from "mobx";

import { AppError, catchException, createError } from "@model/utils/error";
import UserService from "@services/firebase/user.service";
import type { UserService as IUserService, User } from "@model/types/user";
import type { Utility, Rate } from "@model/types/utilities";
import { UserActionData } from "@services/firebase/user.service/request.types";
import { UserStore } from "../auth/user.store";
import { UtilityStore } from "../auth/utilities.store";
import { PlanOption } from "./types";

export const preferenceOptions = [
    { name: "lowest-price", label: "Savings" },
    { name: "best-renewable", label: "Renewables" },
];

export class ProductStore {
    // injections

    protected userService = inject(this, UserService);

    protected userStore = inject(this, UserStore);

    protected utilityStore = inject(this, UtilityStore);

    // constructors

    constructor() {
        makeAutoObservable(this);

        if (this.user?.services?.electric?.quotes?.renewableFixed?.approved) {
            this.activePlan = "best-renewable";
        } else if (
            this.user?.services?.electric?.quotes?.lowestFixed?.approved
        ) {
            this.activePlan = "lowest-price";
        } else {
            this.activePlan =
                this.user?.renewable === "very-important"
                    ? "best-renewable"
                    : "lowest-price";
        }
        reaction(
            () => this.user?.id,
            () => {
                if (
                    this.user?.services?.electric?.quotes?.renewableFixed
                        ?.approved
                ) {
                    this.activePlan = "best-renewable";
                } else if (
                    this.user?.services?.electric?.quotes?.lowestFixed?.approved
                ) {
                    this.activePlan = "lowest-price";
                } else {
                    this.activePlan =
                        this.user?.renewable === "very-important"
                            ? "best-renewable"
                            : "lowest-price";
                }
            },
        );
    }

    // attributes

    error: AppError | null = null;

    isLoading = false;

    activePlan: PlanOption;
    // computed

    get utility(): Utility | null {
        return this.utilityStore.utility;
    }

    get utilityRate(): Rate | undefined {
        return this.utility?.rates?.electric?.[0];
    }

    get user(): User | null {
        return this.userStore.user;
    }

    get service(): IUserService | undefined {
        return this.user?.services?.electric;
    }

    get isUrjanetSupported(): boolean {
        return !!this.utility?.urjanet?.providerId;
    }

    // actions

    changeOption = async (option: PlanOption) => {
        this.activePlan = option;
    };

    initiateRateSwitch = async () => {
        this.userStore.inUserScope({
            call: async (userId) => {
                await this.userService.updateLog(userId, "set-state", {
                    switchNow: true,
                });
            },
        });
    };

    switchPlan = async (form: UserActionData<"confirm-switch">) => {
        try {
            this.isLoading = true;

            await this.userStore.inUserScope({
                call: async (userId) => {
                    await this.userService.updateLog(
                        userId,
                        "confirm-switch",
                        form,
                    );

                    await this.userStore.whenUserUpdated();
                },
                onError: (error) => {
                    throw error;
                },
            });
        } catch (error) {
            catchException(error);
            this.error = createError();
        } finally {
            this.isLoading = false;
        }
        return this.error;
    };
}
export default ProductStore;
