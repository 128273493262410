import * as React from "react";
import styled from "@mui/material/styles/styled";

export interface ImageProps
    extends React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {}

const Image = styled("img")<ImageProps>(({ onClick }) => ({
    cursor: onClick ? "pointer" : "default",
    userSelect: "none",
}));

export default Image;
