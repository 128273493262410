import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Box from "@components/fondation/Box/Box";

const Container = styled(Box, { target: "Header-root" })<{
    collapsible?: boolean;
}>(({ theme, collapsible }) => ({
    display: "flex",
    flexDirection: "column",
    padding: r(16),
    minHeight: collapsible ? r(48) : undefined,
    borderRadius: theme.shape.borderRadius,

    ".MuiChip-root": {
        borderRadius: r(6),
    },

    ".MuiIconButton-root": {
        margin: r(-5),
    },
}));

export default Container;
