import { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Grid from "@components/fondation/Grid/Grid";
import FormControl from "@mui/material/FormControl";

import Loader from "@components/elements/Loader/Loader";
import Button from "@components/elements/Button/Button";
import TextField from "@components/elements/TextField/TextField";
import {
    emailValidator,
    zipCodeValidator,
} from "@components/modules/forms/common/validations";

export type FormData = {
    zipCode: string;
    email?: string;
};

const defaultInitialValues: FormData = { zipCode: "", email: "" };

export const validationSchema = yup.object().shape({
    zipCode: zipCodeValidator.clone().required("Please input a Zip Code"),
    email: emailValidator.clone(),
});

export interface ZipCodeFormProps {
    initialValues?: Partial<FormData>;
    loading?: boolean;
    shouldDisable?: boolean;
    onSubmit?: (form: FormData) => void;
}

export function ZipCodeForm({
    initialValues = undefined,
    onSubmit = () => undefined,
    loading = false,
    shouldDisable,
}: ZipCodeFormProps): JSX.Element {
    const formik = useFormik<FormData>({
        initialValues: { ...defaultInitialValues, ...initialValues },
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    const submitButtonDisabled = !formik.isValid || loading || shouldDisable;

    return (
        <FormControl component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        name="zipCode"
                        error={
                            formik.touched.zipCode && !!formik.errors.zipCode
                        }
                        helperText={
                            formik.touched.zipCode && formik.errors.zipCode
                        }
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        label="Zip Code"
                        type="string"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="email"
                        type="email"
                        error={formik.touched.email && !!formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        label="Email"
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button
                        disabled={submitButtonDisabled}
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={loading && <Loader />}
                    >
                        Check My Eligibility
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    );
}

export default ZipCodeForm;
