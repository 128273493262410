import type { ReactNode } from "react";
import Typography from "@components/fondation/Typography/Typography";
import Chip from "@components/elements/Chip/Chip";
import Image from "@components/elements/Image/Image";
import { ChipProps } from "@mui/material/Chip";
import Stack from "@components/fondation/Stack/Stack";

export interface PerformanceCardProps {
    imageSrc: string;
    title: ReactNode;
    badgeProps: ChipProps;
    description: ReactNode;
}

export function PerformanceCard({
    imageSrc,
    title,
    badgeProps,
    description,
}: PerformanceCardProps): JSX.Element {
    return (
        <Stack spacing={2.5} alignItems="flex-start">
            <Image src={imageSrc} height={53} />
            <Typography variant="h4">{title}</Typography>
            <Chip {...badgeProps} />
            <Typography variant="body1" color="grey.800">
                {description}
            </Typography>
        </Stack>
    );
}

export default PerformanceCard;
