import { useCallback, useMemo } from "react";
import * as React from "react";

import Grid from "@components/fondation/Grid/Grid";
import FormTextField from "@components/modules/forms/common/FormTextField";
import { createError } from "@model/utils/error";
import Box from "@components/fondation/Box/Box";
import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import { FormikProvider, useFormik, yupToFormErrors } from "formik";
import { BaseSchema } from "yup";
import Stack from "@components/fondation/Stack/Stack";
import InfoTooltip from "./InfoTooltip";
import {
    EnterBillFormData,
    getFiledDefaultLabel,
    getFiledDefaultMessage,
    getValidationSchema,
    helpersMapper,
} from "./utils";
import { FormConfig } from "./types";

const SHOW_ALL = false; // Boolean(process.env.REACT_APP_DEBUG_MODE)

export const defaultInitialValues: EnterBillFormData = {
    accountNumber: "",
    contactName: "",
    meterNum: undefined,
    referenceNum: undefined,
    nameKey: undefined,
    userRate: undefined,
};

interface UtilityAccountFormProps {
    socketAfter?: React.ReactNode;
    socketBefore?: React.ReactNode;
    config?: FormConfig;
    loading?: boolean;
    cancelable?: boolean;
    submittable?: boolean;
    initialValues?: Partial<EnterBillFormData>;
    onSubmit: (form: EnterBillFormData) => void;
    onCancel?: React.MouseEventHandler;
}

export const UtilityAccountForm: React.FC<UtilityAccountFormProps> = React.memo(
    ({
        socketAfter,
        config,
        loading,
        initialValues,
        socketBefore,
        cancelable = true,
        submittable = true,
        onSubmit,
        onCancel,
    }) => {
        const validationSchema: BaseSchema = useMemo(
            () => getValidationSchema(config),
            [config],
        );

        const initForm = useCallback(
            (prevValues = initialValues) => ({
                ...validationSchema.cast(
                    { ...defaultInitialValues, ...prevValues },
                    { stripUnknown: true, context: config },
                ),
            }),
            [validationSchema, config],
        );

        const formik = useFormik<EnterBillFormData>({
            initialValues: initForm(),
            validationSchema,
            validateOnChange: true,
            validate: (values) => {
                validationSchema
                    .validate(values, { context: config })
                    .catch((err) => yupToFormErrors(err));
            },
            onSubmit: (form) =>
                onSubmit(
                    validationSchema.cast(form, {
                        stripUnknown: true,
                        context: config,
                    }),
                ),
        });

        const getHelperCallback = (filedName: keyof EnterBillFormData) => {
            const messageKey = helpersMapper[filedName];
            const message = messageKey?.filedNameKey
                ? config?.[messageKey.filedNameKey]
                : null;
            const title = messageKey?.labelNameKey
                ? config?.[messageKey?.labelNameKey]
                : null;
            return createError(
                filedName.toString(),
                typeof title === "string"
                    ? title
                    : getFiledDefaultLabel(filedName),
                typeof message === "string"
                    ? message
                    : getFiledDefaultMessage(filedName),
            );
        };

        const disabled = loading || formik.isSubmitting || !submittable;
        const withLoader = loading && !submittable;

        return (
            <FormikProvider value={formik}>
                <Stack
                    component="form"
                    flexBasis="100%"
                    onSubmit={formik.handleSubmit}
                    spacing={8}
                >
                    <Box>
                        <Grid container rowSpacing={5} columnSpacing={4}>
                            {socketBefore != null && (
                                <Grid item xs={12}>
                                    {socketBefore}
                                </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                                <FormTextField
                                    name="contactName"
                                    label={getFiledDefaultLabel("contactName")}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InfoTooltip
                                                message={getHelperCallback(
                                                    "contactName",
                                                )}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormTextField
                                    name="accountNumber"
                                    label={
                                        config?.accountNumLabel ||
                                        getFiledDefaultLabel("accountNumber")
                                    }
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InfoTooltip
                                                message={getHelperCallback(
                                                    "accountNumber",
                                                )}
                                            />
                                        ),
                                    }}
                                />
                            </Grid>

                            {(!!config?.showNameKey || SHOW_ALL) && (
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name="nameKey"
                                        label={getFiledDefaultLabel("nameKey")}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InfoTooltip
                                                    message={getHelperCallback(
                                                        "nameKey",
                                                    )}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}

                            {(!!config?.showReferenceNum || SHOW_ALL) && (
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name="referenceNum"
                                        label={getFiledDefaultLabel(
                                            "referenceNum",
                                        )}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InfoTooltip
                                                    message={getHelperCallback(
                                                        "referenceNum",
                                                    )}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}

                            {(!!config?.showMeterNum || SHOW_ALL) && (
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name="meterNum"
                                        label={getFiledDefaultLabel("meterNum")}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InfoTooltip
                                                    message={getHelperCallback(
                                                        "meterNum",
                                                    )}
                                                />
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}

                            {socketAfter != null && (
                                <Grid item xs={12}>
                                    {socketAfter}
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box>
                        <Grid
                            container
                            spacing={3}
                            flexDirection={{
                                xs: "column-reverse",
                                md: "initial",
                            }}
                        >
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    type="reset"
                                    disabled={!cancelable || loading}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={disabled}
                                    endIcon={withLoader && <Loader />}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </FormikProvider>
        );
    },
);

export default UtilityAccountForm;
