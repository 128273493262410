import { useEffect } from "react";
import { BrowserTracing } from "@sentry/tracing";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE);

export interface AppMessage<T extends string = string> {
    code: T;
    title: string;
    message?: string;
    action?: string;
}

export type AppError<T extends string = string> = AppMessage<T>;

export const startSentry = (): void => {
    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment:
                process.env.REACT_APP_SENTRY_ENVIRONMENT ??
                process.env.REACT_APP_FIREBASE_PROJECT_ID,
            release: process.env.REACT_APP_VERSION,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    ),
                }),
            ],
            tracesSampleRate: 0.2,
        });
    }
};

export function createError<T extends string = string>(
    code: T = "UNKNOWN" as T,
    title = "Error",
    message = "Action failed for unknown reason",
): AppError<T> {
    return { code, title, message };
}

export const catchException = (error: unknown): void => {
    Sentry.captureException(error);
    if (IS_DEV_MODE) {
        console.error(error);
    }
};
