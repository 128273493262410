import { ReactElement } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function UploadFile(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9455 10.89C11.2327 11.18 11.698 11.18 11.9851 10.89C12.2822 10.6 12.2822 10.13 11.995 9.84L9.15347 6.96C9.08416 6.89 9.00495 6.84 8.91584 6.8C8.82673 6.76 8.73762 6.74 8.63861 6.74C8.5396 6.74 8.44059 6.76 8.35148 6.8C8.26238 6.84 8.18317 6.89 8.11386 6.96L5.28218 9.84C4.99505 10.13 4.99505 10.6 5.28218 10.89C5.56931 11.18 6.03465 11.18 6.32178 10.89L7.89604 9.29V14.12C7.89604 14.53 8.22277 14.86 8.63861 14.86C9.04455 14.86 9.37129 14.53 9.37129 14.12V9.29L10.9455 10.89ZM16.3282 7.02561C16.5609 7.02292 16.8143 7.02 17.0446 7.02C17.2921 7.02 17.5 7.22 17.5 7.47V15.51C17.5 17.99 15.5 20 13.0446 20H5.17327C2.58911 20 0.5 17.89 0.5 15.29V4.51C0.5 2.03 2.4901 0 4.96535 0H10.2525C10.5 0 10.7079 0.21 10.7079 0.46V3.68C10.7079 5.51 12.1931 7.01 14.0149 7.02C14.4313 7.02 14.801 7.02315 15.1258 7.02591C15.3801 7.02807 15.6069 7.03 15.8069 7.03C15.9479 7.03 16.1306 7.02789 16.3282 7.02561ZM16.6047 5.566C15.7908 5.569 14.8324 5.566 14.1423 5.559C13.0472 5.559 12.1452 4.648 12.1452 3.542V0.906C12.1452 0.475 12.6621 0.261 12.9581 0.572C13.7204 1.37179 14.8872 2.59739 15.8736 3.63346C16.2735 4.05345 16.6437 4.44229 16.9452 4.759C17.2334 5.062 17.0215 5.565 16.6047 5.566Z"
            />
        </SvgIcon>
    );
}

export default UploadFile;
