import * as React from "react";
import { observer } from "mobx-react-lite";

import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import Message, { MessageProps } from "@components/prototypes/Message";
import CardBox from "@components/elements/Card/CardBox";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "@components/modules/layouts/DashboardLayout";
import { getImageUrl } from "@components/elements/Image/collection";

const successMessage: MessageProps = {
    title: "Success",
    message:
        "We are working with your utility to switch you over to your new rate. We will update you as soon as we hear back, usually within 1-2 weeks.",
    imageSrc: getImageUrl("finish.png"),
    buttonText: "Go to dashboard",
};

const CheckoutMessagePage: React.FC = observer(() => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

    return (
        <DashboardLayout>
            <CardBox
                width="100%"
                maxWidth={500}
                elevation={isMobile ? 0 : 1}
                bg={isMobile ? "secondary" : "primary"}
                p={{ xs: 0, md: 10 }}
                m="auto"
            >
                <Message
                    {...successMessage}
                    onSubmit={() => navigate("/dashboard")}
                />
            </CardBox>
        </DashboardLayout>
    );
});

export default CheckoutMessagePage;
