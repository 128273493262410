import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import ButtonBase from "@mui/material/ButtonBase";

export const ButtonBox = styled(ButtonBase)(({ theme }) => ({
    width: "auto",
    padding: r(6),
    borderRadius: theme.shape.borderRadius,
    minWidth: r(160),

    "&:hover": {
        background: theme.palette.grey[50],
    },
}));

export default ButtonBox;
