import { makeAutoObservable } from "mobx";

type ContentType = "terms" | "policy" | "loa";

class TermsAndPolicyStore {
    opened: ContentType | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isOpened(): boolean {
        return this.opened !== null;
    }

    show = (content: ContentType) => {
        this.opened = content;
    };

    hide = () => {
        this.opened = null;
    };
}

export default TermsAndPolicyStore;
