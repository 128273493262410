import { useEffect } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import Stack from "@components/fondation/Stack/Stack";
import Box from "@components/fondation/Box/Box";
import Typography from "@components/fondation/Typography/Typography";
import Image from "@components/elements/Image/Image";
import Button from "@components/elements/Button/Button";
import RichListItem from "@components/elements/List/RichListItem";
import Loader from "@components/elements/Loader/Loader";
import { useInstances } from "react-ioc";
import SessionStore from "@store/auth/session.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import { getImageUrl } from "@components/elements/Image/collection";
import ProductStore from "@store/product/product.store";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { makeStyles } from "@mui/styles";
import bbbLogo from "@components/elements/Image/bbb-logo.png";
import trustedLogo from "@components/elements/Image/Trustpilot-Logo.png";
import star from "@components/elements/Image/Star.svg";

const IMG_SRC = getImageUrl("globe_02.png");

const getOptions = (utilityName = "utility") => [
    {
        title: "We monitor the market",
        description: `We scan the market to find better rates for ${utilityName} customers.`,
        imgSrc: getImageUrl("monitor.png"),
    },
    {
        title: "We help you select",
        description:
            "You can choose from our top pick or we can automatically do it for you.",
        imgSrc: getImageUrl("compare.png"),
    },
    {
        title: "You save",
        description: `Start saving with no commitment or change to your ${utilityName} service.`,
        imgSrc: getImageUrl("save.png"),
    },
];

const userComments = [
    {
        date: "Apr 23, 2023",
        comment:
            "They dropped my rate by a third within a day and took me just a couple minutes to sign up. Pretty great!",
        user: "Matt, Pennsylvania",
        stars: 5,
    },
    {
        date: "Jun 11, 2023",
        comment:
            "I’m very happy to say that Arbor found me best price I could hope for, and my bills have indeed gone down!",
        user: "Jenny, Ohio",
        stars: 5,
    },
];

const useStyles = makeStyles(() => ({
    // pageContainerTop: {
    // 	display: "block",
    // },
    // pageContainerBottom: {
    // 	display: "none",
    // },
    hideElement: {
        display: "none",
    },
    userComment: {
        background: "white",
        padding: "25px",
        fontSize: "14px",
        marginTop: "12px",
        borderRadius: "10px",
    },
    boxTop: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
    },
    logoContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "20px 0 0 0",
    },
    stars: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    bbLogo: {
        height: "50px",
    },
    trustedLogo: {
        height: "40px",
    },
}));

export const GetStartedPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [sessionStore, productStore] = useInstances(
        SessionStore,
        ProductStore,
    );

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "get-started", {
            page_name: "get-started",
            name: "get-started",
            url: window.location.href,
        });
    }, []);

    const handleSubmit = () => {
        UseRudderStack.INSTANCE.trackEvent(
            "get_started-button_click-help_me_save",
        );
        navigate("/enrollment/about", { replace: true });
    };

    const loading = sessionStore.isLoading;
    const canContinue = sessionStore.isAreaValid && !loading;

    const options = getOptions(sessionStore.currentUtilityShortName);
    const foundRates =
        productStore.utilityRate?.rateSummary.supplierRateCount ?? 0;

    const title = foundRates > 0 ? "Great news!" : "We're working on it.";
    const titleBottomPartOne1 =
        foundRates > 0
            ? "Arbor has saved hundreds of PECO customers"
            : "We're working on it.";
    const titleBottomPartOne2 = foundRates > 0 ? "over $250,000!" : "";
    const message =
        foundRates > 0
            ? `We found available rates in your area.`
            : "Searching for available rates in your area.";

    const renderStars = (stars: number) => {
        const starList = [];
        for (let i = 0; i < stars; i += 1) {
            starList.push(
                <img style={{ marginRight: "2px" }} src={star} alt="star" />,
            );
        }

        return starList;
    };

    return (
        <>
            <ModalBox p={4} pt={8} height={1}>
                <Stack
                    spacing={5}
                    alignItems="stretch"
                    direction="column"
                    minHeight={1}
                    flexShrink={0}
                >
                    <Box alignSelf="center">
                        <Image src={IMG_SRC} height={90} />
                    </Box>

                    <Box>
                        <Typography variant="h3" textAlign="center" mb={3}>
                            {title}
                        </Typography>
                        <Typography variant="h4" textAlign="center" mb={3}>
                            {message}
                        </Typography>
                    </Box>
                    <Box className={classes.hideElement}>
                        <Typography variant="h3" textAlign="center">
                            {titleBottomPartOne1}
                        </Typography>
                        <Typography
                            variant="h3"
                            textAlign="center"
                            mb={3}
                            color="#0EA875"
                        >
                            {titleBottomPartOne2}
                        </Typography>
                    </Box>
                    {!productStore.utilityRate?.rateSummary ? (
                        <Typography variant="h3" textAlign="center" mb={3}>
                            <Loader size="large" center />
                        </Typography>
                    ) : (
                        <>
                            <Box flexGrow={1}>
                                <Typography
                                    variant="subtitle2"
                                    color="grey.700"
                                    fontWeight={700}
                                    marginX={2}
                                    mb={1}
                                >
                                    Here&lsquo;s how it works.
                                </Typography>
                                {options.map((item) => (
                                    <RichListItem {...item} key={item.title} />
                                ))}
                            </Box>

                            <Box flexGrow={1} className={classes.hideElement}>
                                <Typography
                                    variant="subtitle2"
                                    color="grey.700"
                                    fontWeight={700}
                                    marginX={2}
                                    mb={1}
                                >
                                    What customers are saying
                                </Typography>
                                {userComments.map((user) => (
                                    <Box className={classes.userComment}>
                                        <Box className={classes.boxTop}>
                                            <Box className={classes.stars}>
                                                {renderStars(user.stars)}
                                            </Box>
                                            <Typography
                                                color="#6E7385"
                                                fontSize="10px"
                                                marginX={2}
                                                mb={1}
                                            >
                                                {user.date}
                                            </Typography>
                                        </Box>
                                        <Typography>{user.comment}</Typography>
                                        <Typography
                                            fontWeight="900"
                                            color="black.700"
                                            mt="10px"
                                        >
                                            {user.user}
                                        </Typography>
                                    </Box>
                                ))}
                                <Box className={classes.logoContainer}>
                                    <img
                                        className={classes.bbLogo}
                                        src={bbbLogo}
                                        alt="logo"
                                    />
                                    <img
                                        className={classes.trustedLogo}
                                        src={trustedLogo}
                                        alt="logo"
                                    />
                                </Box>
                            </Box>

                            <Box>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    disabled={!canContinue}
                                    endIcon={loading && <Loader />}
                                    onClick={handleSubmit}
                                >
                                    HELP ME SAVE
                                </Button>
                            </Box>
                        </>
                    )}
                </Stack>
            </ModalBox>
        </>
    );
});
