import { useEffect } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { useInstance } from "react-ioc";

import { UtilityStore } from "@store/auth/utilities.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import Message, { MessageProps } from "@components/prototypes/Message";
import { getImageUrl } from "@components/elements/Image/collection";
import { trackEvent } from "@model/utils/tracking";
import Image from "@components/elements/Image/Image";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";

const getMessage = (
    utilityName = "utility",
    variant = "default",
): MessageProps => {
    if (variant === "skip") {
        return {
            imageSrc: getImageUrl("high_five.png"),
            title: "Great choice!",
            message: ` Next up we'll present you with our top pick based on your preferences and the best rates in ${utilityName}.`,
            buttonText: "See my savings options",
        };
    }

    return {
        imageSrc: getImageUrl("success.png"),
        title: "You're all set!",
        message: `While we are analyzing your rate, check out our top pick based on your preferences and the best rates in ${utilityName}`,
        buttonText: "See what's available now",
    };
};

const SuccessPage: React.FC = observer(() => {
    const { state } = useLocation();
    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "success", {
            page_name: "success",
            name: "success",
            url: window.location.href,
        });
        if (state?.messageVariant !== "skip") {
            trackEvent("Utility Linked");
        }
    }, []);

    const navigate = useNavigate();
    const { utility, utilityShortName } = useInstance(UtilityStore);

    const handleSubmit = () => {
        UseRudderStack.INSTANCE.trackEvent("success-button_click-continue");
        navigate("/dashboard");
    };

    if (!utility) {
        return null;
    }

    const content = getMessage(utilityShortName, state?.messageVariant);

    return (
        <ModalBox flexBasis="100%" p={4}>
            <Message {...content} onSubmit={handleSubmit} />
            <Image
                src="https://www.dianomi.com/pixeltrack.pl?c=9972"
                height={1}
                width={1}
            />
        </ModalBox>
    );
});

export default SuccessPage;
