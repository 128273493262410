import {
    Auth,
    getAuth,
    fetchSignInMethodsForEmail,
    signInWithEmailLink,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { firebaseApp } from "../firebase";

class FirebaseAuthService {
    auth: Auth;

    constructor() {
        this.auth = getAuth(firebaseApp);
    }

    checkExistingUser = async (email: string) =>
        fetchSignInMethodsForEmail(this.auth, email);

    loginWithEmailLink = (email: string, link: string) =>
        signInWithEmailLink(this.auth, email, link);

    login = (login: string, pass: string) =>
        signInWithEmailAndPassword(this.auth, login, pass);

    logout = () => this.auth.signOut();
}

export default FirebaseAuthService;
