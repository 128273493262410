import * as React from "react";
import styled from "@mui/material/styles/styled";

import Box from "@components/fondation/Box/Box";
import { r } from "@theme/utils";
import Image, { ImageProps } from "@components/elements/Image/Image";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import AppBar from "@components/elements/HeaderBar/AppBar";
import { getImageUrl } from "@components/elements/Image/collection";
import { isSafari } from "@root/lib/browserUtils";

const BG_IMAGE_LEFT = getImageUrl("hero_03.png");
const BG_IMAGE_RIGHT = getImageUrl("hero_04.png");

const RootContainer = styled(Box, { target: "BaseLayout-root" })({
    display: "flex",
    flexDirection: "column",
    height: isSafari ? "-webkit-fill-available" : "100vh",
});

const BodyContainer = styled(Box, { target: "BaseLayout-body" })(
    ({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        flex: "1 1 100%",
        position: "relative",
        marginTop: r(70),
        overflow: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: r(3 + 4 * 2),
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.primary.main,
            height: r(3),
            border: "solid transparent",
            borderWidth: r(4),
            borderRadius: "6px",
            backgroundClip: "content-box",
        },

        [theme.breakpoints.down("lg")]: {
            marginTop: r(44),
        },
    }),
);

const ContainerWithBg = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 100%",
    position: "relative",
});

const BGImage = styled(Image)<ImageProps>({
    position: "absolute",
    bottom: 120,
    zIndex: -1,

    "&.left": {
        left: 0,
        transform: "translateX(-50%)",
    },

    "&.right": {
        right: 0,
        transform: "translateX(50%)",
    },
});

interface BaseLayoutProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    children?: React.ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = React.memo(
    ({ header, footer, children }) => {
        const isTablet = useMediaQuery((t) => t.breakpoints.up("md"));

        return (
            <RootContainer>
                <AppBar position="fixed" color="primary" elevation={1}>
                    {header}
                </AppBar>

                <BodyContainer>
                    <ContainerWithBg>
                        {isTablet && (
                            <>
                                <BGImage
                                    src={BG_IMAGE_LEFT}
                                    width={320}
                                    className="left"
                                />
                                <BGImage
                                    src={BG_IMAGE_RIGHT}
                                    width={320}
                                    className="right"
                                />
                            </>
                        )}
                        {children}
                    </ContainerWithBg>
                </BodyContainer>

                <AppBar
                    position="fixed"
                    sx={{ top: "auto", bottom: 0 }}
                    color="secondary"
                    elevation={1}
                >
                    {footer}
                </AppBar>
            </RootContainer>
        );
    },
);

export default BaseLayout;
