/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as yup from "yup";
import { FormConfig } from "./types";

export const getAccountNumberValidator = (
    config: FormConfig = {},
): yup.StringSchema => {
    const accountNumberName = config?.accountNumLabel || "Account Number";
    const accountNumberValidator = yup
        .string()
        .required(`${accountNumberName} is required`);

    return !config?.accountNumFormat
        ? accountNumberValidator
        : accountNumberValidator.matches(
              new RegExp(config?.accountNumFormat),
              `Please add a valid ${accountNumberName}`,
          );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getValidationSchema = (config: FormConfig = {}) =>
    yup.object({
        contactName: yup.string().required(`Full Name is required`),

        accountNumber: getAccountNumberValidator(config),

        nameKey: yup.string().when({
            is: () => !!config.showNameKey,
            then: yup
                .string()
                .required("Name Key is required")
                .matches(
                    new RegExp(config.nameKeyFormat!),
                    "Please add a valid Name Key",
                ),
            otherwise: yup.string().notRequired(),
        }),

        referenceNum: yup.string().when({
            is: () => !!config.showReferenceNum,
            then: yup
                .string()
                .required("Reference Number is required")
                .matches(
                    new RegExp(config.referenceNumFormat!),
                    "Please add a valid Reference Number",
                ),
            otherwise: yup.string().notRequired(),
        }),

        meterNum: yup.string().when({
            is: () => !!config.showMeterNum,
            then: yup
                .string()
                .required("Meter Number is required")
                .matches(
                    new RegExp(config.meterNumFormat!),
                    "Please add a valid Meter Number",
                ),
            otherwise: yup.string().notRequired(),
        }),

        userRate: yup.number().when({
            is: () =>
                config.isListedAsSupplier != null && !config.isListedAsSupplier,
            then: yup
                .number()
                .typeError("Supply Rate must be a number")
                .positive()
                .min(0.00001, "Supply Rate must be greater than zero")
                .max(0.99999, "Supply Rate is in dollars per kWh")
                .required("Supply Rate is required"),
            otherwise: yup
                .number()
                .nullable()
                .transform(() => null),
        }),
    });

export type EnterBillFormData = yup.InferType<
    ReturnType<typeof getValidationSchema>
>;

export const helpersMapper: Partial<
    Record<keyof EnterBillFormData, Record<string, keyof FormConfig>>
> = {
    accountNumber: {
        filedNameKey: "accountNumFormatHelp",
        labelNameKey: "accountNumLabel",
    },
    meterNum: { filedNameKey: "meterNumFormatHelp" },
    referenceNum: { filedNameKey: "referenceNumFormatHelp" },
    nameKey: { filedNameKey: "nameKeyFormatHelp" },
};

export const helpersDefaults: Partial<
    Record<
        keyof EnterBillFormData,
        {
            defaultLabel: string;
            defaultMessage?: string;
        }
    >
> = {
    contactName: {
        defaultLabel: "Full Name on account",
        defaultMessage: "This is the Full Name exactly as it is on your bill.",
    },
    accountNumber: { defaultLabel: "Account Number" },
    nameKey: { defaultLabel: "Name Key" },
    referenceNum: { defaultLabel: "Reference Number" },
    meterNum: { defaultLabel: "Meter Number" },
    userRate: {
        defaultLabel: "Supply Rate ($/kWh)",
        defaultMessage:
            "The Supply Rate from your bill, also known as the Price to Compare or PTC.",
    },
};

export const getFiledDefaultLabel = (
    filedName: keyof EnterBillFormData,
): string => helpersDefaults[filedName]?.defaultLabel ?? "Unknown";

export const getFiledDefaultMessage = (
    filedName: keyof EnterBillFormData,
): string => helpersDefaults[filedName]?.defaultMessage ?? "No message found";
