import FormControl from "@mui/material/FormControl";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import { emailValidator } from "@components/modules/forms/common/validations";
import Stack from "@components/fondation/Stack/Stack";
import FormTextField from "../common/FormTextField";

const validationSchema: yup.BaseSchema = yup.object({
    email: emailValidator
        .clone()
        .required("Please enter you email address")
        .default(""),
});

export type FormData = yup.InferType<typeof validationSchema>;

const defaultInitialValues: FormData = validationSchema.getDefault();

export interface ClassicSignInFormProps {
    initialValues?: FormData;
    loading?: boolean;
    submitLabel?: string;
    onSubmit: (form: FormData) => void;
}

function EmailForm({
    initialValues = defaultInitialValues,
    loading,
    submitLabel = "Submit",
    onSubmit,
}: ClassicSignInFormProps): JSX.Element {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (form) => onSubmit(validationSchema.cast(form)),
    });

    return (
        <FormikProvider value={formik}>
            <FormControl
                component="form"
                fullWidth
                onSubmit={formik.handleSubmit}
            >
                <Stack spacing={5}>
                    <FormTextField
                        fullWidth
                        name="email"
                        type="email"
                        label="Email"
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={loading}
                        endIcon={loading && <Loader />}
                    >
                        {submitLabel}
                    </Button>
                </Stack>
            </FormControl>
        </FormikProvider>
    );
}

export default EmailForm;
