import type { ReactNode } from "react";
import Grid from "@components/fondation/Grid/Grid";
import FormControl from "@mui/material/FormControl";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import FormTextField from "../common/FormTextField";

export interface FormData {
    username: string;
    password: string;
}

export const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
});

const defaultInitialValues: FormData = { username: "", password: "" };

export interface ContactInfoFormProps {
    initialValues?: Partial<FormData>;
    loading?: boolean;
    children?: ReactNode;
    onSubmit: (form: FormData) => void;
}

function ConnectUtilityForm({
    initialValues = {},
    loading,
    children,
    onSubmit,
}: ContactInfoFormProps): JSX.Element {
    const formik = useFormik({
        initialValues: { ...defaultInitialValues, ...initialValues },
        validationSchema,
        initialErrors: { username: "" },
        validateOnChange: true,
        onSubmit,
    });

    return (
        <FormikProvider value={formik}>
            <FormControl component="form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {!!children && (
                                <Grid item xs={12}>
                                    {children}
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <FormTextField
                                    name="username"
                                    label="Username or email"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormTextField
                                    type="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={loading || !formik.isValid}
                            endIcon={loading && <Loader />}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
        </FormikProvider>
    );
}

export default ConnectUtilityForm;
