import { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Grid from "@components/fondation/Grid/Grid";
import FormControl from "@mui/material/FormControl";

import Loader from "@components/elements/Loader/Loader";
import Button from "@components/elements/Button/Button";
import TextField from "@components/elements/TextField/TextField";
import { businessNameValidator } from "@components/modules/forms/common/validations";
import Autocomplete from "@components/elements/DropDown/Autocomplete";
import Typography from "@components/fondation/Typography/Typography";
import Stack from "@components/fondation/Stack/Stack";

export type BusinessFormData = {
    name: string;
    averageElectricBillSize?: string;
};

export type OrganizationSizes = {
    label: string;
    value: string;
};

const defaultInitialValues: BusinessFormData = {
    name: "",
    averageElectricBillSize: "",
};

export const validationSchema = yup.object().shape({
    name: businessNameValidator
        .clone()
        .required("Please enter the business name"),
    // businessaverageElectricBillSize: emailValidator.clone(),
});

interface BusinessFormProps {
    initialValues?: Partial<BusinessFormData>;
    loading?: boolean;
    onSubmit?: (form: BusinessFormData) => void;
    children?: React.ReactNode;
}

export function BusinessForm({
    initialValues = undefined,
    onSubmit = () => undefined,
    loading = false,
    children,
}: BusinessFormProps): JSX.Element {
    const formik = useFormik<BusinessFormData>({
        initialValues: { ...defaultInitialValues, ...initialValues },
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    const submitButtonDisabled = !formik.isValid || loading;

    const businessOrgSizes: OrganizationSizes[] = [
        { label: "Small Business ($0 - $500)", value: "small" },
        { label: "Medium Business ($500 - $2000)", value: "medium" },
        { label: "Large Business ($2000 - $10,000)", value: "large" },
        { label: "Very Large Business ($10,000+)", value: "very_large" },
        { label: "I don't know", value: "dont_know" },
    ];

    return (
        <FormControl component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={7}>
                <Grid item xs={12}>
                    {children}
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={4}>
                        <Typography variant="h4">Name of Business</Typography>
                        <TextField
                            name="name"
                            error={formik.touched.name && !!formik.errors.name}
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            fullWidth
                            label="Enter the name of your business"
                            type="string"
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Stack spacing={4}>
                        <Typography variant="h4">
                            Average Electric Bill
                        </Typography>
                        <Autocomplete
                            getOptionLabel={(option: OrganizationSizes) =>
                                option.label
                            }
                            clearText="Clear"
                            options={businessOrgSizes}
                            id="averageElectricBillSize"
                            onChange={(e, value) =>
                                formik.setFieldValue(
                                    "averageElectricBillSize",
                                    value?.value,
                                )
                            }
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    multiline
                                    fullWidth
                                    label="What is your average bill"
                                />
                            )}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} mt={6}>
                    <Stack spacing={5}>
                        <Button
                            disabled={submitButtonDisabled}
                            fullWidth
                            variant="contained"
                            size="large"
                            type="submit"
                            endIcon={loading && <Loader />}
                        >
                            Continue
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </FormControl>
    );
}

export default BusinessForm;
