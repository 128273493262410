import type { ReactNode } from "react";
import styled from "@mui/material/styles/styled";
import { r } from "@theme/utils";
import Typography from "@components/fondation/Typography/Typography";
import { alpha } from "@mui/system/colorManipulator";

export type Color =
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "mustard"
    | "teal"
    | "rust"
    | "green";

interface ContainerProps {
    color?: Color;
}

const Card = styled("div")<ContainerProps>(({ theme, color = "primary" }) => {
    const mainColor = theme.palette[color].main;
    return {
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid transparent",
        borderColor: alpha(mainColor, 0.2),
        backgroundColor: alpha(mainColor, 0.1),
        color: mainColor,
        padding: r(10, 14),
    };
});

interface LabelCardProps extends ContainerProps {
    label: string;
    children?: ReactNode;
}

export function LabelCard({
    label,
    children,
    ...rest
}: LabelCardProps): JSX.Element {
    return (
        <Card {...rest}>
            <Typography variant="caption" fontSize={r(10)}>
                {label}
            </Typography>
            <Typography variant="h4" color="inherit">
                {children}
            </Typography>
        </Card>
    );
}

export default LabelCard;
