import type { ReactNode } from "react";
import Grid from "@components/fondation/Grid/Grid";
import Typography from "@components/fondation/Typography/Typography";
import Image from "@components/elements/Image/Image";
import Button from "@components/elements/Button/Button";

export interface MessageProps {
    imageSrc: string;
    title: ReactNode;
    message: ReactNode;
    buttonText?: string;
    onSubmit?: () => void;
}

export function Message({
    imageSrc,
    title,
    message,
    buttonText = "Submit",
    onSubmit,
}: MessageProps): JSX.Element {
    return (
        <Grid container spacing={6} justifyContent="center" marginY="auto">
            <Grid item xs={12} textAlign="center">
                <Image src={imageSrc} height={120} />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" textAlign="center" mb={2.5}>
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    sx={(t) => ({
                        color: t.palette.grey[800],
                    })}
                    textAlign="center"
                >
                    {message}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={onSubmit}
                >
                    {buttonText}
                </Button>
            </Grid>
        </Grid>
    );
}

export default Message;
