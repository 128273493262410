import { useEffect, type ReactNode } from "react";
import * as React from "react";
import { FormikProvider, useFormik } from "formik";
import merge from "lodash/merge";
import * as yup from "yup";

import Button from "@components/elements/Button/Button";
import Loader from "@components/elements/Loader/Loader";
import { ButtonProps } from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import Stack from "@components/fondation/Stack/Stack";
import Box from "@components/fondation/Box/Box";

import FormTextField from "@components/modules/forms/common/FormTextField";
import { signUpValidationScheme } from "@components/modules/forms/common/validations";
import { useTheme } from "@mui/system";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import AgreementFooter from "@components/prototypes/Footer/AgreementFooter";
import PhoneNumberTextField from "./components/PhoneNumberTextField";
import { SignUpFormData } from "../common/types";

const defaultInitialValues: SignUpFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
};

const validationSchema: yup.BaseSchema = signUpValidationScheme;

export interface ContactInfoFormProps {
    initialValues?: Partial<SignUpFormData>;
    loading?: boolean;
    children?: ReactNode;
    submitButtonProps?: ButtonProps;
    onSubmit: (form: SignUpFormData) => void;
}

function ContactInfoForm({
    initialValues,
    loading,
    children,
    submitButtonProps,
    onSubmit,
}: ContactInfoFormProps): JSX.Element {
    const theme = useTheme();
    // Override default values with the ones passed from props.
    const formikInitialValues = merge(defaultInitialValues, initialValues);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit: (form) => {
            UseRudderStack.INSTANCE.trackEvent(
                "about_you-button_click-continue",
                form,
            );
            onSubmit(validationSchema.cast(form));
        },
    });

    useEffect(() => {
        formik.validateForm();
    }, []);

    const submitButtonDisabled = !formik.isValid || loading;

    return (
        <FormikProvider value={formik}>
            <Stack
                minWidth="100%"
                spacing={3}
                component="form"
                onSubmit={formik.handleSubmit}
            >
                {children != null && <Box>{children}</Box>}

                <Typography variant="body1" mb={4}>
                    Sign up and start saving money on your utility bill. We’ll
                    never sell your personal info.
                </Typography>

                <FormTextField
                    fullWidth
                    name="firstName"
                    label="First Name"
                    onBlur={(event) => {
                        UseRudderStack.INSTANCE.trackEvent(
                            "about_you-form_fill-first_name",
                            { value: event.target.value },
                        );
                    }}
                />

                <FormTextField
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    onBlur={(event) => {
                        UseRudderStack.INSTANCE.trackEvent(
                            "about_you-form_fill-last_name",
                            { value: event.target.value },
                        );
                    }}
                />

                <FormTextField
                    fullWidth
                    name="email"
                    type="email"
                    label="Email"
                    onBlur={(event) => {
                        UseRudderStack.INSTANCE.trackEvent(
                            "about_you-form_fill-email",
                            { value: event.target.value },
                        );
                    }}
                />

                <PhoneNumberTextField
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    onBlur={(event) => {
                        UseRudderStack.INSTANCE.trackEvent(
                            "about_you-form_fill-phone_number",
                            { value: event.target.value },
                        );
                    }}
                />
                <Typography variant="body1" color="grey.800" mb={2.5}>
                    For your security, we&lsquo;ll email or text you a code
                    whenever you want to access your account.
                </Typography>

                <Grid item xs={12}>
                    <AgreementFooter />
                </Grid>

                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{ marginTop: `${theme.spacing(4)} !important` }}
                    disabled={submitButtonDisabled}
                    endIcon={loading && <Loader />}
                    {...submitButtonProps}
                >
                    {submitButtonProps?.children ?? "Submit"}
                </Button>
            </Stack>
        </FormikProvider>
    );
}

export default ContactInfoForm;
