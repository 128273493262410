export const loaderPart1Path =
    "M216.486532,111.902341C229.365547,70.792672,179.487451,42.832059,150.236384,73.332216C121.487451,43.832059,71.06108,71.775958,83.702223,111.957673C42.273012,121.558568,42.766412,178.921364,83.702223,188.326907C71.461338,229.216193,121.260649,258.043536,150.165381,226.822375C179.243823,258.014187,229.144898,229.197588,216.486533,188.326907C258.306664,178.594378,258.391521,121.488898,216.486533,111.902341";
export const loaderPart2Path =
    "M83.285854,111.902341L216.486533,188.401565C229.116132,229.606988,179.227977,257.888239,150.236384,226.897033L150.236384,73.406874C179.482011,42.953256,229.125267,71.316584,216.486533,111.864916L83.702223,188.401565C42.346945,179.393048,42.358839,121.351836,83.671412,111.902341";
export const loaderPart3Path =
    "M150.000001,226.78495C121.076109,257.694179,71.074583,229.267475,83.702223,188.289482L216.486533,111.752833C257.45019,121.151704,258.029758,179.060265,216.486533,188.289482L83.702223,111.390244C71.350681,70.766069,121.432079,42.674482,150,73.294791Q150.305174,187.858028,150.165382,226.78495";

export const animationFunction1 =
    "M0,0 L0.117,0.167 0.167,0.167 0.283,0.333 0.333,0.333 0.450,0.500 0.500,0.500 0.617,0.667 0.667,0.667 0.783,0.833 0.833,0.833 0.950,1 1,1";
export const animationFunction2 =
    "M0,0 L0.117,0.197 0.167,0.197 0.283,0.333 0.333,0.333 0.450,0.530 0.500,0.530 0.617,0.667 0.667,0.667 0.783,0.863 0.833,0.863 0.950,1.000 1.000,1.000";
export const animationFunction3 =
    "M0,0 L0.117,0.137 0.167,0.137 0.283,0.333 0.333,0.333 0.450,0.470 0.500,0.470 0.617,0.667 0.667,0.667 0.783,0.803 0.833,0.803 0.950,1.000 1.000,1.000";
