import { useMemo, FC } from "react";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";

import Typography from "@components/fondation/Typography/Typography";
import Box from "@components/fondation/Box/Box";
import { RateOffer } from "@store/product/RateOffer";
import { UtilityStore } from "@store/auth/utilities.store";
import { isRenewable } from "@store/selectors/plan.selectors";
import { useHasBetterRateAvailable } from "@store/selectors/rates.selectors";
import { Messages } from "@components/prototypes/dashboard/Messages";
import { serviceStatusOneOf } from "@store/selectors/service.selectors";

import BenefitsList from "./BenefitsList";
import HowItWorksList from "./HowItWorksList";
import ProductFAQList from "./ProductFAQList";
import { RateComparison } from "./RateComparison";
import ReferralBox from "./ReferralBox";

const sectionMargin = { md: 10, xs: 7.5 };

export const Product: FC = observer(() => {
    const [offer, utilityStore] = useInstances(RateOffer, UtilityStore);

    const utilityName = utilityStore.utilityShortName;

    const hasBetterRate = useHasBetterRateAvailable(offer.productStore, offer);
    const isReview = serviceStatusOneOf(offer.productStore)("review");
    const isUnlinked = serviceStatusOneOf(offer.productStore)("unlinked");

    const displayText = useMemo(() => {
        if (isReview) {
            return `We are in the process of switching you to the best rate for ${utilityName} customers.`;
        }

        if (isUnlinked) {
            return `Complete your profile, so we can find the best rate for you.`;
        }

        if (hasBetterRate) {
            return `We have rates available for ${utilityName} customers.`;
        }

        return "We're monitoring the market and actively searching for better rates.";
    }, [isReview, hasBetterRate]);

    return (
        <Box>
            <Typography variant="h2" mb={5}>
                {isRenewable(offer.productStore)
                    ? "Your Renewable Options"
                    : "Your Savings Options"}
            </Typography>
            <Typography variant="body1" marginY={5}>
                {displayText}
            </Typography>

            <Messages mb={sectionMargin} />

            <Box mb={sectionMargin}>
                <RateComparison />
            </Box>

            <Box mb={sectionMargin}>
                <ReferralBox />
            </Box>

            <Box marginY={sectionMargin}>
                <Typography variant="h3" mb={4}>
                    Arbor Benefits
                </Typography>
                <BenefitsList />
            </Box>

            <Box marginY={sectionMargin}>
                <Typography variant="h3" mb={4}>
                    How It Works
                </Typography>
                <HowItWorksList />
            </Box>

            <Box marginY={sectionMargin}>
                <Typography variant="h3" mb={4}>
                    FAQs
                </Typography>
                <ProductFAQList />
            </Box>
        </Box>
    );
});
