import * as React from "react";
import { PairRowData } from "@components/elements/Table/SimpleTable";
import {
    shortMoneyFormatter,
    percentFormatter,
    rateFormatter,
} from "@root/lib/numberUtils";
import { format } from "date-fns";
import { DATE_FORMAT } from "@components/modules/forms/common/validations";
import { RateOffer } from "@store/product/RateOffer";
import { hasBetterRateAvailable } from "@store/selectors/rates.selectors";
import { isRenewable } from "@store/selectors/plan.selectors";
import { serviceStatusOneOf } from "@store/selectors/service.selectors";
import { isAutopilot } from "@store/selectors/user.selectors";

export interface SavingsHeader {
    label: string;
    title: string;
    description: React.ReactNode;
}

export function renderDate(date?: Date | null, alt = " - "): string {
    if (date == null) {
        return alt;
    }
    return format(date, DATE_FORMAT);
}

export function renderRate(rate?: number | null, alt = " - "): string {
    if (rate == null) {
        return alt;
    }
    return rateFormatter.format(rate);
}

export function makeRateTable(offer: RateOffer): PairRowData[] {
    const compareRateDisplay =
        offer.baseRate > offer.avgRate ? "Default Base Rate" : "Average Rate";
    const bestComparisonRate = Math.max(offer.avgRate, offer.baseRate);

    const renewable = isRenewable(offer.productStore);

    const commonRows: Array<PairRowData> = [
        {
            label: "Recommended Rate",
            value: (
                <>
                    {renderRate(offer.recommendedRate)} <sub>/kWh</sub>
                </>
            ),
        },
        {
            label: "Guaranteed Term",
            value: (
                <>
                    {offer.guaranteedTerm} <sub>months</sub>
                </>
            ),
        },
    ];

    if (renewable) {
        return [
            ...commonRows,
            {
                label: "Renewables",
                value: <>{percentFormatter.format(1)}</>,
            },
        ];
    }

    const hasBetterRate = hasBetterRateAvailable(offer.productStore, offer);

    const userRateDisplay = {
        label: "Your Current Rate",
        value: (
            <>
                {renderRate(offer.userRate)} <sub>/kWh</sub>
            </>
        ),
    };

    const serviceStatus = serviceStatusOneOf(offer.productStore);

    if (
        serviceStatus("searching", "review") ||
        (serviceStatus("user-approving") && hasBetterRate)
    ) {
        return [userRateDisplay, ...commonRows];
    }

    if (hasBetterRate) {
        return [
            {
                label: compareRateDisplay,
                value: (
                    <>
                        <s>{renderRate(bestComparisonRate)}</s> <sub>/kWh</sub>
                    </>
                ),
            },
            ...commonRows,
        ];
    }

    return [userRateDisplay];
}

export const getSavingsHeader = (offer: RateOffer): SavingsHeader => {
    const renewable = isRenewable(offer.productStore);
    const serviceStatus = serviceStatusOneOf(offer.productStore);
    const hasBetterRate = hasBetterRateAvailable(offer.productStore, offer);

    const defaultLabel = renewable
        ? "Best Renewable Option"
        : "Best Savings Option";
    const rateToCompare = offer.baseRate > offer.avgRate ? "base" : "avg";
    const bestComparisonSavings = Math.max(
        offer.savingsToAvg,
        offer.savingsToBase,
    );
    const bestComparisonRate = Math.max(offer.avgRate, offer.baseRate);
    const bestComparisonPercent = Math.max(
        offer.savingsToAvgPercent,
        offer.savingsToBasePercent,
    );

    if (renewable) {
        return serviceStatus("review")
            ? {
                  label: defaultLabel,
                  title: "Get ready for renewable energy",
                  description: "Thanks for doing your part to help the planet!",
              }
            : {
                  label: defaultLabel,
                  title: "Switch to 100% renewable energy",
                  description: (
                      <>
                          Our best renewable rate of{" "}
                          {renderRate(offer.recommendedRate)} is guaranteed for{" "}
                          {offer.guaranteedTerm} months, and is{" "}
                          <b>
                              {percentFormatter.format(
                                  offer.savingsToAvgPercent,
                              )}{" "}
                              lower
                          </b>{" "}
                          than the average renewable rate of{" "}
                          {renderRate(offer.avgRate)}.
                      </>
                  ),
              };
    }

    // savings
    if (serviceStatus("review")) {
        return {
            label: defaultLabel,
            title: "Get ready to save",
            description: (
                <>
                    Your new rate is guaranteed for {offer.guaranteedTerm}{" "}
                    months and could
                    <b>
                        {" "}
                        save you up to{" "}
                        {shortMoneyFormatter.format(offer.savingsToCurrent)}
                    </b>{" "}
                    over that time.
                </>
            ),
        };
    }

    if (serviceStatus("user-approving") && hasBetterRate) {
        return {
            label: defaultLabel,
            title: "Switch to a lower rate",
            description: (
                <>
                    Our best rate is{" "}
                    <b>
                        {percentFormatter.format(offer.savingsToCurrentPercent)}{" "}
                        lower
                    </b>{" "}
                    than your current rate of {renderRate(offer.userRate)}. It
                    is guaranteed for {offer.guaranteedTerm} months and could
                    <b>
                        {" "}
                        save you up to{" "}
                        {shortMoneyFormatter.format(offer.savingsToCurrent)}
                    </b>{" "}
                    over that time.
                </>
            ),
        };
    }

    if (serviceStatus("searching")) {
        if (offer.isLockRecommended) {
            return {
                label: defaultLabel,
                title: "Protect against rising rates",
                description: (
                    <>
                        Your current rate is only{" "}
                        {percentFormatter.format(
                            Math.abs(offer.savingsToCurrentPercent),
                        )}{" "}
                        less than our best rate, but it might not that way be
                        for long. Our best rate is{" "}
                        <b>guaranteed for {offer.guaranteedTerm} months</b> and
                        could save you money if market rates rise.
                    </>
                ),
            };
        }

        if (hasBetterRate) {
            return {
                label: defaultLabel,
                title: "Switch to a lower rate",
                description: (
                    <>
                        Our best rate is{" "}
                        <b>
                            {percentFormatter.format(
                                offer.savingsToCurrentPercent,
                            )}{" "}
                            lower
                        </b>{" "}
                        than your current rate of {renderRate(offer.userRate)}.
                        It is guaranteed for {offer.guaranteedTerm} months and
                        could
                        <b>
                            {" "}
                            save you up to{" "}
                            {shortMoneyFormatter.format(offer.savingsToCurrent)}
                        </b>{" "}
                        over that time.
                    </>
                ),
            };
        }
    }

    return {
        label: defaultLabel,
        ...(hasBetterRate
            ? {
                  title: "Lowest Fixed Rate",
                  description: (
                      <>
                          Our best rate is{" "}
                          <b>
                              {percentFormatter.format(bestComparisonPercent)}{" "}
                              lower
                          </b>{" "}
                          than the{" "}
                          {rateToCompare === "base"
                              ? "default base"
                              : "average"}{" "}
                          rate of {renderRate(bestComparisonRate)}. It is
                          guaranteed for for {offer.guaranteedTerm} months and
                          could
                          <b>
                              {" "}
                              save you up to{" "}
                              {shortMoneyFormatter.format(
                                  bestComparisonSavings,
                              )}
                          </b>{" "}
                          compared to the{" "}
                          {rateToCompare === "base"
                              ? "default base rate"
                              : "average"}
                          .
                      </>
                  ),
              }
            : {
                  title: "You're on a great rate",
                  description: (
                      <>
                          It looks like your current rate is lower than the best
                          available market rate. We check daily for new rates,
                          and will let you know when we have an update.{" "}
                          {!isAutopilot(
                              {
                                  user: offer.productStore,
                              } as any, // this is objectively bad but the only access we have here without bigger refactor
                          ) &&
                              "If you still want our best available rate, follow the link below."}
                      </>
                  ),
              }),
    };
};
