import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useInstances } from "react-ioc";

import Typography from "@components/fondation/Typography/Typography";
import Grid from "@components/fondation/Grid/Grid";
import { RateOfferCard } from "@components/prototypes/dashboard/RateOfferCard";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import Box from "@components/fondation/Box/Box";
import { RateOffer } from "@store/product/RateOffer";
import Stack from "@components/fondation/Stack/Stack";
import ContentHeader from "@components/elements/Card/RatePlanCard/Header/ContentHeader";
import { SUPPORT_ROUTE } from "@model/constants/utilities/app";
import { getImageUrl } from "@components/elements/Image/collection";
import { navLinkRenderer } from "@components/elements/Markdown/utils";
import Markdown from "@components/elements/Markdown/Markdown";
import { UserStore } from "@store/auth/user.store";
import { isRenewable } from "@store/selectors/plan.selectors";
import { useHasBetterRateAvailable } from "@store/selectors/rates.selectors";
import { isAutopilot } from "@store/selectors/user.selectors";
import { serviceStatusOneOf } from "@store/selectors/service.selectors";
import ClimateAction from "./ClimateAction";
import { UnavailableRatePlan } from "../UserRate/UnavailableRatePlan";

const savingsHeaderContent = {
    title: "Rate Comparison",
    message: "Here is our best available rate compared with your current rate.",
};

const renewableHeaderContent = {
    title: "Your Clean Energy Impact",
    message:
        "Transitioning to cleaner sources of energy like wind and solar is critical in meeting the challenges posed by climate change. Arbor makes switching to renewable energy simple and affordable.",
};

export const RateComparison = observer(() => {
    const navigate = useNavigate();
    const [offer, user] = useInstances(RateOffer, UserStore);

    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));
    const isReview = serviceStatusOneOf(offer.productStore)("review");

    const isUnlinked = serviceStatusOneOf(offer.productStore)("unlinked");
    if (isUnlinked) {
        return null;
    }

    const headerContent = isRenewable(offer.productStore)
        ? renewableHeaderContent
        : savingsHeaderContent;

    const hasBetterRate = useHasBetterRateAvailable(offer.productStore, offer);

    const handleSwitch =
        isReview || isAutopilot(user)
            ? undefined
            : () => {
                  offer.productStore.initiateRateSwitch();
                  navigate("/switch");
              };

    const titleNode = hasBetterRate && (
        <Box>
            <Typography variant="h3" mb={3}>
                {headerContent.title}
            </Typography>
            <Typography variant="body1" color="grey.800">
                {headerContent.message}
            </Typography>
        </Box>
    );

    const detailsNode = isRenewable(offer.productStore) ? (
        <ClimateAction />
    ) : (
        !hasBetterRate && (
            <UnavailableRatePlan
                title="Arbor's got your back!"
                message="We checked all of the rates we have available and you're still on the best rate! For now, sit back and relax - Arbor will continue to monitor the market on your behalf and if we find a better rate, we'll let you know. Here's to a bright future 🌞"
                image={getImageUrl("flower.png")}
            />
        )
    );

    const ratePlanNode = hasBetterRate && (
        <RateOfferCard onSwitch={handleSwitch} />
    );

    const newRatePlan = isReview && (
        <ContentHeader status="SELECTED">
            <Markdown renderLink={navLinkRenderer}>
                {offer.quote?.approved
                    ? `We will notify you once we have processed your reservation, usually within 24 hours. If you have any questions, just reach out to [support](${SUPPORT_ROUTE}).`
                    : `Good news! We found you a better rate. We will notify you once we process your reservation, usually within 24 hours. If you have any questions or want to opt out of this rate, please contact [support](${SUPPORT_ROUTE}).`}
            </Markdown>
        </ContentHeader>
    );

    return isMobile ? (
        <Stack spacing={7.5}>
            <Stack spacing={4}>
                {newRatePlan}
                {ratePlanNode}
            </Stack>
            <Box>
                <Stack spacing={4}>
                    {detailsNode && titleNode}
                    {detailsNode}
                </Stack>
            </Box>
        </Stack>
    ) : (
        <Stack spacing={6}>
            {titleNode}
            {newRatePlan}
            <Box>
                <Grid container spacing={6}>
                    {detailsNode && (
                        <Grid item lg={12}>
                            {detailsNode}
                        </Grid>
                    )}
                    {ratePlanNode && (
                        <Grid item lg={6}>
                            {ratePlanNode}
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Stack>
    );
});
