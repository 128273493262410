import * as React from "react";
import { observer } from "mobx-react-lite";
import { Outlet, RouteProps } from "react-router-dom";
import { useInstance } from "react-ioc";

import { UtilityStore } from "@store/auth/utilities.store";
import LoadingLayout from "@components/modules/layouts/LoadingOutlet";

const UtilityOutlet: React.FC<RouteProps> = observer(() => {
    const utilityStore = useInstance(UtilityStore);

    const isLoading = utilityStore.utility == null && !utilityStore.error;

    return (
        <LoadingLayout loading={isLoading} error={utilityStore.error}>
            <Outlet />
        </LoadingLayout>
    );
});

export default UtilityOutlet;
