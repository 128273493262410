import { useEffect, useMemo } from "react";
import * as React from "react";
import { useInstances } from "react-ioc";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FormikHelpers } from "formik";

import SessionStore from "@store/auth/session.store";
import { UserStore } from "@store/auth/user.store";
import { UtilityStore } from "@store/auth/utilities.store";
import { AddressFormData } from "@components/modules/forms/auth/AddressForm/types";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import AddressForm from "@components/modules/forms/auth/AddressForm/AddressForm";
import Stack from "@components/fondation/Stack/Stack";
import NavLink from "@components/elements/NavLink/NavLink";
import { SUPPORT_ROUTE } from "@model/constants/utilities/app";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import AboutYouHeader from "./AboutYouHeader";

const TITLE = "Enter your address";
const MESSAGE = `Your address impacts the energy plans we can find. Please contact [support](${SUPPORT_ROUTE}) if you need help.`;

export const editAddressErrorCodeToMessage = (
    code: string,
    utilityName?: string,
): React.ReactNode => {
    if (code === "UNSUPPORTED_AREA") {
        return "Sorry, we're not able to service this area right now";
    }
    if (code === "UNAVAILABLE_ACTION") {
        return (
            <>
                This address is not serviced by{" "}
                {utilityName ?? "your selected utility"} <br />
                Please <NavLink to="/area">
                    select a new utility
                </NavLink> or{" "}
                <NavLink to="/account/address/move">
                    reach out to support
                </NavLink>{" "}
                to change your address
            </>
        );
    }
    return "We are having trouble, please try again later";
};

const AccountAddressPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sessionStore, userStore, utilityStore] = useInstances(
        SessionStore,
        UserStore,
        UtilityStore,
    );
    const utilityName = utilityStore.utilityShortName;

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "address", {
            page_name: "address",
            name: "address",
            url: window.location.href,
        });
    }, []);

    useEffect(() => {
        if (sessionStore.session?.signInLink) {
            sessionStore.clearSession();
        }
    }, []);

    const handleSubmit = async (
        form: AddressFormData,
        helpers: FormikHelpers<AddressFormData>,
        isGoogleAddress?: boolean,
    ) => {
        console.log(helpers, isGoogleAddress);
        const error = await userStore.updateAddress(form, isGoogleAddress);
        if (error) {
            console.log("received error", error);
            // log here
            return;
        }

        if (form.propertyClass === "commercial") {
            console.log("moving to commercial");
            navigate("/enrollment/about/business", {
                state: { backPath: location.pathname },
            });
            return;
        }

        // this does not get here
        console.log("moving to preferences");
        navigate("/enrollment/preferences");
    };

    const initialValues: Partial<AddressFormData> = useMemo(() => {
        const place = userStore.user?.place ?? null;
        return {
            postalCode: sessionStore.area?.postalCode,
            apartment: userStore.user?.apartment,
            owner: userStore.user?.property?.owner,
            propertyClass: userStore.user?.property?.class,
            ...place,
        };
    }, [sessionStore.area, userStore.user]);

    useEffect(() => {
        if (!userStore.isInit && !userStore.isLoading) {
            navigate("../register");
        }
    }, [userStore.isLoading]);

    const { error, isLoading } = userStore;

    return (
        <Stack>
            <AboutYouHeader title={TITLE} message={MESSAGE} />
            <AddressForm
                loading={isLoading}
                onSubmit={handleSubmit}
                initialValues={initialValues}
            >
                {!!error && (
                    <Alert severity="error">
                        <AlertTitle>Wrong address</AlertTitle>
                        {editAddressErrorCodeToMessage(error.code, utilityName)}
                    </Alert>
                )}
            </AddressForm>
        </Stack>
    );
});

export default AccountAddressPage;
