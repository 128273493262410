import { createTheme, darken, lighten } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { DATE_FORMAT } from "@components/modules/forms/common/validations";
import { r, sr } from "./utils";

export const white = "#FFFFFF";
export const black = "#000000";

export const palette = createPalette({
    mustard: {
        main: "#D6B943",
        dark: darken("#D6B943", 0.2),
        light: lighten("#D6B943", 0.2),
        contrastText: black,
    },
    teal: {
        main: "#2CB1C8",
        dark: darken("#2CB1C8", 0.2),
        light: lighten("#2CB1C8", 0.2),
        contrastText: white,
    },
    rust: {
        main: "#D88D48",
        dark: darken("#D88D48", 0.2),
        light: lighten("#D88D48", 0.2),
        contrastText: white,
    },
    green: {
        main: "#7BAE9D",
        dark: darken("#7BAE9D", 0.2),
        light: lighten("#7BAE9D", 0.2),
        contrastText: white,
    },
    primary: {
        main: "#0E0F19",
        dark: darken("#0E0F19", 0.2),
        light: lighten("#0E0F19", 0.2),
        contrastText: white,
    },
    secondary: {
        main: "#404350",
        dark: darken("#404350", 0.2),
        light: lighten("#404350", 0.2),
        contrastText: white,
    },
    success: {
        main: "#0EA875",
        dark: darken("#0EA875", 0.2),
        light: lighten("#0EA875", 0.2),
        contrastText: white,
    },
    warning: {
        main: "#ED8A2F",
        dark: darken("#ED8A2F", 0.2),
        light: lighten("#ED8A2F", 0.2),
        contrastText: white,
    },
    error: {
        main: "#F15C5C",
        dark: darken("#F15C5C", 0.2),
        light: lighten("#F15C5C", 0.2),
        contrastText: white,
    },
    info: {
        main: "#5C7DF1",
        dark: darken("#5C7DF1", 0.2),
        light: lighten("#5C7DF1", 0.2),
        contrastText: white,
    },
    grey: {
        50: "#F5F5F7",
        100: "#EEEEF1",
        150: "#E7E8EB",
        200: "#DBDFE6",
        300: "#ADB2BE",
        700: "#6E7385",
        800: "#404350",
        900: "#0E0F19",
    },
    background: {
        paper: "#F8F9F9",
    },
});

export const themeCommon = createTheme({
    palette,
    typography: {
        fontFamily: "inherit",
        h1: {
            fontSize: r(36),
            fontWeight: 700,
            letterSpacing: 0,
        },
        h2: {
            fontSize: r(30),
            lineHeight: "124%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        h3: {
            fontSize: r(26),
            lineHeight: "130%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        h4: {
            fontSize: r(18),
            lineHeight: "140%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        h5: {
            fontSize: r(16),
            lineHeight: "140%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        h6: {
            fontSize: r(14),
            lineHeight: "140%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        body1: {
            fontSize: r(16),
            lineHeight: "160%",
            fontWeight: 400,
            letterSpacing: 0,
        },
        body2: {
            fontSize: r(14),
            lineHeight: "140%",
            fontWeight: 400,
            letterSpacing: 0,
        },
        subtitle1: {
            fontSize: r(12),
            lineHeight: "160%",
            fontWeight: 700,
            letterSpacing: 0,
        },
        subtitle2: {
            fontSize: r(12),
            fontWeight: 400,
            textTransform: "uppercase",
            letterSpacing: 0.02,
        },
        caption: {
            fontSize: r(12),
            fontWeight: 400,
            letterSpacing: 0,
        },
    },
    shape: {
        borderRadius: 8,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 550,
            lg: 900,
            xl: 1200,
        },
    },
    spacing: (value: number) => sr(value * 4),
});

export const componentsOverrideTheme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                color: "grey.900",
            },
            styleOverrides: {
                root: {
                    "sup, sub": {
                        color: themeCommon.palette.grey[800],
                        lineHeight: "1.5em",
                        fontSize: ".8em",
                        fontWeight: 400,
                    },
                    sup: {
                        verticalAlign: "top",
                    },
                    sub: {
                        verticalAlign: "bottom",
                    },
                },
            },
        },

        MuiTextField: {
            defaultProps: {
                variant: "filled",
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ...(themeCommon.typography.subtitle1 ?? null),
                    fontWeight: 400,
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginLeft: "5px",
                    fontSize: themeCommon.typography.body1.fontSize,
                    color: palette.grey[300],
                    "&.Mui-focused": {
                        color: palette.grey[300],
                    },
                    "&.Mui-error": {
                        color: palette.error.main,
                    },
                    "&.Mui-disabled": {
                        color: palette.grey[200],
                    },
                },
            },
        },

        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    border: "1px solid",
                    borderColor: palette.grey[200],
                    backgroundColor: palette.common.white,
                    borderRadius: themeCommon.shape.borderRadius,
                    fontSize: themeCommon.typography.body1.fontSize,
                    paddingTop: r(3),
                    paddingRight: r(16),
                    paddingBottom: r(3),
                    paddingLeft: r(16),
                    minHeight: r(54),
                    ".MuiFilledInput-input": {
                        borderRadius: 0,
                        padding: 0,
                        paddingTop: r(22),
                        "&:-webkit-autofill": {
                            borderRadius: 0,
                        },
                    },
                    "&:hover": {
                        borderColor: palette.grey[300],
                        backgroundColor: palette.common.white,
                    },
                    "&.Mui-focused": {
                        borderColor: palette.primary.main,
                        backgroundColor: palette.common.white,
                    },
                    "&.Mui-error": {
                        borderColor: palette.error.main,
                    },
                    "&.Mui-disabled": {
                        color: palette.grey[300],
                        borderColor: palette.grey[50],
                        backgroundColor: palette.common.white,
                    },
                    ".MuiInputAdornment-root": {
                        color: palette.grey[300],
                        // marginTop: r(-9),
                    },
                },
            },
        },

        MuiDatePicker: {
            defaultProps: {
                disableMaskedInput: true,
                inputFormat: DATE_FORMAT,
                closeOnSelect: false,
            },
        },

        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: palette.green.main,
                    },
                    "&:hover.Mui-selected": {
                        backgroundColor: palette.green.main,
                    },
                    "&:focus.Mui-selected": {
                        backgroundColor: palette.green.main,
                    },
                },
            },
        },

        MuiAutocomplete: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                root: {
                    ".MuiFilledInput-root": {
                        paddingLeft: r(16),
                        ".MuiFilledInput-input": {
                            padding: 0,
                        },
                    },
                },
                popper: {
                    paddingTop: r(6),
                    ".MuiPaper-root": {
                        border: "1px solid",
                        borderColor: palette.primary.main,
                        backgroundColor: palette.common.white,
                    },
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.body2,
                    fontWeight: 700,
                    letterSpacing: "0.02em",
                    boxShadow: "none",

                    "&.Mui-disabled": {
                        background: theme.palette.grey[200],
                        cursor: "not-allowed",
                    },
                }),
                sizeLarge: {
                    borderWidth: "2px",
                    ":hover": {
                        borderWidth: "2px",
                    },
                    minHeight: r(54),
                    paddingBlock: r(15),
                },
                sizeMedium: {
                    borderWidth: "2px",
                    ":hover": {
                        borderWidth: "2px",
                    },
                    minHeight: r(44),
                    paddingBlock: r(11),
                },
                sizeSmall: {
                    borderWidth: "2px",
                    ":hover": {
                        borderWidth: "2px",
                    },
                    minHeight: r(34),
                    paddingBlock: r(7),
                },
            },
        },

        MuiSwitch: {
            defaultProps: {
                color: "default",
            },
            styleOverrides: {
                root: ({ ownerState: { color = "default" } }) => ({
                    padding: r(5),
                    ".MuiSwitch-thumb": {
                        width: r(24),
                        height: r(24),
                        color: palette.grey[300],
                        boxShadow: "none",
                    },
                    ".MuiSwitch-switchBase": {
                        padding: r(7),
                    },
                    ".MuiSwitch-switchBase.Mui-checked": {
                        "& + .MuiSwitch-track": {
                            backgroundColor: (color !== "default"
                                ? palette[color]
                                : palette.green
                            ).main,
                            opacity: 1,
                        },
                        "& > .MuiSwitch-thumb": {
                            color: palette.common.white,
                        },
                    },
                    ".MuiSwitch-switchBase.Mui-disabled": {
                        "& + .MuiSwitch-track": {
                            backgroundColor: palette.grey[150],
                            opacity: 1,
                        },
                        "& > .MuiSwitch-thumb": {
                            color: palette.grey[200],
                        },
                    },
                    ".MuiSwitch-track": {
                        borderRadius: r(14),
                        backgroundColor: palette.grey[200],
                        opacity: 1,
                    },
                }),
            },
        },

        MuiChip: {
            defaultProps: {
                color: "default",
                size: "small",
            },
            styleOverrides: {
                root: ({
                    ownerState: { color = "default", size = "small" },
                }) => ({
                    backgroundColor:
                        color === "default"
                            ? palette.grey[200]
                            : palette[color].main,
                    height: size === "small" ? r(18) : r(22),
                    borderRadius: r(3),
                    fontSize: size === "small" ? r(10) : r(12),
                    fontWeight: 700,
                    ".MuiChip-deleteIcon": {
                        fontSize: size === "small" ? r(14) : r(18),
                    },
                }),
                label: ({
                    ownerState: { color = "default", size = "small" },
                }) => ({
                    textTransform: "uppercase",
                    color:
                        color === "default"
                            ? palette.grey[800]
                            : palette[color].contrastText,
                    paddingInline: size === "small" ? r(4) : r(8),
                }),
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    ".MuiSvgIcon-root": {
                        fontSize: r(24),
                    },
                    "&.Mui-disabled": {
                        color: palette.grey[200],
                    },
                },
            },
        },

        MuiAppBar: {
            defaultProps: {
                elevation: 1,
            },
            styleOverrides: {
                root: ({ ownerState: { color = "secondary", elevation } }) => ({
                    backgroundColor:
                        color === "primary"
                            ? palette.common.white
                            : color === "secondary"
                            ? palette.grey[50]
                            : color,
                    color: palette.grey[900],
                    boxShadow:
                        elevation === 1
                            ? "0px 5px 44px rgba(14, 15, 25, 0.06)"
                            : undefined,
                }),
            },
        },

        MuiToolbar: {
            styleOverrides: {
                regular: ({ theme }) => ({
                    [theme.breakpoints.up("xs")]: {
                        minHeight: r(70),
                        paddingLeft: r(30),
                        paddingRight: r(30),
                    },
                }),
                dense: ({ theme }) => ({
                    [theme.breakpoints.up("xs")]: {
                        minHeight: r(42),
                        paddingLeft: r(10),
                        paddingRight: r(10),
                    },
                }),
            },
        },

        MuiListItem: {
            styleOverrides: {
                root: ({ ownerState: { disablePadding } }) => ({
                    paddingBlock: disablePadding ? 0 : r(6),
                }),
            },
        },

        MuiModal: {
            styleOverrides: {
                root: {
                    "*::-webkit-scrollbar": {
                        width: r(3 + 4 * 2),
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: themeCommon.palette.primary.main,
                        height: r(3),
                        border: "solid transparent",
                        borderWidth: r(4),
                        borderRadius: "6px",
                        backgroundClip: "content-box",
                    },
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    color: theme.palette.grey[900],
                    background: theme.palette.common.white,
                    fontSize: theme.typography.body2.fontSize,
                    boxShadow: "0px 2px 2px rgba(14, 15, 25, 0.3)",
                    padding: r(7),
                }),
                arrow: ({ theme }) => ({
                    color: theme.palette.common.white,
                }),
            },
        },

        MuiContainer: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingBottom: r(70),

                    [theme.breakpoints.up("xs")]: {
                        paddingLeft: r(16),
                        paddingRight: r(16),
                    },
                }),
            },
        },

        MuiAvatar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: r(16),
                    fontWeight: 500,
                    color: theme.palette.grey[900],
                    background: theme.palette.grey[150],
                }),
                img: ({ theme }) => ({
                    background: theme.palette.common.white,
                }),
            },
        },

        MuiLink: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: "inherit",
                    color: theme.palette.grey[900],
                    fontWeight: 700,
                    letterSpacing: 0,
                }),
            },
        },
    },
});

export default createTheme(componentsOverrideTheme, themeCommon);
