import styled from "@mui/material/styles/styled";

import CardBox from "@components/elements/Card/CardBox";

interface BaseModalLayoutProps {
    fitContent?: boolean;
}

const BaseModalLayout = styled(CardBox)<BaseModalLayoutProps>(
    ({ theme, fitContent = false }) => ({
        position: "absolute",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        backgroundColor: theme.palette.grey[50],
        border: "none",
        outline: "none",
        [theme.breakpoints.down("md")]: {
            height: "100%",
            inset: 0,
            transform: "none",
            width: "100%",
            borderRadius: 0,
        },
        [theme.breakpoints.up("md")]: {
            height: fitContent ? "initial" : "min(100vh, 750px)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: theme.breakpoints.values.sm,
            borderRadius: theme.shape.borderRadius,
        },
    }),
);

export default BaseModalLayout;
