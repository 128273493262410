import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useInstances } from "react-ioc";
import { observer } from "mobx-react-lite";
import Link from "@mui/material/Link";

import EastIcon from "@mui/icons-material/East";
import Typography from "@components/fondation/Typography/Typography";
import EnterBillForm from "@components/modules/forms/connect-utility/EnterBillForm/EnterBillForm";
import { UserStore } from "@store/auth/user.store";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import ImageViewerStore from "@store/image-viewer.store";
import ModalBox from "@components/elements/Modal/ModalBox";
import Stack from "@components/fondation/Stack/Stack";
// eslint-disable-next-line import/no-cycle
import LinkUtilityStore from "@store/auth/link-utility.store";
import { EnterBillFormData } from "@components/modules/forms/connect-utility/EnterBillForm/utils";
import { trackEvent } from "@model/utils/tracking";
import { UtilityStore } from "@store/auth/utilities.store";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import { UtilityLinkErrorMessage } from "@model/types/user";

interface LinkManuallyPageProps {
    isError?: boolean;
    error?: string;
}

type ContentType = {
    title: (utilityShortName?: string) => string;
    subtitle: (utilityShortName: string) => string;
};

type utilityErrorConfigType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in UtilityLinkErrorMessage | any]: ContentType;
};

export const utilityErrorConfig: utilityErrorConfigType = {
    MULTI_FACTOR_AUTH_FAILURE: {
        title: (utilityShortName?: string) =>
            `Could not connect to ${utilityShortName}. Enter your account info`,
        subtitle: (utilityShortName: string) =>
            `Because of two-factor authentication, we could not connect to ${utilityShortName}. Fill in details from a recent ${utilityShortName} bill.`,
    },
};

const linkContent = {
    title: () => "Enter your account info",
    subtitle: (utilityShortName: string) =>
        `Fill in details from a recent ${utilityShortName} bill.`,
};
const getContent = (
    isError: boolean,
    errorCode: UtilityLinkErrorMessage | undefined,
): ContentType => {
    if (!isError || !errorCode) {
        return linkContent;
    }
    return utilityErrorConfig[errorCode] ?? linkContent;
};

const LinkManuallyPage: React.FC<LinkManuallyPageProps> = observer(() => {
    const helperContainerRef = React.useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const [linkStore, userStore, utilityStore, imageViewer] = useInstances(
        LinkUtilityStore,
        UserStore,
        UtilityStore,
        ImageViewerStore,
    );

    const handleSubmit = async (form: EnterBillFormData) => {
        trackEvent("Link Attempted", "Bill Upload");
        const error = await linkStore.enterBill(form);
        if (!error) {
            trackEvent("Link Submitted", "Manual");
            navigate("/enrollment/success");
        }
    };

    const service = userStore.user?.services?.electric;
    const { utility, utilityShortName } = utilityStore;

    const isError = linkStore.linkingStatus === "error";
    const content: ContentType = getContent(isError, linkStore?.error?.code);

    const handleOpenExample = () => {
        UseRudderStack.INSTANCE.trackEvent(
            "upload_bill-link_click-show_details",
            { values: utilityStore?.utility?.key },
        );
        imageViewer.open(utilityStore?.utility?.key);
    };

    const detailsButtonStyle = {
        display: "block",
        color: theme.palette.grey[900],
        fontWeight: 700,
        fontSize: "12px",
    };

    return (
        <ModalBox overflow="initial" width="100%" ref={helperContainerRef}>
            <Stack flex="1 1 100%" spacing={3}>
                <Stack>
                    <Typography variant="h3" mb={1}>
                        {content.title(utilityShortName)}
                    </Typography>
                    <Typography variant="body1" color={theme.palette.grey[800]}>
                        {content.subtitle(utilityShortName ?? "")}
                    </Typography>
                </Stack>

                <Alert severity="info" onClick={handleOpenExample}>
                    <AlertTitle>Where do I find this information?</AlertTitle>
                    View a sample bill
                    <Typography variant="button" sx={detailsButtonStyle}>
                        <Link onClick={handleOpenExample} mr={1}>
                            Show details
                        </Link>
                        <EastIcon fontSize="inherit" />
                    </Typography>
                </Alert>

                <EnterBillForm
                    loading={userStore.isLoading}
                    initialValues={service}
                    config={utility?.powerkiosk}
                    utilityName={utilityShortName}
                    onSubmit={handleSubmit}
                />
            </Stack>
        </ModalBox>
    );
});

export default LinkManuallyPage;
