import { useMemo } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import Stack from "@components/fondation/Stack/Stack";
import CollapsibleCard from "@components/elements/Card/CollapsibleCard";
import Box from "@components/fondation/Box/Box";
import { useInstance } from "react-ioc";
import ProductStore from "@store/product/product.store";
import Markdown from "@components/elements/Markdown/Markdown";
import { navLinkRenderer } from "@components/elements/Markdown/utils";
import Typography from "@components/fondation/Typography/Typography";
import { useFAQList } from "@model/constants/support/FAQ";

export const ProductFAQList: React.FC = observer(() => {
    const productStore = useInstance(ProductStore);
    const status = productStore.service?.status;

    const faqList = useFAQList();

    const list = useMemo(() => {
        if (!status) {
            return [];
        }
        return faqList.filter((item) => item.status.includes(status));
    }, [status]);

    return (
        <Box>
            <Stack spacing={4}>
                {list.length ? (
                    list.map(({ question, answer }) => (
                        <CollapsibleCard title={question} key={question}>
                            <Markdown renderLink={navLinkRenderer}>
                                {answer}
                            </Markdown>
                        </CollapsibleCard>
                    ))
                ) : (
                    <Typography color="gray.500">No questions found</Typography>
                )}
            </Stack>
        </Box>
    );
});

export default ProductFAQList;
