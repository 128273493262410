import { useEffect } from "react";
import * as React from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";

import AuthStore from "@store/auth/auth.store";
import BaseLayout from "@components/modules/layouts/BaseLayout/BaseLayout";
import MainHeader from "@components/prototypes/Header/MainHeader";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import Stack from "@components/fondation/Stack/Stack";
import UserAvatar from "@components/prototypes/Header/UserAvatar";
import Toolbar from "@components/elements/HeaderBar/Toolbar";
import DashboardFooter from "@components/prototypes/Footer/DashboardFooter";

interface EnrollmentLayoutProps {
    children?: React.ReactNode;
}

const EnrollmentLayout: React.FC<EnrollmentLayoutProps> = observer(
    ({ children }) => {
        const location = useLocation();
        const navigate = useNavigate();

        const auth = useInstance(AuthStore);

        const isRoot = useMatch("/");
        const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

        useEffect(() => {
            if (!auth.isAuthorized && isRoot) {
                navigate(`/utilities${location.search}`, { replace: true });
            }
        }, [auth.isAuthorized, isRoot]);

        const afterNode = auth.isAuthorized && !isMobile && (
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={5}
            >
                <UserAvatar />
            </Stack>
        );

        return (
            <BaseLayout
                header={
                    <Toolbar>
                        <MainHeader after={afterNode} />
                    </Toolbar>
                }
                footer={<DashboardFooter />}
            >
                {children}
            </BaseLayout>
        );
    },
);

export default EnrollmentLayout;
