import { useState } from "react";
import * as React from "react";

import Chip from "@components/elements/Chip/Chip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@components/elements/Button/IconButton";
import Typography from "@components/fondation/Typography/Typography";
import Collapse from "@components/elements/Collapse/Collapse";
import Stack from "@components/fondation/Stack/Stack";
import { statusMapper } from "../constants";
import { PlanStatus } from "../types";
import Container from "./HeaderBase";

export interface HeaderProps {
    collapsible?: boolean;
    status?: PlanStatus;
    children?: React.ReactNode;
}

const ContentHeader: React.FC<HeaderProps> = React.memo((props) => {
    const { children, status = "OLD", collapsible } = props;
    const context = statusMapper[status];

    const [open, setOpen] = useState(false);

    if (!context) {
        throw new Error(`Plan status - '${status}', is not accepted`);
    }

    return (
        <Container bgcolor={context.bgColor} collapsible={collapsible}>
            <Stack direction="row" justifyContent="space-between">
                <Chip label={context.label} color={context.color} />

                {!!collapsible && (
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
            </Stack>

            <Collapse in={children != null && (!collapsible || open)}>
                <Typography variant="subtitle2" mt={1.5} textTransform="none">
                    {children}
                </Typography>
            </Collapse>
        </Container>
    );
});

export default ContentHeader;
