import { ReactText } from "react";
import Toast from "@components/elements/Toast/Toast";
import { toast } from "react-toastify";
import { AppError } from "@model/utils/error";
import { preferenceOptions } from "./constants";

export const successUpdateAutopilot = (newValue: boolean): ReactText =>
    toast(
        <Toast
            security="success"
            title="Success"
            description={
                <>
                    Autopilot successfully{" "}
                    <b> {newValue ? "Enabled" : "Disabled"} </b>
                </>
            }
        />,
    );

export const successUpdatePreference = (newValue: string): ReactText => {
    const name = preferenceOptions.find(
        ({ value }) => value === newValue,
    )?.label;
    return toast(
        <Toast
            severity="success"
            title="Success"
            description={
                <>
                    Your preference is successfully switched to <b>{name}</b>.
                </>
            }
        />,
    );
};

export const throwErrorMessage = (error: AppError): ReactText =>
    toast(
        <Toast
            severity="error"
            title={error.title}
            description={error.message}
        />,
    );
