import MUITabs, { TabsProps } from "@mui/material/Tabs";
import styled from "@mui/material/styles/styled";

import { r } from "@theme/utils";

const padding = 2;
const minHeight = 34;
const inMinHeight = minHeight - 2 * padding;

const SwitchTabs = styled(MUITabs)<TabsProps>(({ theme }) => ({
    zIndex: 0,
    padding: r(padding),
    background: theme.palette.grey[100],
    minHeight,
    width: "fit-content",
    borderRadius: r(minHeight / 2),

    ".MuiTab-root": {
        zIndex: 1,
        minHeight: r(inMinHeight),
        borderRadius: r(inMinHeight / 2),
        paddingBlock: r(3),
        fontSize: r(14),
        fontWeight: 400,
        lineHeight: "160%",
        textTransform: "none",
        color: theme.palette.grey[700],
        letterSpacing: "0",
    },

    ".MuiTab-root:hover": {
        color: theme.palette.grey[800],
    },

    ".MuiTab-root.Mui-selected": {
        fontWeight: 700,
        color: theme.palette.grey[900],
    },

    ".MuiTabs-indicator": {
        zIndex: 0,
        top: 0,
        height: "100%",
        borderRadius: r(inMinHeight / 2),
        background: theme.palette.common.white,
    },

    ".MuiTouchRipple-root": {
        color: theme.palette.common.white,
    },
}));

export default SwitchTabs;
