import { FieldMetaProps, FormikContextType } from "formik";

export const hasError = <T>(
    formik: FormikContextType<T>,
    filedName: keyof T,
): boolean => Boolean(formik.touched[filedName] && !!formik.errors[filedName]);

export const fieldHasError = <T>(formik: FieldMetaProps<T>): boolean =>
    Boolean(formik.touched && !!formik.error);

export const errorMessage = <T>(
    formik: FormikContextType<T>,
    filedName: keyof T,
): string => {
    const error = formik.errors[filedName];
    if (typeof error !== "string" || !formik.touched[filedName]) {
        return "";
    }
    return error;
};

export const getFieldErrorMessage = <T>(formik: FieldMetaProps<T>): string => {
    if (!formik.touched || typeof formik.error !== "string") {
        return "";
    }
    return formik.error;
};
