import * as React from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@mui/material/styles/styled";

import DashboardFooter from "@components/prototypes/Footer/DashboardFooter";
import UIContainer from "@components/elements/Container/Container";
import { r } from "@theme/utils";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import ModalHeader from "@components/elements/HeaderBar/ModalHeader";
import { DashboardHeaderDesktop } from "@components/prototypes/Header/DashboardHeader";
import BaseLayout from "./BaseLayout/BaseLayout";

const Container = styled(UIContainer)(({ theme }) => ({
    paddingTop: r(50),
    [theme.breakpoints.down("lg")]: {
        paddingTop: r(30),
    },
}));

interface AccountLayoutProps {
    children?: React.ReactNode;
}

const AccountLayout: React.FC<AccountLayoutProps> = observer(({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

    const handleClose = () =>
        navigate(location.state?.backPath ?? "/dashboard");

    const headerNode = isMobile ? (
        <ModalHeader hideBackButton EndButtonProps={{ onClick: handleClose }} />
    ) : (
        <DashboardHeaderDesktop showBackButton />
    );

    return (
        <BaseLayout header={headerNode} footer={<DashboardFooter />}>
            <Container>{children}</Container>
        </BaseLayout>
    );
});

export default AccountLayout;
