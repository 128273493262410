import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";

import Typography from "@components/fondation/Typography/Typography";
import CardBox from "@components/elements/Card/CardBox";
import useToggle from "@components/prototypes/hooks/useToggle";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import UtilityAccountForm from "@components/modules/forms/connect-utility/EnterBillForm/UtilityAccountForm";
import ImageViewerStore from "@store/image-viewer.store";
import { UtilityStore } from "@store/auth/utilities.store";
import { useInstances } from "react-ioc";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import Alert from "@components/elements/Alert/Alert";
import EastIcon from "@mui/icons-material/East";
import ContainerBox from "@components/prototypes/utilities/UtilityMeta/ContainerBox";
import {
    EnterBillFormData,
    getValidationSchema,
} from "@components/modules/forms/connect-utility/EnterBillForm/utils";
import { BaseSchema } from "yup";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { UserStore } from "@store/auth/user.store";
import LinkUtilityStore from "@store/auth/link-utility.store";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import IconButton from "@components/elements/Button/IconButton";
import UtilityMeta from "../utilities/UtilityMeta/UtilityMeta";
import { UtilityPreviewCard } from "./UtilityPreviewCard";

const FORM_LABEL = "Utility Account";

interface CheckoutUtilityProps {
    cancelable?: boolean;
}

const CheckoutUtility: React.FC<CheckoutUtilityProps> = observer(
    ({ cancelable = true }) => {
        const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));
        const {
            isActive,
            handleToggle: toggleEdit,
            makeActive,
        } = useToggle(!cancelable);

        const [utilityStore, userStore, linkStore, imageViewer] = useInstances(
            UtilityStore,
            UserStore,
            LinkUtilityStore,
            ImageViewerStore,
        );

        const service = userStore.user?.services?.electric;
        const config = utilityStore?.utility?.powerkiosk;
        const urjanetLoginUrl = utilityStore?.utility?.urjanet?.website;
        const theme = useTheme();

        const [loading, setLoading] = useState(false);

        const handleSubmit = async (form: EnterBillFormData) => {
            setLoading(true);
            const error = await linkStore.enterBill(form);
            setLoading(false);
            if (!error) {
                makeActive(false);
            }
        };

        const handleOpenExample = () => {
            imageViewer.open(utilityStore?.utility?.key);
        };

        const validationSchema: BaseSchema = useMemo(
            () => getValidationSchema(config),
            [config],
        );

        const initialValue = useMemo(
            () =>
                validationSchema.cast(service, {
                    stripUnknown: true,
                    context: config,
                }),
            [config, service, validationSchema],
        );

        const handleLoginUtility = urjanetLoginUrl
            ? () => window.open(urjanetLoginUrl, "_blank")
            : undefined;

        useEffect(() => {
            if (!cancelable !== isActive) {
                makeActive(true);
            }
        }, [cancelable]);

        const detailsButtonStyle = {
            display: "block",
            color: theme.palette.grey[900],
            fontWeight: 700,
            fontSize: "12px",
        };

        if (isActive) {
            return (
                <CardBox p={isMobile ? 4 : [6, 4.5]}>
                    <Typography variant="h4" mb={1}>
                        {FORM_LABEL}
                    </Typography>

                    <UtilityAccountForm
                        onSubmit={handleSubmit}
                        onCancel={toggleEdit}
                        initialValues={initialValue}
                        config={config}
                        cancelable={cancelable}
                        submittable={!linkStore.isLoading}
                        loading={loading}
                        socketBefore={
                            <ContainerBox variant="card" color="secondary">
                                <UtilityMeta
                                    name={utilityStore.utilityShortName}
                                    logoSrc={utilityStore.utilityLogo}
                                    socketActions={
                                        handleLoginUtility ? (
                                            <IconButton
                                                onClick={handleLoginUtility}
                                            >
                                                <OpenInNewOutlinedIcon />
                                            </IconButton>
                                        ) : null
                                    }
                                />
                            </ContainerBox>
                        }
                        socketAfter={
                            <Alert severity="info" onClick={handleOpenExample}>
                                <AlertTitle>
                                    Where do I find this information?
                                </AlertTitle>
                                View a sample bill
                                <Typography
                                    variant="button"
                                    sx={detailsButtonStyle}
                                >
                                    <Link onClick={handleOpenExample} mr={1}>
                                        Show details
                                    </Link>
                                    <EastIcon fontSize="inherit" />
                                </Typography>
                            </Alert>
                        }
                    />
                </CardBox>
            );
        }

        return (
            <UtilityPreviewCard
                label={FORM_LABEL}
                onEdit={toggleEdit}
                p={isMobile ? 4 : [6, 4.5]}
            />
        );
    },
);

export default CheckoutUtility;
