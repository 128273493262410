import { utilitiesList, StaticUtility } from "./utilities-list";

// @deprecated
const utilitiesMap = utilitiesList.reduce<Record<string, StaticUtility>>(
    (acc, item) => {
        acc[item.id] = item;
        return acc;
    },
    {},
);

export default utilitiesMap;
