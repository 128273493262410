import { useState } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";

import Stack from "@components/fondation/Stack/Stack";
import MenuGroup from "@components/elements/List/MenuGroup";
import MenuItem, { MenuItemProps } from "@components/elements/List/MenuItem";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import SubPageLayout from "@pages/account/AccountSectionLayout";
import { useInstance } from "react-ioc";
import LinkUtilityStore from "@store/auth/link-utility.store";
import {
    checkLOAStatus,
    fetchLOA,
} from "@components/prototypes/utilities/UtilityMeta/utils";
import { UserStore } from "@store/auth/user.store";

export const menuList: MenuItemProps[] = [
    {
        id: "terms",
        primaryText: "Terms of service",
        icon: <HandshakeOutlinedIcon />,
    },
    {
        id: "privacy",
        primaryText: "Privacy policy",
        icon: <PrivacyTipOutlinedIcon />,
    },
];

const LegalPage: React.FC = observer(() => {
    const [fetching, setFetching] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const userStore = useInstance(UserStore);
    const linkStore = useInstance(LinkUtilityStore);
    const { linkingStatus } = linkStore;
    const { user } = userStore;

    const isActive = (name: string) =>
        !!matchPath(`/${name}`, location.pathname);
    const getLink = (name: string) => `/${name}`;
    const showLoa = checkLOAStatus(linkingStatus);

    const handleLOAOpen = async () => {
        if (user && user?.id) {
            setFetching(true);
            const file = await fetchLOA(user);
            if (file) {
                window.location.href = file;
            } else {
                navigate("/");
            }
        } else {
            navigate("/");
        }
        setFetching(false);
    };

    return (
        <SubPageLayout label="Legal">
            <Stack spacing={4}>
                <MenuGroup>
                    {menuList.map((itemProps) => (
                        <MenuItem
                            {...itemProps}
                            onClick={() => navigate(getLink(itemProps.id))}
                            active={isActive(itemProps.id)}
                            key={itemProps.id}
                        />
                    ))}
                    {showLoa && (
                        <MenuItem
                            loading={fetching}
                            id="loa"
                            primaryText="Letters of authorization"
                            onClick={() => handleLOAOpen()}
                            active={isActive("loa")}
                            key="loa"
                            icon={<ArticleIcon />}
                        />
                    )}
                </MenuGroup>
            </Stack>
        </SubPageLayout>
    );
});

export default LegalPage;
