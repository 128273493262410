import AuthStore from "@store/auth/auth.store";
import { UserStore } from "@store/auth/user.store";
import { makeAutoObservable } from "mobx";
import { inject } from "react-ioc";
import { getDeviceType } from "../../lib/browserUtils";

class AppStore {
    authStore = inject(this, AuthStore);

    userStore = inject(this, UserStore);

    // keep static and set only once at store init
    device: "mobile" | "desktop";

    isLocalhost: boolean;

    constructor() {
        makeAutoObservable(this);
        this.device = getDeviceType(navigator.userAgent);
        this.isLocalhost = window.location.href.includes("localhost");
    }

    get isAppReady(): boolean {
        return this.authStore.isAuthChecked && this.userStore.isUserChecked;
    }
}

export default AppStore;
