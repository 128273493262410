import * as React from "react";

import Typography from "@components/fondation/Typography/Typography";
import { TypographyProps } from "@mui/material/Typography";
import { r } from "@theme/utils";
import Box from "@components/fondation/Box/Box";

export interface LabelProps extends TypographyProps {
    label?: string;
    children?: React.ReactNode;
}

const Label: React.FC<LabelProps> = React.memo((props) => {
    const { label, children, ...restProps } = props;
    return (
        <Box>
            <Typography variant="caption" fontSize={r(10)} mb={0.5}>
                {label}
            </Typography>
            <Typography variant="h6" {...restProps}>
                {children}
            </Typography>
        </Box>
    );
});

export default Label;
