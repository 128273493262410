import { useEffect, useState } from "react";
import * as React from "react";
import styled from "@mui/material/styles/styled";
import { observer } from "mobx-react-lite";

import DashboardFooter from "@components/prototypes/Footer/DashboardFooter";
import UIContainer from "@components/elements/Container/Container";
import { r } from "@theme/utils";
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery";
import {
    DashboardHeaderDesktop,
    DashboardHeaderMobile,
} from "@components/prototypes/Header/DashboardHeader";
import Loader from "@components/elements/Loader/Loader";
import { UserStore } from "@store/auth/user.store";
import { useInstances } from "react-ioc";
import { useNavigate } from "react-router-dom";
import BaseLayout from "./BaseLayout/BaseLayout";

const Container = styled(UIContainer)(({ theme }) => ({
    paddingTop: r(50),
    [theme.breakpoints.down("lg")]: {
        marginTop: r(60),
        paddingTop: r(30),
    },
}));

interface DashboardLayoutProps {
    children?: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = observer(
    ({ children }) => {
        const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"));

        const headerNode = isMobile ? (
            <DashboardHeaderMobile />
        ) : (
            <DashboardHeaderDesktop />
        );

        const [userStore] = useInstances(UserStore);
        const navigate = useNavigate();
        const [loading, setLoading] = useState<boolean>(true);

        useEffect(() => {
            if (userStore?.user?.property?.class === "commercial") {
                navigate("/contact-us");
            } else {
                setLoading(false);
            }
        }, []);

        if (loading) {
            return <Loader />;
        }

        return (
            <BaseLayout header={headerNode} footer={<DashboardFooter />}>
                <Container>{children}</Container>
            </BaseLayout>
        );
    },
);

export default DashboardLayout;
