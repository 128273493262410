import axios from "axios";
import { Functions } from "firebase/functions";
import { FUNCTIONS_URL } from "@root/lib/constants";
import { cloudFunctions } from "../firebase";

export class AppService {
    functions: Functions;

    timeout = 5000;

    constructor() {
        this.functions = cloudFunctions;
    }

    /* eslint-disable class-methods-use-this */
    grabIP(sessionId: string) {
        return axios
            .create({
                baseURL: `${FUNCTIONS_URL}/backend`,
            })
            .post("/grabIP", { sessionId });
    }
    /* eslint-enable class-methods-use-this */
}
