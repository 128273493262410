import { useCallback, useState } from "react";

export const useToggle = (
    initValue = false,
): {
    isActive: boolean;
    makeActive: (value: boolean) => void;
    handleToggle: () => unknown;
} => {
    const [isActive, makeActive] = useState(initValue);

    const handleToggle = useCallback(() => {
        makeActive((state) => !state);
    }, []);

    return { isActive, makeActive, handleToggle };
};
export default useToggle;
