import { useEffect, useState } from "react";

import throttle from "lodash/throttle";

interface Options {
    target?: Node | Window;
    threshold?: number;
}

const useScrollPosition = ({
    target = window,
    threshold = 100,
}: Options): number => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = throttle(() => {
            setScrollPosition((target as HTMLElement).scrollTop ?? 0);
        }, threshold);
        target.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => target.removeEventListener("scroll", updatePosition);
    }, [target, threshold]);

    return scrollPosition;
};

export default useScrollPosition;
