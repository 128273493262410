import WestIcon from "@mui/icons-material/West";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButtonProps } from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Header, { HeaderProps } from "./Header";
import IconButton from "../Button/IconButton";

export interface ModalHeaderProps extends HeaderProps {
    BackButtonProps?: IconButtonProps;
    hideBackButton?: boolean;
    EndButtonProps?: IconButtonProps;
    hideEndButton?: boolean;
}

export default function ModalHeader({
    BackButtonProps = undefined,
    hideBackButton = false,
    EndButtonProps = undefined,
    hideEndButton = false,
    ...rest
}: ModalHeaderProps): JSX.Element {
    return (
        <Toolbar color="secondary" variant="dense">
            <Header
                {...rest}
                before={
                    hideBackButton ? null : (
                        <IconButton
                            size="small"
                            color="primary"
                            {...BackButtonProps}
                        >
                            {BackButtonProps?.children ?? <WestIcon />}
                        </IconButton>
                    )
                }
                after={
                    hideEndButton ? null : (
                        <IconButton
                            size="small"
                            color="primary"
                            {...EndButtonProps}
                        >
                            {EndButtonProps?.children ?? <ClearIcon />}
                        </IconButton>
                    )
                }
            />
        </Toolbar>
    );
}
