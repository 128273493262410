/* eslint-disable react/no-unescaped-entities */
import Typography from "@components/fondation/Typography/Typography";

function LOA(): JSX.Element {
    return (
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            <h2>Letter of Authorization</h2>
            <p style={{ marginBottom: "0" }}>[YOUR NAME HERE]</p>
            <p style={{ marginTop: "0" }}>[DATE OF REGISTRATION]</p>
            <p>To whom it may concern,</p>
            <p>
                I, [YOUR NAME HERE], hereby authorize Arbor Energy Brokerage
                Services, LLC (“Arbor”), to exercise agency authority to enter
                electricity and/or natural gas supply contracts on my behalf for
                the utility account(s) listed in Attachment A. I acknowledge and
                understand that all capitalized terms used in this Letter of
                Authorization have the same meaning as in Arbor’s Terms of
                Service (“Terms of Service”), to which I have already agreed,
                unless expressly defined otherwise herein.
            </p>
            <ol>
                <li>
                    <strong>Ability to Solicit and Negotiate: </strong>I
                    authorize Arbor to solicit electric and/or natural gas
                    supply prices and/or utility bill credits from suppliers on
                    my behalf. Furthermore, Arbor has permission to express
                    interest in a supplier's offer(s), and to negotiate with
                    such supplier on my behalf.
                </li>
                <li>
                    <strong>
                        Initiation, Modification, and Cancellation of
                        Enrollment:{" "}
                    </strong>
                    I grant Arbor my explicit, informed, and affirmative consent
                    to initiate service and enroll me in a rate with a supplier
                    to the extent consistent with the Terms of Service. This
                    includes the authority to modify or cancel any enrollment at
                    my request or at Arbor’s discretion. I understand and agree
                    that if I activate the Autopilot feature, and certain
                    specified conditions are satisfied, any enrollment,
                    initiation, modification, or cancellation of service may
                    occur automatically without my additional consent, unless I
                    opt out.
                </li>
                <li>
                    <strong>Sharing of Information: </strong>I grant Arbor my
                    explicit, informed, and affirmative consent to initiate
                    service and enroll me in a rate with a supplier to the
                    extent consistent with the Terms of Service. This includes
                    the authority to modify or cancel any enrollment at my
                    request or at Arbor’s discretion. I understand and agree
                    that if I activate the Autopilot feature, and certain
                    specified conditions are satisfied, any enrollment,
                    initiation, modification, or cancellation of service may
                    occur automatically without my additional consent, unless I
                    opt out.
                </li>
                <li>
                    <strong>Proof of Agreement: </strong>Arbor may present this
                    Letter of Authorization to utilities, suppliers, or other
                    authorized parties as required or authorized by law.
                </li>
            </ol>
            <p>
                By signing this Letter of Authorization, I authorize,
                understand, and acknowledge the following:
            </p>
            <ol>
                <li>
                    I voluntarily authorize Arbor to enter a supply contract on
                    my behalf.
                </li>
                <li>
                    I understand that Arbor will, and grant Arbor the agency to,
                    enter into a supply contract on my behalf.
                </li>
                <li>
                    I have received Arbor’s Terms of Service to enter a supply
                    contract on my behalf.
                </li>
                <li>
                    I understand that, if Arbor enters into a supply contract on
                    my behalf, I will no longer receive the electric assistance
                    program (EAP) discount on the supply portion of my bill.
                </li>
                <li>
                    I understand that, if Arbor enters into a supply contract on
                    my behalf, I will receive the terms and conditions of
                    service from the supplier and I will have 5 business days to
                    rescind the supply contract with the supplier if the terms
                    and conditions are received electronically or 6 business
                    days to rescind the supply contract with the supplier if the
                    terms are conditions are sent by U.S. Mail.
                </li>
            </ol>
            <p>
                This Letter of Authorization shall remain effective until
                revoked by me in writing.
            </p>
            <p>Sincerely,</p>
            <p>[DIGITAL SIGNATURE]</p>
            <p style={{ marginBottom: "0" }}>Signed: [YOUR NAME HERE]</p>
            <p style={{ margin: "0" }}>Time Stamp: [TIME OF REGISTRATION]</p>
            <p style={{ marginTop: "0" }}>
                Location: [IP ADDRESS OF REGISTRATION]
            </p>
            <p style={{ margin: "3rem 0 0", textAlign: "center" }}>
                Attachment A
            </p>
            <p style={{ marginTop: "0", textAlign: "center" }}>
                Account Numbers
            </p>
            <p style={{ marginBottom: "0" }}>
                Service Address: [YOUR SERVICE ADDRESS]
            </p>
            <p style={{ margin: "0" }}>Utility: [YOUR UTILITY]</p>
            <p style={{ marginTop: "0" }}>
                Account Number: [YOUR UTILITY ACCOUNT NUMBER]
            </p>
        </Typography>
    );
}

export default LOA;
