import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { useInstances } from "react-ioc";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";

import Grid from "@components/fondation/Grid/Grid";
import ContactInfoForm from "@components/modules/forms/auth/ContactInfoForm";
import SessionStore from "@store/auth/session.store";
import Alert from "@components/elements/Alert/Alert";
import AlertTitle from "@components/elements/Alert/AlertTitle";
import { UserStore } from "@store/auth/user.store";
import NavLink from "@components/elements/NavLink/NavLink";
import { parseFormattedPhoneNumber } from "@root/lib/numberUtils";
import { AppError } from "@model/utils/error";
import { SignUpFormData } from "@components/modules/forms/common/types";
import UseRudderStack from "@components/prototypes/hooks/useRudderStack";
import AboutYouHeader from "./AboutYouHeader";

export const signUpErrorCodeToMessage = (code: string): React.ReactNode => {
    if (code === "EMAIL_EXISTS") {
        return (
            <>
                A user with the provided <b>Email</b> already exists,{" "}
                <NavLink to="/login">please try logging in instead</NavLink>
            </>
        );
    }
    if (code === "PHONE_EXISTS") {
        return (
            <>
                A user with the provided <b>Phone number</b> already exists,{" "}
                <NavLink to="/login">please try logging in instead</NavLink>
            </>
        );
    }
    if (code === "INVALID_PHONE") {
        return (
            <>
                The provided <b>Phone number</b> is has incorrect format
            </>
        );
    }

    if (code === "ACCOUNT_BLOCKED") {
        return (
            <>
                It looks like your account is disabled. Please{" "}
                <NavLink to="/">reach out to support</NavLink> to restore your
                access
            </>
        );
    }

    return "We are having trouble, please try again later";
};

const SignUpPage: React.FC = observer(() => {
    const [sessionStore, userStore] = useInstances(SessionStore, UserStore);
    const navigate = useNavigate();
    const alertRef = useRef<null | HTMLDivElement>(null);

    const [, setValidForm] = useState<SignUpFormData | null>(null);
    const [error, setError] = useState<AppError | null>(null);

    const loading = sessionStore.isLoading || userStore.isLoading;

    const handleSubmit = async (form: SignUpFormData) => {
        setError(null);
        setValidForm(null);
        const formData = {
            ...form,
            phone: parseFormattedPhoneNumber(form.phone),
        };
        UseRudderStack.INSTANCE.trackEvent("about_you-button_click-continue");
        const signUpError = await sessionStore.finalizeSignUp(
            undefined,
            undefined,
            formData,
        );
        if (!signUpError) {
            setValidForm(formData);
            await sessionStore.signInWithLink();
        }
        setError(signUpError);
    };

    useEffect(() => {
        UseRudderStack.INSTANCE.pageEvent("sign-up", "about_you", {
            page_name: "about_you",
            name: "about_you",
            url: window.location.href,
        });
    }, []);

    // useEffect(() => {
    // 	if (sessionStore.isLoading || error) return

    // 	const { session, isSignUpInProgress, finalizeSignUp } = sessionStore

    // 	if (isSignUpInProgress && session?.signInLink && session?.email) {
    // 		finalizeSignUp(session?.email, session?.signInLink, validForm)
    // 	}
    // }, [sessionStore.isLoading, error])

    useEffect(() => {
        if (error) {
            if (alertRef) {
                // For better UX lets show user our error if he has
                // small screen and error is outside of the viewport
                alertRef.current?.scrollIntoView({ behavior: "smooth" });
            }
            return;
        }
        if (userStore.isInit) {
            // if (userStore.user?.sscid) {
            // 	const img = document.createElement("img")
            // 	img.src = `https://www.shareasale.com/sale.cfm?tracking=${userStore.userId}&amount=0.00&merchantID=145884&transtype=lead`
            // 	img.width = 1
            // 	img.height = 1
            // }
            navigate("../address", { replace: true });
        }
    }, [userStore.isInit, loading, error, alertRef]);

    useEffect(
        () =>
            // clean up error on leave
            () => {
                runInAction(() => {
                    sessionStore.error = null;
                });
            },
        [],
    );

    // If user previously entered email, we're prefilling
    // contact form with already entered data.
    const initialContactFormValues = {
        email:
            sessionStore.session?.email ??
            sessionStore.session?.preselect?.email ??
            "",
        phone:
            sessionStore.session?.phone ??
            sessionStore.session?.preselect?.phone ??
            "",
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AboutYouHeader />
            </Grid>

            <Grid item xs={12}>
                <ContactInfoForm
                    initialValues={initialContactFormValues}
                    loading={sessionStore.isLoading}
                    onSubmit={handleSubmit}
                    submitButtonProps={{ children: "Agree and Continue" }}
                >
                    {/* <Typography variant="h4" mb={1}>
						{TITLE}
					</Typography>
					<Typography variant="body1" color="grey.800" mb={2.5}>
						{MESSAGE}
					</Typography> */}

                    {error && (
                        <Alert severity="error" ref={alertRef}>
                            <AlertTitle>{error.title}</AlertTitle>
                            {signUpErrorCodeToMessage(error.code) ??
                                error.message}
                        </Alert>
                    )}
                </ContactInfoForm>
            </Grid>
        </Grid>
    );
});

export default SignUpPage;
